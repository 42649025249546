import axios from "axios"

const API = "motivos-generales";

const motivosGenerales = {
    listar: () => axios.get(`${API}/listar`),
    listarEliminacion: () => axios.get(`${API}/listar-eliminacion`),
    listarDevolucion: () => axios.get(`${API}/listar-devolucion`),
    listarSugerencia: () => axios.get(`${API}/listar-sugerencia`),
    listarRechazo: () => axios.get(`${API}/listar-rechazo`),
};

export default motivosGenerales;
