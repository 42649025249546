<template>
    <section>
        <div class="row mx-0 px-3 py-3 border-bottom">
            <div class="col-auto text-general f-600 f-18">
                Tipos de {{$config.cliente}}
            </div>
        </div>
        <div class="row mx-0 pt-4">
            <div class="col-auto" />
            <div class="col px-0">
                <div class="row mx-0">
                    <div class="col-4">
                        <p class="text-general f-14 pl-3">Nombre del tipo</p>
                        <el-input v-model="nombre" class="w-100" show-word-limit maxlength="30" />
                    </div>
                    <div class="col-auto pt-3">
                        <el-tooltip placement="bottom" content="Crear tipo" effect="light">
                            <div class="bg-general text-white br-8 p-1 mt-1">
                                <i class="icon-plus f-20 cr-pointer" @click="crear()" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
                <div class="custom-scroll overflow-auto pt-4" style="height:calc(100vh - 255px)">
                    <p class="pl-4 f-15 text-general mb-3">
                        Lista de tipo
                    </p>
                    <ValidationObserver ref="validacion2" v-slot="{ valid }">
                        <div v-for="(data, i) in lista" :key="i" class="row mx-0 pl-4 mb-3">
                            <div class="col-4">
                                <el-input v-model="data.tipo" :disabled="!data.editar" class="w-100" show-word-limit maxlength="30" />
                            </div>
                            <div class="col-auto px-2 d-middle">
                                <i v-if="data.editar" class="icon-ok-circled-outline f-20 text-success cr-pointer" @click="editar(valid,data)" />
                                <i v-else class="icon-pencil-outline text-general2 f-20 cr-pointer" @click="data.editar = !data.editar " />
                            </div>
                            <div class="col-auto px-2 d-middle">
                                <i class="icon-delete-outline f-22 text-general2 cr-pointer" @click="eliminarTipo(data)" />
                            </div>
                            <div class="col-auto d-middle-center">
                                <el-tooltip placement="bottom" :content="`${$config.cliente} asignados a este tipo`" effect="light">
                                    <div class="bg-general3 text-white br-20 px-3 py-1">
                                        <i class="icon-cliente f-18" />
                                        <span>
                                            {{ data.users_clientes_count }}
                                        </span>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
            <!-- <div class="col-auto" /> -->
        </div>

        <!-- Partials -->
        <modal-eliminar ref="modalEliminarTipo" :titulo="`Eliminar tipo de ${$config.cliente}`" mensaje="¿Desea eliminar ese tipo?" @eliminar="eliminar()" />

        <modal ref="modalEliminar" icon="trash" :titulo="`Eliminar tipo de ${$config.cliente}`" tamano="modal-lg" @guardar="onAccept()">
            <div class="row mx-0">
                <div class="col-auto px-4" />
                <div class="col">
                    Este tipo que va a eliminar tiene {{ count }} {{ count == 1 ? 'usuario' : 'usuarios' }}, elija por cuál lo quiere remplazar
                    <el-select
                    v-model="toUpdate"
                    class="w-100"
                    filterable
                    >
                        <el-option
                        v-for="(item, i) in listaExistente"
                        :key="i"
                        :label="item.tipo"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-auto px-4" />
            </div>
        </modal>

        <!-- </modal-eliminar> -->
    </section>
</template>

<script>
import tiposClientes from '~/services/configurar/admin/tiposClientes'
export default {
    data(){
        return{
            nombre: '',
            lista: [],
            loading: false,
            id: 0,
            count: 0,
            toUpdate: ''            

        }
    },
    computed: {
        listaExistente(){
            return this.lista.filter(el => el.id != this.id)
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        eliminarTipo({id, users_clientes_count}){
            this.id = id
            if (users_clientes_count > 0){
                this.count = users_clientes_count
                this.toUpdate = ''            

                this.$refs.modalEliminar.toggle();
            } else {
                this.$refs.modalEliminarTipo.toggle();
            }
        },
        async listar(){
            try {
                this.loading = true
                const { data } = await tiposClientes.listar();
                this.lista = data.map(el => ({...el, editar: false}))
            } catch (error){
                this.error_catch(error)
            } finally{
                this.loading = false
            }
        },
        async editar(valid, datos){
            try {
                if(valid){
                    let form = {
                        nombre: datos.tipo
                    }
                    const {data} = await tiposClientes.actualizar(datos.id, form)
                    if(data.exito){
                        datos.editar = !datos.editar
                        this.notificacion('', 'Tipo editado correctamente', 'success')
                        this.listar()
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminar(){
            try {
                const { data } = await tiposClientes.eliminar(this.id)
                if (data.exito){
                    this.id = 0
                    this.$refs.modalEliminarTipo.toggle();
                    this.notificacion('', 'Tipo eliminado correctamente', 'success')

                    this.listar()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async crear(){
            try {
                    
                if(!this.nombre) return this.notificacion('', 'El nombre del tipo es requerido', 'error')
                const form = {
                    nombre: this.nombre
                }
                const { data } = await tiposClientes.crear(form)
                if (data.exito){
                    this.notificacion('', 'Tipo creado correctamente', 'success')
                    this.listar()
                    this.nombre = ''
                    this.$refs.form.reset()

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async onAccept(){
            try {
                if(!this.toUpdate) return this.notificacion('', 'El tipo a actualizar es requerido', 'error')
                const form  = {
                    id: this.id,
                    update: this.toUpdate
                }
                const { data } = await tiposClientes.eliminarExistente(form)
                if (data.exito){
                    this.id = 0
                    this.count = 0
                    this.toUpdate = ''
                    this.notificacion('', 'Tipo eliminado correctamente', 'success')
                    this.$refs.modalEliminar.toggle();
                    this.listar()
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>