<template>
    <section>
        <div class="row mx-0 px-3 border-bottom py-2">
            <div class="col-auto  f-600 f-18 text-general">
                Motivos de cambio de precio en pedido de {{ $config.vendedor }} - {{ $config.cliente }}
            </div>
        </div>
        <div class="row mx-0 mt-3 mb-1">
            <div class="col-auto" />
            <div class="col border-bottom d-middle">
                <router-link 
                :to="{name : 'admin.conf.camPrecio.solicitud'}" 
                :class="$route.name == 'admin.conf.camPrecio.solicitud' ? 'tab-active' : 'tab'"
                class="col-auto f-15 py-2"
                >
                    Motivos de solicitud
                </router-link>
                <router-link 
                :to="{name : 'admin.conf.camPrecio.rechazo'}" 
                :class="$route.name == 'admin.conf.camPrecio.rechazo' ? 'tab-active' : 'tab'"
                class="col-auto f-15 py-2"
                >
                    Motivos de rechazo
                </router-link>
            </div>
            <div class="col-auto" />
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 218px)">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col pt-3">
                    <router-view />
                </div>
                <div class="col-auto" />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data(){
        return {

        }
    }
}
</script>

<style lang="scss" scoped>
a{
    color: var(--color-general);
    text-decoration: none !important;
    &:hover{
        color: var(--color-general);
    }
}
.tab-active{
    color: var(--color-general);
    text-decoration: none !important;
    border-bottom: 3px solid var(--color-general);
    &:hover{
        color: var(--color-general);
    }
}
.tab{
    color: var(--color-general2);
    text-decoration: none !important;
    &:hover{
        color: var(--color-general2);
    }
}
</style>