import axios from 'axios'

const tipotienda = {

    PostCrear: (datos) => axios.post(`tipo-tiendas/crear-tis-tipo`,datos),
    GetTipos: () => axios.get(`tipo-tiendas/listar-tis-tipos`),
    PutEditar: (datos) => axios.post(`tipo-tiendas/editar-tis-tipo`,datos),
    DeleteRegistro: (id,idcategoria) => axios.delete(`tipo-tiendas/${id}/${idcategoria}/eliminar-tis-tipo-registros`),
    Delete: (id) => axios.delete(`tipo-tiendas/${id}/eliminar-tis-tipo`)
}
export default tipotienda
