import axios from "axios";

const API = "anuncios";

const Publicidad = {
    getImagenes(tipo){
        return axios.get(`${API}/imagenes?tipo=${tipo}` ); // Tipo = 1 Cliente 2 Leechero
    },
    getCarpetas(params){
        return axios.get(`${API}/carpetas`, { params }); // Tipo = 1 Cliente 2 Leechero
    },
    guardarPublicidad(form){
        return axios.post(`${API}/guardar`,form)
    },
    guardarPublicidadCedis(form){
        return axios.post(`${API}/guardar-cedis`,form)
    },
    editarPublicidad(form){
        return axios.post(`${API}/editar`,form)
    },
    duplicarAnuncios(form){
        return axios.post(`${API}/duplicar-anuncios`,form)
    },
    usarPublicidad(form){
        return axios.post(`${API}/usar-publicidad`,form)
    },
    usarPublicidadMultiple: (form) =>  axios.post(`${API}/usar-publicidad-multiple`,form),
    
    eliminarPublicidad(id){
        return axios.delete(`${API}/eliminar/${id}`)
    },
    guardarPublicidadCarpeta(form){
        return axios.post(`${API}/guardar-carpeta`,form)
    },
    editarPublicidadCarpeta(form){
        return axios.post(`${API}/editar-carpeta`, form);
    },
    eliminarPublicidadCarpeta(id){
        return axios.delete(`${API}/eliminar-carpeta/${id}`)
    },
    getRedireccion: params => axios.get(`${API}/redireccion`, { params }),
    cambiarPosicionImagenes: data => axios.post(`${API}/cambiar-posicion-imagenes`, data),
    cambiarEstado: data => axios.post(`${API}/cambiar-estado`, data),
};

export default Publicidad;
