<template>
    <section>
        <div class="overflow-auto custom-scroll" style="height:calc(100vh - 130px)">
            <div class="col-12">
                <ValidationObserver ref="validacion" v-slot="{ valid }" tag="div" class="row mx-0">
                    <div class="col-7">
                        <div class="row mx-0 bg-white br-12 py-3 px-3 my-4 mb-3">
                            <div class="col-12 text-general f-15">
                                Escribe un mensaje preestablecido que formará parte de la imagen que comparte el {{ $config.vendedor }} junto a su código QR, y un texto que acompañe la imagen en el momento de compartir a través de otras aplicaciones.
                            </div>
                        </div>
                        <div class="row mx-0 px-3 py-5 mt-3 bg-white br-12">
                            <ValidationProvider v-slot="{errors}" rules="required" name="texto" tag="div" class="col-10">              
                                <p class="text-general pl-3 f-15">Texto que hace parte de la imagen</p>
                                <el-input 
                                v-model="texto_imagen" 
                                placeholder="Mensaje" 
                                type="textarea" 
                                :rows="5"
                                class="w-100"
                                maxlength="150"
                                show-word-limit
                                @change="guardarMensajes(valid)"
                                />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="row mx-0 px-3 mt-3 py-5 bg-white br-12">
                            <ValidationProvider v-slot="{errors}" rules="required" name="imagen" tag="div" class="col-10">              
                                <p class="text-general pl-3 f-15">Texto anexo a la imagen compartida</p>
                                <el-input 
                                v-model="texto_anexo"
                                class="w-100" 
                                placeholder="Mensaje"
                                type="textarea"
                                :rows="5"
                                maxlength="200" 
                                show-word-limit
                                @change="guardarMensajes(valid)"
                                />
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                
                                <!-- <div class="row mx-0 my-3">
                                    <div class="bg-general text-white px-2 py-1 cr-pointer br-10" @click="guardarMensajes(valid)">
                                        Guardar
                                    </div>
                                </div> -->
                            </ValidationProvider>                                
                        </div>
                    </div>
                    <div class="col bg-white br-12 mt-4">
                        <div class="col position-relative mb-2">
                            <div class="justify-center mt-1 mx-0 mx-auto pt-3 row">
                                <p class="text-general f-500 f-18">Previsualización</p>
                            </div>
                            <div class="card-style justify-center mt-2 mx-0 mx-auto pt-3 row">
                                <div class="col-12 section-title px-0">
                                    <img :src="$config.logo_blanco" style="max-width:300px;height:50px;" class="obj-cover" />
                                </div>
                                <div class="col-12 section-welcome px-0">
                                    <p>Hola, soy</p>
                                    <p class="f-600">Victoria Galvis</p>
                                </div>                     
                                <div class="col-12 section-text px-0">
                                    <p> {{ texto_imagen }} </p>
                                </div>
                                <div class="col-12 section-code px-0">
                                    <p>Código: COD-2023</p>
                                </div>
                                <div class="section-codeqr">
                                    <img src="/img/otros/codeQR.png" alt="" style="max-height:70%; max-width:70%; border: 7px solid #fff; border-radius: 7px;" />
                                </div>
                                <div class="col-12 section-button px-0">
                                    <div class="btn-general d-middle-center br-12 text-white p-1 cr-pointer text-center" style="height:32px;">
                                        Compartir código
                                    </div>
                                </div>                           
                            </div>
                        </div>
                    </div>
                </ValidationObserver>
            </div>            
        </div>
    </section>
</template>

<script>
import MensajesLeechero from '~/services/configurar/admin/mensajesLeechero'
export default {
    data(){
        return {
            texto_imagen: '',
            texto_anexo: '',
            slimOptions_portada: {
                label:'Subir imagen',
            },
        }
    },
    mounted(){
        this.obtenerMensajes()
    },
    methods:{
        async obtenerMensajes(){
            try {
                let datos={
                    tipos : [51,52]                   
                }
                const {data} = await MensajesLeechero.GetMensajes(datos)
                console.log(data.length)
                if(data.length>0){
                    this.texto_imagen = data[0].texto
                    this.texto_anexo = data[1].texto
                }else{
                    this.texto_imagen = ""
                    this.textotexto_anexo_imagen = ""
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async guardarMensajes(valid){            
            try {
                if(valid){
                    let datos = {
                        tipos :
                            [
                                {
                                    tipo : 51, texto: this.texto_imagen
                                },
                                {
                                    tipo : 52, texto: this.texto_anexo
                                },
                            ]
                    }
                    const {data} = await MensajesLeechero.PostGuardar(datos)
                    this.notificacion('', 'Se han guardado los cambios correctamente', 'success')
                    this.obtenerMensajes()
                }
                
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .img-preview{
        height: 32em;
    }
    .card-style {
        background-color: var(--color-general);
        border-radius: 10px;
        height: 33em;
        width: 17em;
        position: relative;
        box-shadow: 0px 3px 4px #00000029;
        border-bottom: 180px solid white;
        &::after{
            background-color: var(--color-general);
            content: '';
            width: inherit;
            height: 100px;
            bottom: -47px;
            position: absolute;
            border-radius: 52%;
            transform: rotate(181deg);
        }
    }
    .section-title {
        text-align: center;
        position: absolute;
        color: #fff;
        font-size: 1.5em;
        width: 8em;
        top: 1.2em;
    }
    .section-welcome {
        position: absolute;
        color: #fff;
        font-size: 1.1em;
        width: 10em;
        top: 6em;
    }
    .section-button {
        text-align: center;
        position: absolute;
        color: #fff;
        font-size: 1.5em;
        width: 8em;
        top: 20em;
    }
    .section-code {
        text-align: center;
        position: absolute;
        color: #fff;
        font-size: 0.8em;
        width: 14em;
        top: 21.5em;
    }
    .section-text {
        position: absolute;
        color: #fff;
        font-size: 0.70em;
        width: 15.3em;
        top: 15em;
    }
    .section-codeqr {
        position: absolute;
        text-align: center;
        color: #fff;
        top: 19.2em;
        z-index: 9;
    }
    .texto-imagen{
        position: absolute;
        color: #fff;
        font-size: 0.8em;
        width: 14em;
        top: 15em;
    }
</style>