<template>
    <section>
        <div class="row mx-0">
            <menuPrincipal :menus="menus" />
            <div class="px-0 col border br-t-10 menu-prinicipal ml-3 w-content">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    metaInfo:()=>({ title: 'Configurar Admin' }),
    data(){
        return {
            activo: 'tipoTiendas',
            menus: [
                { nombre: 'Tipo de tiendas', ruta: 'admin-vip.conf.tipoTiendas', icon: 'icon-format-list-bulleted-type' },
                { nombre: 'Motivos de inactividad', ruta: 'admin-vip.conf.motInactivo', icon: 'icon-account-reactivate' },
                { nombre: 'Aspecto Correos electrónicos', ruta: 'admin.conf.aspectCorreos', icon: 'icon-email-newsletter' },
                { nombre: 'Logos del sistema', ruta: 'admin.conf.logSistema', icon: 'icon-brush' },
            ]
        }
    }
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    height: calc(100vh - 58px);
    background: #fff;
}
</style>
