import axios from 'axios'

const API = "tipos-clientes";

const tiposClientes = {
    listar: () => axios.get(`${API}/listar`),
    crear: data => axios.post(`${API}/crear`, data),
    actualizar: (id, data) => axios.put(`${API}/${id}/actualizar`, data),
    eliminar: id => axios.delete(`${API}/${id}/eliminar`),
    eliminarExistente: (data) => axios.post(`${API}/eliminar-existente`, data),
}
export default tiposClientes