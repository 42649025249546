var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"h-100"},[_c('div',{staticClass:"row mx-0 px-3 pl-5 f-600 border-bottom text-general py-2"},[_vm._v(" Distancia de los domicilios ")]),_c('div',{staticClass:"p-3 f-15 h-90"},[_c('div',{staticClass:"row mx-0 text-general"},[_vm._v(" Crea los diferentes rangos de distancia posibles, para que cada tendero defina los precios de sus domicilios de acuerdo a esa distancia. ")]),_c('ValidationObserver',{ref:"validacion_crear",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"row mx-0 my-2"},[_c('div',{staticClass:"col-auto px-2",staticStyle:{"width":"150px"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"distancia"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"ml-2 text-general"},[_vm._v("Distancia (m)")]),_c('input-miles',{attrs:{"money-options":_vm.config_distancias},model:{value:(_vm.model.distancia),callback:function ($$v) {_vm.$set(_vm.model, "distancia", $$v)},expression:"model.distancia"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-auto mt-3 pt-1"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Crear rango","placement":"bottom"}},[_c('div',{staticClass:"bg-general cr-pointer d-middle-center br-8",staticStyle:{"width":"38px","height":"38px"}},[_c('i',{staticClass:"icon-plus f-20 text-white",on:{"click":function($event){return _vm.crearRegistro(valid)}}})])])],1)])]}}])}),_c('div',{staticClass:"row mx-0 my-4 text-general"},[_vm._v(" "+_vm._s(_vm.$config.vendedor)+" con domicilios gratis: "+_vm._s(_vm.total)+" ")]),_c('div',{staticClass:"row mx-0 my-2 text-general"},[_vm._v(" Rangos de distancias ")]),(_vm.distancia.length)?_c('div',{staticClass:"overflow-auto custom-scroll",staticStyle:{"height":"calc(100% - 165px)"}},[_c('ValidationObserver',{ref:"validacion",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_vm._l((_vm.distancia),function(data,idx){return _c('div',{key:idx,staticClass:"row mx-0 mb-3"},[_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"desde"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-miles',{staticStyle:{"height":"30px"},attrs:{"suffix-icon":false?'icon-attention-alt text-danger':'',"disabled":true,"money-options":_vm.config_distancias},model:{value:(data.distancia_inicial),callback:function ($$v) {_vm.$set(data, "distancia_inicial", $$v)},expression:"data.distancia_inicial"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('span',{staticClass:"mt-2 text-general"},[_vm._v("A")]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"hasta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input-miles',{staticStyle:{"height":"30px"},attrs:{"disabled":_vm.editar!=idx,"money-options":_vm.config_distancias},model:{value:(data.distancia),callback:function ($$v) {_vm.$set(data, "distancia", $$v)},expression:"data.distancia"}}),_c('span',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-auto px-1"},[(_vm.editar!=idx)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Editar","placement":"bottom"}},[_c('div',{staticClass:"btn-action border"},[_c('i',{staticClass:"icon-pencil-outline f-22 cr-pointer",on:{"click":function($event){_vm.editar=idx}}})])]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Guardar","placement":"bottom"}},[_c('div',{staticClass:"btn-action border",on:{"click":function($event){return _vm.EditarDistancia(valid,data)}}},[_c('i',{staticClass:"icon-ok-circled-outline text-green f-20"})])])],1),_c('div',{staticClass:"col-auto px-0"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"light","content":"Eliminar","placement":"bottom"}},[_c('div',{staticClass:"btn-action border",on:{"click":function($event){return _vm.eliminar(data.id)}}},[_c('i',{staticClass:"icon-trash-empty f-18 cr-pointer hover-inverse"})])])],1)])}),_c('div',{staticClass:"col-2 px-2"},[_c('input-miles',{staticStyle:{"height":"30px"},attrs:{"disabled":true,"money-options":_vm.config_distancia_final},model:{value:(_vm.distancia_final),callback:function ($$v) {_vm.distancia_final=$$v},expression:"distancia_final"}})],1)]}}],null,false,724188527)})],1):_c('sinDatos',{attrs:{"icon":"regla","ext":"png","mensaje":"No se ha creado ningún rango de distancia"}}),_c('modalEliminar',{ref:"modalEliminar",attrs:{"titulo":"Eliminar distancia de domicilio","mensaje":"¿Desea eliminar la distancia de domicilios?"},on:{"eliminar":_vm.EliminarRegistro}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }