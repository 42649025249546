<template>
    <section>
        <div class="row mx-0 p-3 border-bottom">
            <div class="col-auto">
                <p class="f-18 text-general f-600">
                    Productos que no se pueden vender sin stock
                </p>
            </div>
        </div>
        <div class="row mx-0 pl-4 mr-3 mt-5">
            <div class="col-12 ml-2 px-3 border-bottom pb-3 d-flex">
                <div class="col-4" />
                <div class="col f-15 text-general f-500 text-center">
                    Con productos disponibles
                </div>
                <div class="col f-15 text-general f-500 text-center">
                    Con productos reservados
                </div>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 288px)">
            <div v-for="(item,index) in datos" :key="index" class=" row mx-0 pl-4 mr-3 mt-3">
                <div class="col-12 ml-2 px-3 d-middle">
                    <div class="col-4 f-15 text-general f-500">
                        {{ item.nombre }}
                        <i v-show="!item.estado" class="icon-eye-off" />
                    </div>
                    <el-radio-group v-model="item.stock_agotado" class="fix-radio-operador" @change="onChange(item.id,item.stock_agotado)">
                        <div class="col pl-4 f-15 text-general f-500 text-center">
                            <el-radio :label="1" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col pl-4 f-15 text-general f-500 text-center">
                            <el-radio :label="2" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </el-radio-group>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import cedisStockAgotado from '~/services/configurar/admin/cedisStockAgotado'
export default {
    data(){
        return {
            datos: {}
        }
    },
    mounted(){
        this.listarCedis();
    },
    methods:{
        async listarCedis(){
            try {
                const { data } = await cedisStockAgotado.getCedis();
                this.datos = data
            } catch (e){
                this.error_catch(e)
            }
            
        },
        async onChange(idCedis,stock_agotado){
            try {
                const { data } = await cedisStockAgotado.setCedisStockAgotado(idCedis,{stock_agotado})
                this.notificacion("","Stock actualizado correctamente","success");
            } catch (e){
                this.error_catch(e);
            }
        }
    }
}
</script>