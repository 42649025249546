<template>
	<section class="h-100">
		<div class="row mx-0 px-3 pl-5 f-600 border-bottom py-2 text-general">
			Cancelación según estados
		</div>
		<div class="p-3 f-15 h-90">
			<div class="row mx-0 text-general my-3">
				Indique en qué estado del pedido el {{ $config.cliente }} podrá cancelar su solicitud.
			</div>
			<div class="overflow-auto custom-scroll mt-5 mx-1" style="height:calc(100% - 140px);">
				<div v-for="(data, idx) in motiveStates" :key="idx" class="row mx-0 my-1 align-items-center mb-3	">
					<div class="col-4 text-general f-500">
						{{ data.label }}
					</div>
					<div class="col-auto px-0">
						<el-tooltip class="item " effect="light" placement="bottom">
						</el-tooltip>
					</div>
					<div class="col-auto px-2 mx-1">
						<el-switch v-model="data.checked" class="my-auto switch-red" :active-value="1" :inactive-value="0"
							@change="editState($event, data)" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

import estadosCancelacion from '~/services/configurar/admin/estadosCancelacion'

export default {
	data() {
		return {
			motiveStates: [],
		}
	},
	mounted() {
		this.listarEstados()
	},
	methods: {
		async listarEstados() {
			try {
				const { data } = await estadosCancelacion.getStates()
				this.motiveStates = data.data
			} catch (e) {
				this.error_catch(e)
			}
		},

		async editState($event, item) {
			try {
				const payload = {
					estado: $event,
					tipo: item.tipo
				}
				const { data } = await estadosCancelacion.updateOrCreate(payload)
				this.notificacion('Éxito', data.mensaje, "success");
			} catch (error) {
				this.error_catch(error)
			}
		},
	}
}
</script>

<style lang="css" scoped>

</style>
