<template>
    <section>
        <div class="row mx-0 border-bottom py-2 pl-5 px-2">
            <div class="col-auto px-0 f-600 text-general">
                Screen Splash
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3 px-4" style="height:calc(100vh - 139px)">
            <cargando v-if="cargando" />
            <p class="f-15 text-general mb-2">
                Sube las imagenes que aparecen cuando los usuarios abren la aplicación
            </p>
            <p class="f-15 text-general mb-5">
                Formato: MP4<span class="ml-5">Resolución: 1080*1920</span>
            </p>
            <div class="row mx-0">
                <div class="col-auto px-0 mr-5" @click="tipo = 11">
                    <p class="f-15 text-general mb-3">
                        Cliente
                    </p>
                    <template v-if="_.isEmpty(cliente)">
                        <el-upload
                        ref="uploadCliente"
                        class="upload-demo w-100"
                        action="#"
                        accept="video/mp4"
                        :on-change="changeFile"
                        :on-remove="removeFile"
                        :auto-upload="false"
                        :multiple="false"
                        :limit="1"
                        :show-file-list="false"
                        >
                            <div class="shadow-14 br-12 p-2 border-style">
                                <div class="btn-subir text-general f-13 ml-5 mr-5 mb-2 mt-2">
                                    Subir cargando del cliente
                                </div>
                            </div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <div class="card-file">
                            <div class="space-file">
                                <i class="icon-image-outline f-18" />
                            </div>
                            <div class="col f-12">
                                {{ indentificarNombre(cliente.video) }}
                            </div>
                            <div class="space-delete" @click="id = cliente.id, confirmarEliminar()">
                                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                    <i class="icon-trash-empty f-18 cr-pointer" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="col-auto px-0 mr-5" @click="tipo = 12">
                    <p class="f-15 text-general mb-3">
                        Vendedor
                    </p>
                    <template v-if="_.isEmpty(vendedor)">
                        <el-upload
                        ref="uploadVendedor"
                        class="upload-demo w-100"
                        action="#"
                        accept="video/mp4"
                        :on-change="changeFile"
                        :on-remove="removeFile"
                        :auto-upload="false"
                        :multiple="false"
                        :limit="1"
                        :show-file-list="false"
                        >
                            <div class="shadow-14 br-12 p-2 border-style">
                                <div class="btn-subir text-general f-13 ml-5 mr-5 mb-2 mt-2">
                                    Subir cargando del vendedor
                                </div>
                            </div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <div class="card-file">
                            <div class="space-file">
                                <i class="icon-image-outline f-18" />
                            </div>
                            <div class="col f-12">
                                {{ indentificarNombre(vendedor.video) }}
                            </div>
                            <div class="space-delete" @click="id = vendedor.id, confirmarEliminar()">
                                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                    <i class="icon-trash-empty f-18 cr-pointer" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col-auto px-0 mr-5" @click="tipo = 13">
                    <p class="f-15 text-general mb-3">
                        Delivery
                    </p>
                    <template v-if="_.isEmpty(delivery)">
                        <el-upload
                        ref="uploadDelivery"
                        class="upload-demo w-100"
                        action="#"
                        accept="video/mp4"
                        :on-change="changeFile"
                        :on-remove="removeFile"
                        :auto-upload="false"
                        :multiple="false"
                        :limit="1"
                        :show-file-list="false"
                        >
                            <div class="shadow-14 br-12 p-2 border-style">
                                <div class="btn-subir text-general f-13 ml-5 mr-5 mb-2 mt-2">
                                    Subir cargando del delivery
                                </div>
                            </div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <div class="card-file">
                            <div class="space-file">
                                <i class="icon-image-outline f-18" />
                            </div>
                            <div class="col f-12">
                                {{ indentificarNombre(delivery.video) }}
                            </div>
                            <div class="space-delete" @click="id = delivery.id, confirmarEliminar()">
                                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                    <i class="icon-trash-empty f-18 cr-pointer" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="col-auto px-0 mr-5" @click="tipo = 14">
                    <p class="f-15 text-general mb-3">
                        Picking & Packing
                    </p>
                    <template v-if="_.isEmpty(pickingPacking)">
                        <el-upload
                        ref="uploadPicking"
                        class="upload-demo w-100"
                        action="#"
                        accept="video/mp4"
                        :on-change="changeFile"
                        :on-remove="removeFile"
                        :auto-upload="false"
                        :multiple="false"
                        :limit="1"
                        :show-file-list="false"
                        >
                            <div class="shadow-14 br-12 p-2 border-style">
                                <div class="btn-subir text-general f-13 ml-5 mr-5 mb-2 mt-2">
                                    Subir cargando de picking & packing
                                </div>
                            </div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <div class="card-file">
                            <div class="space-file">
                                <i class="icon-image-outline f-18" />
                            </div>
                            <div class="col f-12">
                                {{ indentificarNombre(pickingPacking.video) }}
                            </div>
                            <div class="space-delete" @click="id = pickingPacking.id, confirmarEliminar()">
                                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                    <i class="icon-trash-empty f-18 cr-pointer" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="row mx-0 mt-4">
                <div class="col-auto px-0" @click="tipo = 15">
                    <p class="f-15 text-general mb-3">
                        Chats de soporte
                    </p>
                    <template v-if="_.isEmpty(soporte)">
                        <el-upload
                        ref="uploadSoporte"
                        class="upload-demo w-100"
                        action="#"
                        accept="video/mp4"
                        :on-change="changeFile"
                        :on-remove="removeFile"
                        :auto-upload="false"
                        :multiple="false"
                        :limit="1"
                        :show-file-list="false"
                        >
                            <div class="shadow-14 br-12 p-2 border-style">
                                <div class="btn-subir text-general f-13 ml-5 mr-5 mb-2 mt-2">
                                    Subir cargando de chats de soporte
                                </div>
                            </div>
                        </el-upload>
                    </template>
                    <template v-else>
                        <div class="card-file">
                            <div class="space-file">
                                <i class="icon-image-outline f-18" />
                            </div>
                            <div class="col f-12">
                                {{ indentificarNombre(soporte.video) }}
                            </div>
                            <div class="space-delete" @click="id = soporte.id, confirmarEliminar()">
                                <el-tooltip content="Eliminar" placement="bottom" effect="light">
                                    <i class="icon-trash-empty f-18 cr-pointer" />
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <modal ref="confirmarEliminar" titulo="Eliminar imagen" no-aceptar adicional="Eliminar" @adicional="eliminarImagen">
                <div class="row mx-0 justify-center">
                    <div class="col text-center">
                        <p>¿Está seguro de eliminar esta imagen?</p>
                    </div>
                </div>
            </modal>
        </div>
    </section>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    data(){
        return {
            cargando: false,
            file: null,
            tipo: null,
            id: null,
            cliente: {},
            vendedor: {},
            delivery: {},
            pickingPacking: {},
            soporte: {},
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        toggle(){
            this.$refs.modalSubirImagen.toggle();
        },

        async getData(){
            try {
                const { data } = await Service.getScreenApps();
                this.cargarData(data.data);
            } catch(error){
                this.error_catch(error)
            }
        },

        async cargarData(data){
            this.cliente = data.cliente != null ? data.cliente : {};
            this.vendedor = data.vendedor != null ? data.vendedor : {};
            this.delivery = data.delivery != null ? data.delivery : {};
            this.pickingPacking = data.picking_packing != null ? data.picking_packing : {};
            this.soporte = data.soporte != null ? data.soporte : {};
        },

        async changeFile(file){
            try {
                this.file = file.raw;
                let extension = file.raw.type;
                let size = file.raw.size;
                if(this.file == null)return;
                if(extension != 'video/mp4'){
                    this.notificacion('Alerta','El formato del video seleccionado no corresponde con el permitido (MP4)','warning');
                    return this.removeFile();
                }
                if(size > 10000000){ // 10 MB máximo tamaño
                    this.notificacion('Alerta','El tamaño del video supera lo permitido (10 MB)','warning');
                    return this.removeFile();
                }
                this.cargando = true;
                let model = {
                    tipo: this.tipo,
                    file: this.file
                };
                model = this.crear_formData(model)
                const {data} = await Service.postScreenSplashApps(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.actualizarRegistros(data.data);
                this.removeFile();
            } catch(error){
                this.error_catch(error)
            } finally{
                this.cargando = false;
            }
        },

        confirmarEliminar(){
            this.$refs.confirmarEliminar.toggle();
        },

        async eliminarImagen(){
            try {
                if(this.id  == null)return;
                const {data} = await Service.deleteScreenApps(this.id);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.getData();
                this.id = null;
                this.tipo = null;
            } catch(error){
                this.error_catch(error)
            } finally{
                this.$refs.confirmarEliminar.toggle();
            }
        },

        removeFile(){
            if(this.tipo == 11)this.$refs.uploadCliente.clearFiles();
            if(this.tipo == 12)this.$refs.uploadVendedor.clearFiles();
            if(this.tipo == 13)this.$refs.uploadDelivery.clearFiles();
            if(this.tipo == 14)this.$refs.uploadPicking.clearFiles();
            if(this.tipo == 15)this.$refs.uploadSoporte.clearFiles();
            this.file = null;
        },

        async actualizarRegistros(data){
            switch(this.tipo){
            case 11:
                this.cliente = data;
                break;
            case 12:
                this.vendedor = data;
                break;
            case 13:
                this.delivery = data;
                break;
            case 14:
                this.pickingPacking = data;
                break;
            case 15:
                this.soporte = data;
                break;
            }
        },

        indentificarNombre(nombre){
            let data = nombre.split('/');
            if(data.length == 6){
                return data[5];
            }
            return "adjunto.mp4";
        },
    }
}
</script>
<style lang="scss" scoped>
.space-image{
    width: 240px;
    height: 426px;
    border-radius: 4px;
    position: relative;
    border: 1px solid #DBDBDB;
    .circled-cancel{
        width: 28px;
        height: 28px;
        right: 11px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        z-index: 5;
        color: #FFFFFF;
        background-color: #7F7F7F;
    }
}

.card-file{
    height: 60px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 4px #00000014;
    .space-file{
        width: 48px;
        height: 100%;
        border-radius: 12px 0px 0px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #DFE4E8;
    }
    .space-delete{
        width: 60px;
        height: 60px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        background-color: #FF5158;
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.shadow-14{
    box-shadow: 0px 2px 4px #00000014;
}

.border-style {
    border: 1px dashed #DFE4E8;
    border-radius: 11px;
    opacity: 1;
}
</style>
