<template>
    <section>
        <div class="row mx-0 p-3 border-bottom">
            <div class="col-auto">
                <p class="f-18 text-general f-600">
                    Días de trabajo
                </p>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 164px)">
            <div class="row mx-0 my-4 py-2">
                <div class="col-auto" />
                <div class="col px-0 text-general">
                    Indique los días que reciben pedidos cada uno de los CEDIS
                </div>
            </div>
            <div class="row mx-0 mb-3">
                <div class="col-auto" />
                <div class="col px-0">
                    <div v-for="(cedi,index) in datos" :key="index" class="row mx-0 align-items-center mb-4">
                        <div class="col-3 px-0 f-15 text-general f-500">
                            {{ cedi.nombre }}
                            <i v-show="!cedi.estado" class="icon-eye-off" />
                        </div>
                        <div v-for="(dia,ind) in cedi.dias_trabajados" :key="ind" class="btn-day px-1 border mr-2 cr-pointer" :class="dia ? 'bg-general3 text-white' : 'text-general'" @click="onClick(cedi,ind)">
                            <p class="f-14 "> {{ diaS(ind) | ucfirst }} </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import cedisDiasMuertos from '~/services/configurar/admin/cedisDiasMuertos'
import moment from 'moment'

export default {
    data(){
        return {
            datos: {}
        }
    },
    mounted(){
        this.listarCedis();
    },
    methods: {
        async listarCedis(){
            try {
                const { data } = await cedisDiasMuertos.getCedis();
                this.datos = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async onClick(cedi, dia){
            try {
                let valor = cedi.dias_trabajados[dia] == 0 ? 1 : 0;
                this.$set(cedi.dias_trabajados, dia, valor)
                const params = {
                    dias_trabajados: JSON.stringify(cedi.dias_trabajados)
                }
                let idCedi = cedi.id;
                const { data } = await cedisDiasMuertos.setDia(idCedi,params);
                this.notificacion("","Día actualizado correctamente","success")
            } catch (e){
                this.error_catch(e)
            }
        },
        diaS(d){
            return moment().weekday(1).day(d+ 1).format('ddd')
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-day{
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    border-radius: 8px;
}
</style>