<template>
    <section class="logos-sistema">
        <div class="row mx-0 border-bottom py-2 pl-5 px-2">
            <div class="col-auto px-0 f-600 text-general">
                Logos del sistema
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3" style="height:calc(100vh - 153px)">
            <p class="text-general f-15 pl-5 my-3">
                Carga las diferentes versiones de logo que se verán a lo largo de los sistemas.
            </p>
            <p class="text-general f-18 f-600 pl-5 mt-3">
                Administrador
            </p>
            <div class="row mx-0 px-4">
                <div v-for="(logo, index) in logosSistema" :key="index" class="mx-3 mb-3" style="width:148px;">
                    <div class="row mx-0 align-items-center">
                        <p class="col-auto px-0 text-general text-left pl-2 f-14 py-2">
                            {{ logo.nombre }}
                        </p>
                        <div v-if="logo.descripcion" class="col-auto px-0 ml-auto">
                            <el-tooltip placement="bottom" :content="logo.descripcion" effect="light">
                                <i class="icon-help-circle-outline text-general f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                    <template v-if="logo.upload == 'crooper'">
                        <div class="position-relative space-img br-8 d-flex align-items-end justify-center" style="max-height:150px;max-width:150px;" @mouseover="visible_app = true" @mouseleave=" visible_app = false">
                            <div class="position-absolute d-flex justify-content-center w-100" style="bottom:10px;">
                                <div class="br-50 btns-cropper mx-1" @click="cambiarLogo(logo.tipo, logo.logo, logo.dimension, logo.upload)">
                                    <i class="icon-pencil-outline f-17" />
                                </div>
                            </div>
                            <img :src="logo.logo ? logo.logo : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover br-8" height="100%" width="100%" />
                        </div>
                    </template>
                    <template v-if="logo.upload == 'upload'">
                        <div class="position-relative space-img br-8 d-middle-center" style="max-height:150px;max-width:150px;">
                            <div class="position-absolute d-flex justify-content-center w-100" style="bottom:10px;">
                                <el-upload
                                ref="upload"
                                action="#"
                                accept="image/*"
                                :on-change="changeFile"
                                :on-remove="removeFile"
                                :auto-upload="false"
                                :multiple="false"
                                :limit="1"
                                :show-file-list="false"
                                >
                                    <div class="br-50 btns-cropper mx-1" style="bottom:15px;right:40px;" @click="tipoLogo = logo.tipo">
                                        <i class="icon-pencil-outline f-17" />
                                    </div>
                                </el-upload>
                            </div>
                            <img :src="logo.logo ? logo.logo : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover br-8" height="100%" width="100%" />
                        </div>
                    </template>
                    <p class="text-left pl-2 text-grey f-15 pt-2">
                        {{ logo.formato }}
                    </p>
                    <p class="text-grey f-15 pl-2">
                        {{ logo.aspecto }}
                    </p>
                </div>
            </div>
            <p class="text-general f-18 f-600 pl-5 mt-5">
                Apps
            </p>
            <div class="row mx-0 px-4">
                <div v-for="(logo, index) in logosApp" :key="index" class="mx-3 mb-3" style="width:148px;">
                    <div class="row mx-0 align-items-center">
                        <p class="col-auto px-0 text-general text-left pl-2 f-14 py-2">
                            {{ logo.nombre }}
                        </p>
                        <div v-if="logo.descripcion" class="col-auto px-0 ml-auto">
                            <el-tooltip placement="bottom" :content="logo.descripcion" effect="light">
                                <i class="icon-help-circle-outline text-general f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                    <template v-if="logo.upload == 'crooper'">
                        <div class="position-relative space-img br-8 d-flex align-items-end justify-center" style="max-height:150px;max-width:150px;" @mouseover="visible_app = true" @mouseleave=" visible_app = false">
                            <div class="position-absolute d-flex justify-content-center w-100" style="bottom:10px;">
                                <div class="br-50 btns-cropper mx-1" @click="cambiarLogo(logo.tipo, logo.logo, logo.dimension, logo.upload)">
                                    <i class="icon-pencil-outline f-17" />
                                </div>
                            </div>
                            <img :src="logo.logo ? logo.logo : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover br-8" height="100%" width="100%" />
                        </div>
                    </template>
                    <template v-if="logo.upload == 'upload'">
                        <div class="position-relative space-img br-8 d-middle-center" style="max-height:150px;max-width:150px;">
                            <div class="position-absolute d-flex justify-content-center w-100" style="bottom:10px;">
                                <el-upload
                                ref="upload"
                                action="#"
                                accept="image/*"
                                :on-change="changeFile"
                                :on-remove="removeFile"
                                :auto-upload="false"
                                :multiple="false"
                                :limit="1"
                                :show-file-list="false"
                                >
                                    <div class="br-50 btns-cropper mx-1" style="bottom:15px;right:40px;" @click="tipoLogo = logo.tipo">
                                        <i class="icon-pencil-outline f-17" />
                                    </div>
                                </el-upload>
                            </div>
                            <img :src="logo.logo ? logo.logo : '/img/no-imagen/añadir_imagen.svg'" class="obj-cover br-8" height="100%" width="100%" />
                        </div>
                    </template>
                    <p class="text-left pl-2 text-grey f-15 pt-2">
                        {{ logo.formato }}
                    </p>
                    <p class="text-grey f-15 pl-2">
                        {{ logo.aspecto }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-cambiar-logo ref="CambiarLogo" :parametros="parametrosLogo" @actualizar="getData()" />
    </section>
</template>
<script>
import Service from "~/services/configurar/admin/personalizacion"

export default {
    components: {
        modalCambiarLogo: () => import('./partials/modalCambiarLogo')
    },
    data(){
        /**
         * Esto se usa para que guarde el this de vue para usarlo en el slim
         */
        let vm = this
        return {
            tipoLogo: null,
            visible_favicon: false,
            logosSistema: [
                {
                    id: null,
                    tipo: 14,
                    nombre: 'Logo color',
                    descripcion: 'Se usa en la barra superior del sistema',
                    logo: null,
                    formato: 'Formato: PNG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'crooper',
                    dimension: 250
                },
                {
                    id: null,
                    tipo: 15,
                    nombre: 'Logo blanco',
                    descripcion: 'Se usa la barra superior de color en el login del sistema',
                    logo: null,
                    formato: 'Formato: PNG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'crooper',
                    dimension: 250
                },
                {
                    id: null,
                    tipo: 16,
                    nombre: 'Icono logo blanco',
                    descripcion: 'Se usa en la barra superior del sistema',
                    logo: null,
                    formato: 'Formato: PNG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'crooper',
                    dimension: 250
                },
                {
                    id: null,
                    tipo: 17,
                    nombre: 'Fav icon',
                    descripcion: 'Se usa el pestaña del explorador de internet',
                    logo: null,
                    formato: 'Formato: ICON',
                    aspecto: '32x32 px',
                    upload: 'upload',
                    dimension: 250
                }
            ],
            logosApp: [
                {
                    id: null,
                    tipo: 91,
                    nombre: 'Logo app cliente',
                    logo: null,
                    formato: 'Formato: SVG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'upload',
                    dimension: 800
                },
                {
                    id: null,
                    tipo: 92,
                    nombre: 'Logo app vendedor',
                    logo: null,
                    formato: 'Formato: SVG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'upload',
                    dimension: 800
                },
                {
                    id: null,
                    tipo: 93,
                    nombre: 'Logo app delivery',
                    logo: null,
                    formato: 'Formato: SVG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'upload',
                    dimension: 800
                },
                {
                    id: null,
                    tipo: 94,
                    nombre: 'Logo app picking',
                    logo: null,
                    formato: 'Formato: SVG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'upload',
                    dimension: 800
                },
                {
                    id: null,
                    tipo: 95,
                    nombre: 'Logo app soporte',
                    logo: null,
                    formato: 'Formato: SVG',
                    aspecto: 'Aspecto 1:1',
                    upload: 'upload',
                    dimension: 800
                }
            ],
            parametrosLogo: {
                tipo: null,
                logo:  null,
                dimension: null,
                upload: null,
            },
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        async getData(){
            try {
                const { data } = await Service.getLogosSistema()
                data.logos.map((element) => {
                    let index = this.logosSistema.findIndex((item) => item.tipo === element.tipo);
                    let indexApp = this.logosApp.findIndex((itemApp) => itemApp.tipo === element.tipo);
                    console.log(index)
                    if(index != -1){
                        this.logosSistema[index].id = element.id;
                        this.logosSistema[index].logo = element.logo;
                    }

                    if(indexApp != -1){
                        this.logosApp[indexApp].id = element.id;
                        this.logosApp[indexApp].logo = element.logo;
                    }
                    /* this.logosApp[indexApp].id = element.id;
                        this.logosApp[indexApp].logo = element.logo; */
                });
            } catch (e){
                this.error_catch(e)
            }
        },

        cambiarLogo(tipo, logo, dimension, upload){
            this.parametrosLogo.tipo = tipo;
            this.parametrosLogo.logo = logo;
            this.parametrosLogo.dimension = dimension;
            this.parametrosLogo.upload = upload;
            this.$refs.CambiarLogo.toggle();
        },

        async changeFile(file){
            this.file = file.raw;
            if(this.file != null){
                try {
                    let model = {
                        tipo: this.tipoLogo,
                        file: this.file,
                        upload: 'upload'
                    };
                    model = this.crear_formData(model)
                    const {data} = await Service.postLogoSistema(model)
                    this.notificacion('Mensaje', data.mensaje, data.tipo);
                    let favIcon = document.getElementById("favicon");
                    favIcon.href = this.$config.favicon;
                    this.getData();
                    this.removeFile();
                } catch(error){
                    this.error_catch(error);
                }
            }
        },

        removeFile(){
            this.file = null;
        },
    }
}
</script>
<style lang="scss" scoped>

.space-img{
    min-height: 150px;
    min-width: 150px;
    border: 1px solid #DBDBDB;
}


.btns-cropper{
    color: #fff;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #171817;
    box-shadow: 0px 3px 6px #00000029;
}
.br-12{
    border-radius: 12px !important;
}

</style>
