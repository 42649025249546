<template>
    <section class="w-100 h-100">
        <cargando v-if="cargandoCedis" />
        <template v-if="!crearDescuento">
            <div class="row mx-0 mt-2 border-bottom align-items-center pl-5 py-2 text-general">
                <div class="col-auto">
                    <p class="f-20">Descuentos de lista</p>
                </div>
                <div class="btn-general-2 border br-5 px-3 ml-auto f-17 mr-5 cr-pointer" @click="crearDescuento = !crearDescuento">
                    <i class="icon-pencil-outline" /> <span class="ml-1">Editar clasificación de descuentos</span>
                </div>
            </div>
            <div class="row mx-0 align-items-center pl-5 mt-5">
                <span class="text-general f-600">Cedis</span>
                <div class="col-3 pl-2">
                    <el-select v-model="idCedis" size="small w-100" @change="cambioCedis">
                        <el-option
                        v-for="item in cedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div v-if="idCedis" class="btn-general-2 cr-pointer d-middle-center border br-10 ml-auto mr-5" style="width:35px;height:35px;" @click="duplicarData">
                    <i class="icon-content-duplicate f-20 text-general" />
                </div>
            </div>
            <hr class="ml-5 mr-5" />
            <div v-if="idCedis">
                <div class="row mx-0 align-items-center text-general mt-4 ml-5 mr-5">
                    <div class="h-100 d-middle-center cr-pointer" :class="tabSelected === 1? 'border-b-red text-general-red':''" style="width:170px;" @click="tabSelected = 1">
                        {{ $config.vendedor }}
                    </div>
                    <div class="h-100 d-middle-center cr-pointer" :class="tabSelected === 2? 'border-b-red text-general-red':''" style="width:170px;" @click="tabSelected = 2">
                        {{ $config.cliente }}
                    </div>
                </div>
                <div class="row mx-0">
                    <cargando v-if="cargandoClasi" />
                    <leecheros v-if="tabSelected === 1" :lista="lista" :cedis="idCedis" />
                    <clientes v-if="tabSelected === 2" :lista="lista" :cedis="idCedis" />
                </div>
            </div>
            <div v-else>
                <div class="row mx-0 align-items-center text-general mt-4 ml-5 mr-5">
                    <div class="col-auto">
                        <p class="f-14">No se ha seleccionado un cedis</p>
                    </div>
                </div>
            </div>
        </template>
        <crearDescuento v-if="crearDescuento" @regresar="crearDescuento = !crearDescuento" @nuevo="cambioCedis" @actualizo="actualizarItemLista" @eliminar="eliminarItemLista" @ordenar="getClasificaciones" />
        <!-- Partials -->
        <modalDuplicar ref="modalDuplicarDescuentos" />
    </section>
</template>
<script>
import Service from '~/services/configurar/admin/descuentosLista';

export default {
    components: {
        crearDescuento: () => import('./components/crearDescuentosLista.vue'),
        leecheros: () => import('./components/descListaLeechero.vue'),
        clientes: () => import('./components/descListaCliente.vue'),
        modalDuplicar: () => import('./partials/modalDuplicarDescuentos.vue'),
    },
    data(){
        return {
            cargandoCedis: false,
            cargandoClasi: false,
            crearDescuento: false,
            cedis: [],
            lista: [],
            listaClientes: [],
            tabSelected: 1,
            idCedis: null,
        }
    },

    watch: {
        tabSelected(){
            this.lista = [];
            this.getClasificaciones();
        }
    },

    mounted(){
        this.getCedisUser();
    },

    methods:{
        async getCedisUser(){
            try {
                this.cargandoCedis = true;
                const {data} = await Service.getCedisUser();
                this.cedis = data.cedis;
                if(data.cedis.length > 0){
                    this.idCedis = data.cedis[0].id;
                    this.getClasificaciones();
                }
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargandoCedis = false;
            }
        },

        async getClasificaciones(){
            try {
                if(!this.idCedis)return;
                this.cargandoClasi = true;

                const params = {
                    tipo: parseInt(this.tabSelected) === 1 ? 2 : 1
                };

                const {data} = await Service.getClasificacionesCedis(this.idCedis, params);
                this.lista = data.lista;
            } catch(e){
                this.error_catch(e);
            } finally{
                this.cargandoClasi = false;
            }
        },

        async cambioCedis(){
            this.lista = [];
            this.getClasificaciones();
        },

        actualizarItemLista(data){
            let index = this.lista.findIndex(item => item.id === data.id);
            if(index !== -1){
                this.lista[index].nombre = data.nombre;
                this.lista[index].imagen = data.imagen;
            }
        },

        eliminarItemLista(idLista){
            let index = this.lista.findIndex(item => item.id === idLista);
            if(index !== -1){
                this.lista.splice(index, 1);
            }
        },

        duplicarData(){
            const tipo = parseInt(this.tabSelected) === 1 ? 2 : 1;
            this.$refs.modalDuplicarDescuentos.toggle(this.cedis, this.idCedis, tipo);
        },
    },
}
</script>
