import axios from "axios";

const API = "estados-cancelacion";

const ENDPOINTS = {
    getStates(){
        return axios.get(`${API}`)
    },
	updateOrCreate(payload){
		return axios.put(`${API}`, payload)
	}
};

export default ENDPOINTS;
