<template>
    <section>
        <div class="row mx-0 py-3 border-bottom px-3">
            <div class="col-auto text-general f-600">
                Tipo de registro por país
            </div>
        </div>
        <div class="row mx-0 px-3 mt-3">
            <div class="col-12 text-general f-15">
                Seleccione el país, la comisión, el tiempo de la misma y la condición, si el {{$config.vendedor}} requiere postulación de acuerdo a su ubicación (País).
            </div>
        </div>
        <div class="row mx-0 px-3 my-4">
            <div class="col-auto text-general f-18 f-600 d-middle">
                Lista de tipo de registro
            </div>
            <div class="col-auto">
                <el-tooltip placement="bottom" content="Añadir Pais" effect="light">
                    <div class="bg-general text-white p-2 br-10 cr-pointer" @click="guardarRegistro()">
                        <i class="icon-plus f-18" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="px-3 custom-scroll overflow-auto" style="height:calc(100vh - 302px);">
            <div v-for="registro in registros" :key="registro.id" class="row mx-0 mb-2">
                <div class="col-4 pr-0">
                    <p class="text-general pl-3 f-14 f-500">
                        Pais
                    </p>
                    <el-input v-model="registro.pais.pais" size="small" class="w-100" />
                </div>
                <div class="col-xl-2 pr-0 col-lg">
                    <p class="text-general text-center f-14 f-500">
                        Comisión
                    </p>
                    <el-input
                    v-model="registro.comision_monto"
                    size="small"
                    class="w-100"
                    >
                        <i
                        slot="suffix"
                        class="el-input__icon"
                        >%
                        </i>
                    </el-input>
                </div>
                <div class="col-xl-2 col-lg">
                    <p class="text-general text-center f-14 f-500">
                        Vigencia
                    </p>
                    <el-input
                    v-model="registro.comision_meses"
                    size="small"
                    class="w-100"
                    >
                        <i
                        slot="suffix"
                        class="el-input__icon"
                        >meses
                        </i>
                    </el-input>
                </div>
                <div class="col-auto px-2 text-center">
                    <p class="text-general f-14 f-500 mb-1">
                        Postulación requerida
                    </p>
                    <i v-if="registro.postulacion" class="icon-ok-circled-outline f-18 text-aqua mt-3" />
                </div>
                <div class="col-auto px-2 d-middle-center">
                    <el-tooltip placement="bottom" content="Editar" effect="light">
                        <div class="btn-action border" @click="guardarRegistro(registro)">
                            <i class="icon-pencil-outline f-17 hover-icons" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="col-auto px-2 d-middle-center">
                    <el-tooltip placement="bottom" content="Eliminar" effect="light">
                        <div class="btn-action border" @click="eliminarPais(registro.id)">
                            <i class="icon-trash-empty f-17 hover-inverse cr-pointer"  />
                        </div>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-tipo-registro ref="modalTipoRegistro" @guardado="listarRegistros" />
        <modal-eliminar ref="modalEliminarPais" titulo="Eliminar País" mensaje="¿Desea eliminar el país?" @eliminar="eliminarRegistro" />
    </section>
</template>

<script>
import TipoRegistro from '~/services/configurar/admin/tipoRegistro'
export default {
    components: {
        modalTipoRegistro: () => import('./partials/modalTipoRegistro')
    },
    data(){
        return {
            pais: '',
            registros:[],
            idEliminar:null
        }
    },
    mounted(){
        this.listarRegistros()
    },
    methods: {
        guardarRegistro(registro = false){
            this.$refs.modalTipoRegistro.getPaises();
            this.$refs.modalTipoRegistro.toggle(registro);
        },
        eliminarPais(idEliminar){
            this.idEliminar = idEliminar
            this.$refs.modalEliminarPais.toggle();
        },
        async listarRegistros(){
            try {
                const {data} = await TipoRegistro.GetRegistros()    
                this.registros = data 
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminarRegistro(){
            try {
                const {data} = await TipoRegistro.DeleteRegistro(this.idEliminar)    
                this.listarRegistros()
                this.idEliminar = ""
                this.$refs.modalEliminarPais.toggle();
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>