<template>
    <section>
        <div class="row mx-0 py-3 border-bottom pl-4">
            <div class="col-auto f-18 text-general f-500">
                Gaming
            </div>
        </div>
        <div class="row mx-0 pt-4 px-4">
            <p class="col-12 text-general f-15">
                Selecciona de cada una de los CEDIS que tipo de usuario tendrán los beneficios de gaming
            </p>
        </div>
        <div class="row mx-0 border-bottom mt-4 mb-3 mx-5 px-2">
            <div class="col-4 px-0" />
            <div class="col text-center text-general f-500 f-15">
                Ninguno
            </div>
            <div class="col text-center text-general f-500 f-15">
                {{ $config.vendedor }}
            </div>
            <div class="col text-center text-general f-500 f-15">
                {{ $config.cliente }}
            </div>
            <div class="col text-center text-general f-500 f-15">
                Ambos
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-4 px-4" style="height:calc(100vh - 282px)">
            <div v-for="(c,index) in cedis" :key="index" class="d-middle mx-3 mb-4 px-0">
                <div class="col-4 text-general f-500 px-0">
                    {{ c.nombre }}
                    <i v-show="!c.estado" class="icon-eye-off" />
                </div>
                <div class="col px-0">
                    <el-radio-group v-model="c.gaming" class="fix-radio-operador" @change="updateCedisGaming(c.id,c.gaming)">
                        <div class="col f-15 text-general f-500 text-center">
                            <el-radio :label="0" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col f-15 text-general f-500 text-center">
                            <el-radio :label="1" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col f-15 text-general f-500 text-center">
                            <el-radio :label="2" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                        <div class="col f-15 text-general f-500 text-center">
                            <el-radio :label="3" class="radio-dark icon-check-radio">
                                <span class="text-white" />
                            </el-radio>
                        </div>
                    </el-radio-group>
                </div>
                <!-- <div class="col px-0">
                </div> -->
            </div>
        </div>
        <!-- <div class="custom-scroll overflow-auto pt-4 px-4" style="height:calc(100vh - 180px)">
        </div> -->
    </section>
</template>

<script>
import CedisGaming from '@/services/configurar/admin/cedisGaming'
export default {
    data(){
        return {
            gaming: 1,
            cedis: []
        }
    },
    mounted(){
        this.listarCedisGaming()
    },
    methods: {
        async listarCedisGaming(){
            try {
                const { data } = await CedisGaming.getCedisGaming()
                this.cedis = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async updateCedisGaming(idCedis,gaming){
            try {
                let params = {
                    id_cedis: idCedis,
                    gaming: gaming
                }

                const { data } = await CedisGaming.setCedisGaming(params)

                this.notificacion("","Gaming actualizado correctamente","success")
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.fix-radio-operador{
    align-items: center !important;
    .col .el-radio.radio-dark .el-radio__label{
        display: none !important;
    }
}
</style>