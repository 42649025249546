import axios from "axios";
// Routes api: home_leechero
const API = "configurar/home-leechero"

const homeLeecheros = {

    getCedis: () => axios(`${API}/get-cedis`),

    postHomeLeechero(params){
        return axios.post(`${API}/agregar_opcion_home`, params)
    },

    getOptionsHomeLeechero(id){
        return axios.get(`${API}/${id}/opciones_home_cedi`)
    },

    deleteOptionHomeLeechero(params,id){
        return axios.put(`${API}/${id}/delete_home_cedi`, params)
    },

    putDuplicateCedis(params,id){
        return axios.put(`${API}/${id}/duplicate_home_cedi`,params)
    },
}

export default homeLeecheros
