import axios from "axios";

const api = "configurar/parametros-defecto"

const parametrosDefecto = {
    listar: id => axios.get(`${api}/listar/${id}`),
    listarCedis: () => axios.get(`${api}/listar-cedis`),
    guardar: data => axios.post(`${api}/guardar`, data),
    duplicar: data => axios.post(`${api}/duplicar`, data),
}

export default parametrosDefecto