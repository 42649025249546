<template>
	<section>
		<div class="row mx-0 border-bottom py-2 pl-5 px-2">
			<div class="col-auto px-0 f-600 text-general">
				Imagenes para apps
			</div>
		</div>
		<cargando v-if="cargando" />
		<div v-show="showData" class="custom-scroll overflow-auto pt-3 px-4" style="height:calc(100vh - 139px)">
			<p class="f-15 text-general mb-2">
				Header de categorias (Cliente y Vendedor)
			</p>
			<div class="mx-0 my-4 justify-center">
				<!-- <slim-cropper ref="crop_logo1" :options="slimOptions1" class="border cr-pointer bg-white mx-lg-auto" style="height:162px;width:162px;border-radius:12px;" /> -->
				<slim-cropper ref="img" :options="slimOptions" class="border"
					style="cursor:pointer;height:215px;background:#F8F9FF;width:95%;border-radius:12px;" />
			</div>
			<p class="f-15 text-general text-gris mb-5">
				Formato: PNG<span class="ml-5">Relación de aspecto 16:9</span>
			</p>
		</div>
	</section>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
	data() {
		let vm = this
		return {
			image: {},
			showData: false,
			imagenCrop: null,
			cargando: false,
			slimOptions: {
				ratio: '16:9',
				label: 'Subir imagen',
				buttonCancelLabel: 'Cancelar',
				buttonConfirmLabel: 'ok',
				buttonUploadLabel: 'Ok',
				willRemove: function (a, remove) {
					vm.deleteImage()
					remove()
				},
				didSave: function (a) {
					vm.postImagen(a.output.image)
					vm.firstTime = false
				}
			},
		}
	},
	mounted() {
		this.getImageApp();
	},
	methods: {
		async getImageApp() {
			try {
				const { data } = await Service.getImagesApps()
				this.image = data.imagen;
				this.imagenCrop = data.imagen.imagen
			} catch (error) {
				this.error_catch(error)
			} finally {
				this.showData = true;
				this.cargando = false;
				this.prueba()
			}
		},
		prueba() {
			this.$refs.img.set_image(this.imagenCrop)
		},
		async postImagen(item) {
			// return console.log(this.$refs.img.set_image(this.imagenCrop));
			try {
				const payload = {
					dimension: 1700,
					imagen: item
				};
				if (payload.imagen == null) return this.notificacion('Mensaje', 'Para continuar, por favor seleccione una imagen.', 'warning');
				this.cargando = true;
				const { data } = await Service.postImagesApps(payload);
				this.notificacion('Mensaje', data.mensaje, 'success');
			} catch (error) {
				this.error_catch(error)
			}
			finally {
				this.cargando = false;
			}
		},
		async deleteImage() {
			try {
				const { data } = await Service.deleteImagesApps(this.image.id)
				this.notificacion('Mensaje', data.mensaje, 'success');
			} catch (error) {
				this.error_catch(error);
			} finally {
				this.cargando = false;
			}
		}
	},

}
</script>
<style lang="scss" scoped>
.space-image {
	width: 240px;
	height: 240px;
	position: relative;
	border-radius: 4px;
	cursor: pointer;

	.circled-cancel {
		width: 28px;
		height: 28px;
		right: 11px;
		top: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		position: absolute;
		z-index: 5;
		color: #FFFFFF;
		background-color: #7F7F7F;
	}

	img {
		border-radius: 4px;
		object-fit: cover;
	}
}
</style>
