<template>
    <section class="aspectos Correo">
        <div class="px-3 overflow-auto custom-scroll pt-4" style="height:calc(100vh - 134px);">
            <div class="row mx-0">
                <div class="col pl-0 mr-2">
                    <div class="row mx-0 bg-white br-12 py-3">
                        <div class="col-12 mx-0 text-general f-600 f-17 mt-2">
                            Imagen para la cabecera del correo
                        </div>
                        <div class="col-12 mx-0 mt-2">
                            <slim-cropper ref="crop_header" :options="slimOptions" class="border" style="height:180px;background:#F8F9FF;width:calc(100vw - 800px);">
                                <div slot="label" class="">
                                    <img src="/img/modales/camera.svg" alt="" />
                                </div>
                            </slim-cropper>
                            <div class="col-12 text-general f-13 my-1 px-2">
                                800 x 200px
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 bg-white br-12 py-3 mt-4 px-3">
                        <div class="col-12">
                            <div class="row mx-0">
                                <div class="col-12 px-0 f-600 text-general">
                                    Pie de pagina
                                </div>
                                <div class="col-8 mt-3 pl-0">
                                    <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="página web">
                                        <label class="pl-3 text-general f-15" v-text="`Página Web`" />
                                        <el-input v-model="pagina_web" size="small" class="w-100" @change="crear_parametro(valid,22,$event)" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pl-0">
                            <div class="row mx-0 my-3">
                                <ValidationObserver ref="validacion" v-slot="{ valid }" tag="div" class="col-12">
                                    <div class="row mx-0">
                                        <div class="col-8 pl-0 mb-2 px-0 d-flex">
                                            <ValidationProvider v-slot="{errors}" rules="required|max:80" tag="div" class="col px-0 mx-1" name="texto">
                                                <p class="f-15 text-general pl-2">Texto</p>
                                                <el-input v-model="model.texto" size="small" class="w-100" />
                                                <p class="text-danger f-11 pl-2"> {{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{errors}" rules="required|max:80" tag="div" class="col px-0 mx-1" name="texto 2">
                                                <p class="f-15 text-general pl-2">Texto 2</p>
                                                <el-input v-model="model.texto_2" size="small" class="w-100" />
                                                <p class="text-danger f-11 pl-2"> {{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-2 px-2 pt-3">
                                            <div class="row mx-0 align-items-center pt-1">
                                                <div class="bg-general cr-pointer d-middle-center text-white br-8 mt-1" style="width:32px;height:32px;" @click="crear_parametro_textos(valid,23 ,model, null)">
                                                    <i class="icon-plus f-18" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ValidationObserver>
                                <ValidationObserver ref="validacion2" v-slot="{ valid }" class="col-12">
                                    <div v-for="(data, d) in textos" :key="d" class="row mx-0 align-items-center my-2">
                                        <div class="col-8 px-0 d-flex">
                                            <ValidationProvider v-slot="{errors}" :vid="'vp'+d" rules="required|max:80" tag="div" class="col px-0 mx-1" name="texto">
                                                <el-input v-model="data.texto" size="small" :disabled="data.disabled" />
                                                <p class="text-danger f-11 pl-2"> {{ errors[0] }}</p>
                                            </ValidationProvider>
                                            <ValidationProvider v-slot="{errors}" :vid="'vp2'+d" rules="required|max:80" tag="div" class="col px-0 mx-1" name="texto 2">
                                                <el-input v-model="data.texto_2" size="small" :disabled="data.disabled" />
                                                <p class="text-danger f-11 pl-2"> {{ errors[0] }}</p>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col d-middle px-0">
                                            <el-tooltip v-if="data.disabled" class="item" effect="light" content="Editar" placement="bottom">
                                                <div class="d-middle-center cr-pointer br-10" style="width:44px;height:44px;">
                                                    <i class="icon-pencil-outline hover-icons f-20" @click="data.disabled = !data.disabled" />
                                                </div>
                                            </el-tooltip>
                                            <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                                <div class="bg-light-f5 text-general2 shadow cr-pointer d-middle-center br-10" style="width:44px;height:44px;" @click="crear_parametro_textos(valid,23 ,data,data.id)">
                                                    <i class="icon-ok-circled-outline f-20" />
                                                </div>
                                            </el-tooltip>
                                            <!-- <el-tooltip placement="bottom" content="Editar" effect="light">
                                                <i class="icon-pencil-outline f-20 mx-2 hover-icons" @click="data.disabled = !data.disabled" />
                                            </el-tooltip> -->
                                            <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                                <i class="icon-trash-empty f-20 mx-2 hover-icons" @click="eliminarModal(data.id)" />
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </ValidationObserver>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row mx-0 mt-2">
                                <div class="col-12 px-0 f-600 text-general">
                                    Previsualizacion
                                </div>
                                <div class="col-12 mt-2 px-0">
                                    <div class="br-3 p-2" style="width:calc(100vw - 800px);background-color:#F6F9FB;">
                                        <div class="row mx-0 h-100">
                                            <!-- Parte Superior Header -->
                                            <div v-for="(data,d) in textos" :key="d" class="col-6 px-0 text-muted2 mb-2">
                                                <p class="f-600">
                                                    {{ data.texto }}
                                                </p>
                                                <p>
                                                    {{ data.texto_2 }}
                                                </p>
                                            </div>
                                            <!-- Parte inferior Banner -->
                                            <div class="col-12 px-0">
                                                <div class="row mx-0">
                                                    <div class="col-6 px-0 d-flex flex-column justify-content-end">
                                                        <p class="f-12">
                                                            Copyright © 2021 {{ nombre_proyecto }}, Todos los derechos reservados.
                                                            Estas recibiendo este correo porque te suscribiste a la comunidad {{ nombre_proyecto }}.
                                                        </p>
                                                    </div>
                                                    <div class="col-6 px-0">
                                                        <p class="text-gr-general f-14 mt-3 text-right">
                                                            {{ pagina_web }}
                                                        </p>
                                                        <div class="row mx-0 justify-content-end">
                                                            <i v-if="facebook" class="icon-facebook f-22 text-gr-general" />
                                                            <i v-if="youtube" class="icon-youtube-play f-22 text-gr-general" />
                                                            <i v-if="instagram" class="icon-instagram f-22 text-gr-general" />
                                                            <i v-if="twitter" class="icon-twitter f-22 text-gr-general" />
                                                            <i v-if="whatsapp" class="icon-whatsapp f-22 text-gr-general" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>           
                    </div>
                </div>
                <div class="col-4 px-0">
                    <div class="bg-white br-12 py-3 px-2">
                        <div class="row mx-0">
                            <div class="col-12 px-5 f-17 text-general f-600">
                                Redes sociales
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row mx-0 align-items-end">
                                    <i class="icon-facebook f-22 text-gr-general" />
                                    <div class="col">
                                        <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="facebook">
                                            <label class="pl-3 text-general f-15" v-text="`Facebook`" />
                                            <el-input v-model="facebook" size="small" class="w-100" @change="crear_parametro(valid,31,$event)" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row mx-0 align-items-end">
                                    <i class="icon-youtube-play f-22 text-general mb-1" />
                                    <div class="col">
                                        <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="youtube">
                                            <label class="pl-3 text-general f-15" v-text="`Youtube`" />
                                            <el-input v-model="youtube" size="small" class="w-100" @change="crear_parametro(valid,32,$event)" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row mx-0 align-items-end">
                                    <i class="icon-instagram f-22 text-general mb-1" />
                                    <div class="col">
                                        <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="instagram">
                                            <label class="pl-3 text-general f-15" v-text="`Instagram`" />
                                            <el-input v-model="instagram" size="small" class="w-100" @change="crear_parametro(valid,33,$event)" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row mx-0 align-items-end">
                                    <i class="icon-twitter f-22 text-general mb-1" />
                                    <div class="col">
                                        <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="twitter">
                                            <label class="pl-3 text-general f-15" v-text="`Twitter`" />
                                            <el-input v-model="twitter" size="small" class="w-100" @change="crear_parametro(valid,34,$event)" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-3">
                                <div class="row mx-0 align-items-end">
                                    <i class="icon-whatsapp f-22 text-general mb-1" />
                                    <div class="col">
                                        <ValidationProvider v-slot="{errors, valid}" rules="url|max:200" name="whatsapp">
                                            <label class="pl-3 text-general f-15" v-text="`Whatsapp`" />
                                            <el-input v-model="whatsapp" size="small" class="w-100" @change="crear_parametro(valid,35,$event)" />
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar
        ref="eliminarTexto"
        titulo="Eliminar texto"
        mensaje="¿Desea eliminar el texto?"
        @eliminar="eliminar_parametro()"
        />
    </section>
</template>
<script>

import Localizaciones from "~/services/proyectos/localizaciones";
import Parametros from "~/services/x_parametros"
import Aws from '~/services/aws'

export default {
    data(){
        /**
         * Esto se usa para que guarde el this de vue para usarlo en el slim
         */
        let vm = this
        return {
            slimOptions: {
                ratio: '8:2',
                label:'Subir imagen',
                // minSize: { width: 100, height: 10 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
                willRemove: function(a,remove){
                    vm.evento(null)
                    console.log('asdasd');
                    vm.firstTime = false
                    remove()
                },
                didSave: function(a){
                    vm.evento(a.output.image)
                    vm.firstTime = false
                }
            },
            paises: [],
            estados: [],
            ciudades: [],
            nombre_proyecto: '',
            pagina_web: '',
            facebook: '',
            youtube: '',
            instagram: '',
            twitter: '',
            whatsapp: '',
            pais: '',
            estado: '',
            ciudad: '',
            model: {
                texto: '',
                texto_2: '',
            },
            textos: [],
            firstTime: true,
            idEliminar: null,
        }
    },
    async mounted(){
        await this.get_parametros()
    },
    methods: {
        async get_parametros(){
            try {
                const { data } = await Parametros.get_parametros()
                this.$validar(data)
                data.textos.map(el => {
                    el.disabled = true
                })
                this.textos = data.textos
                this.nombre_proyecto = data.parametros.nombre_proyecto
                this.pagina_web = data.parametros.pagina_web
                this.facebook = data.parametros.facebook
                this.youtube = data.parametros.youtube
                this.instagram = data.parametros.instagram
                this.twitter = data.parametros.twitter
                this.whatsapp = data.parametros.whatsapp
                this.pais = data.parametros.pais
                this.estado = data.parametros.estado
                this.ciudad = data.parametros.ciudad
                if (data.parametros.header != null){
                    this.$refs.crop_header.set_image(data.parametros.header)
                } else {
                    this.$refs.crop_header.instanciaCrop.remove()
                    this.firstTime = false
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async crear_parametro(valid, tipo, dato){
            try {
                if (valid){
                    let datos = {
                        tipo: tipo,
                        texto: dato,
                        id: null,
                        texto2: null
                    }
                    const {data} = await Parametros.crear_parametro(datos)
                    this.notificacion('', data.mensaje, 'success')
                    if (tipo == 25){
                        this.firstTime = true
                        this.get_parametros()
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async crear_parametro_textos(valid, tipo, dato, id){
            try {
                if (valid){
                    let datos = {
                        id: id,
                        tipo,
                        texto: dato.texto,
                        texto2: dato.texto_2
                    }
                    const {data} = await Parametros.crear_parametro(datos)
                    dato.disabled = true
                    if (tipo == 23){
                        let model = data.model
                        model.disabled = true
                        if (!id){
                            this.model = {
                                texto: '',
                                texto_2: '',
                            }
                            this.textos.push(model)
                            this.$refs.validacion.reset()
                        }else{
                            let texto = this.textos.find(el => el.id == id)
                            texto = model
                        }
                    }
                    this.notificacion('', data.mensaje, 'success')
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async evento(val){
            try {

                if (this.firstTime){
                    return
                }

                let datos = {
                    tipo: 21,
                    id: null,
                    texto: val,
                    max: 800
                }
                const {data} = await Parametros.crear_parametro(datos)
                this.$validar(data)
                this.notificacion('', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
        async eliminar_parametro(){
            try {
                const {data} = await Parametros.eliminar_parametro(this.idEliminar)
                this.notificacion('', data.mensaje, 'success')
                let index = this.textos.findIndex(el => el.id == this.idEliminar)
                this.textos.splice(index,1)
                this.idEliminar = null
                this.$refs.eliminarTexto.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminarModal(id){
            this.idEliminar = id
            this.$refs.eliminarTexto.toggle()
        }
    }
}
</script>
<style lang="scss" scoped>
.nombre_1{
    height: 97px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
    line-height: 25px;
}
.br-12{
    border-radius: 12px !important;
}
</style>
