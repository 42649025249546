import axios from "axios";

const API = "configurar";

const ENDPOINTS = {
    getCedis(){
        return axios.get(`${API}/cedis/parametros-temporales`)
    },
    putHorasDiasCedis(form){
        return axios.put(`${API}/${form.id}/cedis/parametros-temporales/actualizar`,form)
    },
};

export default ENDPOINTS;
