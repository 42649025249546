<template>
    <section>
        <ValidationObserver ref="validacion" v-slot="{ valid }">
            <div class="row mx-0 pt-5">
                <div class="col-auto" />
                <div class="col-4">
                    <ValidationProvider v-slot="{errors}" vid="condicion" rules="required|max:25" name="condición">
                        <p class="pl-3 f-13 text-general">
                            Nombre de la comisión
                        </p>
                        <el-input v-model="model.condicion" maxlength="25">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-auto pt-3">
                    <div class="bg-general cr-pointer text-white br-12 py-2 px-2 mt-1" @click="crearCondicion(valid)">
                        <i class="icon-plus" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <div class="row mx-0 pt-3">
            <div class="col-auto" />
            <div class="col pt-3">
                <p class="text-general f-15">
                    Lista de comisiones
                </p>
                <ValidationObserver ref="validacion2" v-slot="{ valid }">
                    <div v-for="(data, d) in condiciones" :key="d" class="row mx-0 pt-2">
                        <div class="col-4">
                            <el-input v-model="data.condicion" class="w-100" :disabled="data.editar" />
                        </div>
                        <div class="col-auto px-2 d-middle-center">
                            <i v-if="!data.editar" class="icon-ok-circled-outline f-20 text-success" @click="editarCondicion(valid,data)" />
                            <i v-else class="icon-pencil-outline text-general2 f-20 cr-pointer" @click="data.editar = !data.editar " />
                        </div>
                        <div class="col-auto px-2 d-middle-center">
                            <i v-if="data.editar" class="icon-trash-empty text-general2 f-20 cr-pointer" @click="eliminar(data.id)" />
                        </div>
                        <div class="col-auto pt-2">
                            <el-tooltip placement="bottom" content="Cedis que utilizan esta condición" effect="light">
                                <div class="bg-general3 text-white px-3 br-20 d-middle">
                                    <i class="icon-cedis f-18 ml-auto" />
                                    <span>
                                        {{ agregarSeparadoresNumero(data.cedis_count) }}
                                    </span>
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto pt-2">
                            <el-tooltip placement="bottom" content="Pedidos realizado con esa condición en los últimos 3 meses" effect="light">
                                <div class="bg-general3 text-white px-3 br-20 d-middle">
                                    <i class="icon-pedidos f-18 ml-auto" />
                                    <span>
                                        {{ agregarSeparadoresNumero(data.pedidos_count) }}
                                    </span>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar condición" mensaje="¿Desea eliminar la condición?" @eliminar="deleteCondicion" />
    </section>
</template>

<script>
import Condiciones from "~/services/configurar/admin/condiciones";
export default {
    data(){
        return {
            model: {
                condicion: '',
            },
            condiciones: []
        }
    },
    mounted(){
        this.listarCondiciones()
    },
    methods: {
        async listarCondiciones(){
            try {
                const params = {
                    calculate: true
                }
                const { data } = await Condiciones.listarCondiciones(params)
                data.condiciones.map(el => {
                    el.editar = true
                })
                this.condiciones = data.condiciones
            } catch (error){
                console.error(error);
            }
        },
        async crearCondicion(valid){
            try {
                if(valid){
                    const {data} = await Condiciones.crearCondicion(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.limpiar()
                    this.listarCondiciones()
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarCondicion(valid,row){
            try {
                if(valid){
                    let datos = {
                        id: row.id,
                        condicion: row.condicion,
                    }
                    const {data} = await Condiciones.editarCondicion(datos)
                    row.editar = !row.editar
                    this.notificacion('', data.mensaje, 'success')
                    this.listarCondiciones()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        eliminar(id){
            if(id){
                this.id_eliminar = id
            }
            this.$refs.modalEliminar.toggle()
        },
        async deleteCondicion(id){
            try {
                const {data} = await Condiciones.deleteCondicion(this.id_eliminar)
                this.notificacion('', data.mensaje, 'success')
                this.listarCondiciones()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model.condicion = null
            this.$refs.validacion.reset();
        }
    }
}
</script>

<style>

</style>
