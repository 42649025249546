import axios from 'axios'

const api = "configurar"

const CedisGaming = {

    getCedisGaming: () => axios.get(`${api}/get-cedis-gaming`),
    setCedisGaming: (params) => axios.put(`${api}/set-cedis-gaming`,params)
}

export default CedisGaming 