<template>
    <section>
        <div class="row mx-0">
            <!-- <menuPrincipal :menus="menus" /> -->
            <div class="overflow-auto custom-scroll menu-prinicipal br-10" style="width:380px;">
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showProductos = !showProductos">
                    <div class="col text-general f-18 text-left f-600">
                        Productos
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showProductos?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showProductos">
                    <menuPrincipal :menus="productos" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showDomicilios = !showDomicilios">
                    <div class="col text-general f-18 text-left f-600">
                        Domicilios
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showDomicilios?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showDomicilios">
                    <menuPrincipal :menus="domicilios" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showLeecheros = !showLeecheros">
                    <div class="col text-general f-18 text-left f-600">
                        {{ $config.vendedor }}
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showLeecheros?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showLeecheros">
                    <menuPrincipal :menus="Leecheros" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showCedis = !showCedis">
                    <div class="col text-general f-18 text-left f-600">
                        Cedis
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showCedis?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showCedis">
                    <menuPrincipal :menus="cedis" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showClientes = !showClientes">
                    <div class="col text-general f-18 text-left f-600">
                        {{ $config.cliente }}
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showClientes?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showClientes">
                    <menuPrincipal :menus="clientes" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showGestionPedidos = !showGestionPedidos">
                    <div class="col text-general f-18 text-left f-600">
                        Gestión de pedidos
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showGestionPedidos?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showGestionPedidos">
                    <menuPrincipal :menus="gestionPedidos" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showFaqs = !showFaqs">
                    <div class="col text-general f-18 text-left f-600">
                        FAQs
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showFaqs?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showFaqs">
                    <menuPrincipal :menus="faqs" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showDelivery = !showDelivery">
                    <div class="col text-general f-18 text-left f-600">
                        Delivery
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showDelivery?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showDelivery">
                    <menuPrincipal :menus="delivery" :completed="false" />
                    <!-- Separador -->
                    <div class="row mx-0 py-4 border-top border-bottom" />
                    <!-- Separador -->
                </template>
                <div class="row mx-0 align-items-center py-3 border-bottom justify-center px-3 cr-pointer" @click="showAspectoSistema = !showAspectoSistema">
                    <div class="col text-general f-18 text-left f-600">
                        Personalización
                    </div>
                    <div class="width-circle d-middle-center bg-general3 br-50 text-white">
                        <i :class="`${showAspectoSistema?'icon-angle-up': 'icon-angle-down'}`" />
                    </div>
                </div>
                <template v-if="showAspectoSistema">
                    <menuPrincipal :menus="aspectoSistema" :completed="false" />
                </template>
            </div>
            <div class="px-0 br-t-10 menu-prinicipal ml-3 w-content">
                <!-- <component :is="activo" /> -->
                <router-view />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    metaInfo:()=>({ title: 'Configurar Admin' }),
    middleware:['auth'],
    data(){
        return {
            activo: 'tipoTiendas',
            showProductos: false,
            showDomicilios: false,
            showLeecheros: false,
            showClientes: false,
            showGestionPedidos: false,
            showFaqs: false,
            showAspectoSistema: false,
            showDelivery: false,
            showCedis: false,
            productos: [
                { nombre: 'Categorías de productos', ruta: 'admin.conf.productos.categorias', icon: 'icon-package-variant' , can : 'menu_productos_configuracion_categorias' },
                { nombre: 'Etiquetas de productos', ruta: 'admin.conf.productos.etiquetas', icon: 'icon-tag' , can : 'menu_productos_configuracion_etiquetas' },
                { nombre: 'Unidades de medida de productos', ruta: 'admin.conf.productos.unidades', icon: 'icon-ruler' , can : 'menu_productos_configuracion_unidades' },
                { nombre: 'Motivos de movimientos de productos', ruta: 'admin.conf.productos.motivos-movimientos', icon: 'icon-swap-horizontal-bold' , can : 'menu_productos_configuracion_unidades' },
                { nombre: 'Peso volumétrico', ruta: 'admin.conf.productos.peso-volumetrico', icon: 'icon-axis-arrow' , can : 'menu_productos_configuracion_peso_volumetrico' },
                { nombre: 'Impuestos', ruta: 'admin.conf.productos.impuestos', icon: 'icon-impuestos' , can : 'menu_productos_configuracion_unidades' },
            ],
            domicilios: [
                { nombre: 'Distancia de los domicilios para precio', ruta: 'admin.conf.distancia', icon: 'icon-ruler' , can : 'menu_domicilios_configuracion_distancia' },
                { nombre: 'Tiempos de estados de los pedidos', ruta: 'admin.conf.tiempos', icon: 'icon-clock-end' , can : 'menu_domicilios_configuracion_tiempos' },
            ],
            Leecheros: [
                { nombre: 'Motivos de inactividad', ruta: 'admin.conf.motInactivo', icon: 'icon-account-reactivate' , can : 'menu_leecheros_configuracion_motivos' },
                { nombre: '', ruta: 'admin.conf.selectLeechero', icon: 'icon-leechero-cap f-25' , can : 'menu_leecheros_configuracion_seleccion' },
                { nombre: 'Tipo de registro por país', ruta: 'admin.conf.tipoRegistro', icon: 'icon-flag f-25' , can : 'menu_leecheros_configuracion_tipo_registro' },
                { nombre: 'Selección de cedis por defecto', ruta: 'admin.conf.cedisDefecto', icon: 'icon-cedis' , can : 'menu_leecheros_configuracion_seleccion_cedis' },
				{ nombre: 'Comisiones de venta', ruta: 'admin.conf.comisiones', icon: 'icon-price-check' , can : 'menu_leecheros_configuracion_comisionescondiciones' },
                { nombre: 'Descuentos de lista', ruta: 'admin.conf.descuentos-lista', icon: 'icon-sale f-25' , can : 'menu_leecheros_configuracion_descuentos_lista' },
                { nombre: 'Descuentos de cupón', ruta: 'admin.conf.vendedores.descuentos.cupones', icon: 'icon-cupon' , can : 'menu_leecheros_configuracion_descuentos_cupones' },
            ],
            cedis: [
                { nombre: 'Valor mínimo del pedido', ruta: 'admin.conf.valorMinimoPedido', icon: 'icon-currency-usd-circle' , can : 'menu_leecheros_configuracion_valor_minimo' },
                { nombre: 'Aplicar Impuestos', ruta: 'admin.conf.aplicar-impuestos', icon: 'icon-impuestos', can: 'menu_cedis_configuracion_impuestos'},
                { nombre: 'Cobertura de entregas', ruta: 'admin.conf.cobertura-entregas', icon: 'icon-route' , can : 'menu_domicilios_configuracion_cobertura' },
                { nombre: 'Horario de atención', ruta: 'admin.conf.horario-atencion', icon: 'icon-calendar' , can : 'menu_domicilios_configuracion_cobertura' },
                /* Se separaron los impuestos productos y impuestos cedis en aplicar impuestos y inventario diferente */
                /* { nombre: 'Etiqueta de impuesto para productos', ruta: 'admin.conf.impuestos-inventario', icon: 'icon-cedis' , can : 'menu_domicilios_configuracion_franjas' }, */
                { nombre: 'Parámetros por defecto', ruta: 'admin.conf.parametros-defecto', icon: 'icon-format-list-checks' , can : 'menu_leecheros_parametros_defecto' },
                { nombre: 'Operador logístico', ruta: 'admin.conf.operador-logistico', icon: 'icon-truck', can: 'menu_cedis_configuracion_logistica'},
                { nombre: 'Días laborales', ruta: 'admin.conf.dias-laborales', icon: 'icon-fecha-fin', can: 'menu_cedis_configuracion_dias_laborales' },
                { nombre: 'Franjas horarias de entregas', ruta: 'admin.conf.franjas-horarias', icon: 'icon-franjas-horarias' , can : 'menu_domicilios_configuracion_franjas' },
                { nombre: 'Almacenar inventario en CEDIS diferente', ruta: 'admin.conf.almacenar-inventario', icon: 'icon-package-variant', can: 'menu_cedis_configuracion_inventario' },
                { nombre: 'Productos que no se pueden vender sin stock', ruta: 'admin.conf.productos-stock', icon: 'icon-cash-lock-open', can: 'menu_cedis_configuracion_productos_stock' },
                { nombre: 'Usar catálogo de CEDIS diferente', ruta: 'admin.conf.catalogo-cedis', icon: 'icon-book-open-page', can:'menu_cedis_configuracion_catalogo' },
                { nombre: 'Imagen de perfil', ruta: 'admin.conf.imagen-perfil', icon: 'icon-image', can: 'menu_cedis_configuracion_imagen_perfil' },
                { nombre: 'Home app vendedor ', ruta: 'admin.conf.home-app', icon: 'icon-mobile' , can : 'menu_leecheros_parametros_defecto' },
                { nombre: 'Factura', ruta: 'admin.conf.factura', icon: 'icon-pedidos' , can : 'menu_aspecto_configuracion_logos_sistema' },
                { nombre: 'Gaming', ruta: 'admin.conf.gaming', icon: 'icon-controller' , can : 'menu_cedis_configuracion_gaming' },
                { nombre: 'Parámetros temporales', ruta: 'admin.conf.parametros-temporales', icon: 'icon-history' , can : 'menu_cedis_configuracion_parametros_temporales' },
                { nombre: 'Forma de pago', ruta: 'admin.conf.forma-pago', icon: 'icon-comision' , can : 'menu_cedis_configuracion_parametros_temporales' },
                //admin.conf.forma-pago
                //admin.conf.gaming
            ],
            clientes: [
                { nombre: 'Tipos de clientes', ruta: 'admin.conf.tipos-clientes', icon: 'icon-cliente' , can : 'menu_clientes_configuracion_tipo_cientes' },
                { nombre: 'Avatares de clientes', ruta: 'admin.conf.grupoAvatares', icon: 'icon-sticker-emoji' , can : 'menu_aspecto_configuracion_avatares_clientes' }
            ]
            ,
            gestionPedidos: [
                { nombre: 'Criterios de calificación de pedidos', ruta: 'admin.conf.criterios', icon: 'icon-star' , can : 'menu_pedidos_configuracion_criterios_calificacion' },
                { nombre: 'Motivos de rechazo por tendero', ruta: 'admin.conf.motRechazo', icon: 'icon-playlist-remove' , can : 'menu_pedidos_configuracion_motivos_rechazo' },
                { nombre: 'Motivos de cancelación por tendero', ruta: 'admin.conf.motCanTendero', icon: 'icon-cancel-circled-outline' , can : 'menu_pedidos_configuracion_cancelacion_tienda' },
                { nombre: 'Motivos de cancelación por cliente', ruta: 'admin.conf.motCanCliente', icon: 'icon-account-remove-outline' , can : 'menu_pedidos_configuracion_cancelacion_cliente' },
                { nombre: 'Motivos de reclamo', ruta: 'admin.conf.motReclamo', icon: 'icon-attention-alt' , can : 'menu_pedidos_configuracion_motivos_reclamo' },
                { nombre: 'Motivos de eliminación de producto', ruta: 'admin.conf.motEliminacion', icon: 'icon-delete-outline' , can : 'menu_pedidos_configuracion_eliminacion_producto' },
                { nombre: 'Motivos de devolución de producto', ruta: 'admin.conf.motDevolucion', icon: 'icon-swap-horizontal-bold' , can : 'menu_pedidos_configuracion_devolucion_producto' },
                { nombre: 'Motivos de cambio precio de producto', ruta: 'admin.conf.camPrecio', icon: 'icon-currency-usd-circle' , can : 'menu_pedidos_configuracion_cambio_precio' },
                { nombre: 'Cancelación según estados', ruta: 'admin.conf.motCancelacion', icon: 'icon-alert-circled' , can : 'menu_pedidos_configuracion_cambio_precio' },
            ],
            faqs: [
                { nombre: 'Preguntas más frecuentes', ruta: 'admin.conf.preguntas', icon: 'icon-help-circle-outline' , can : 'menu_faqs_configuracion_preguntas_frecuentes' },
                { nombre: 'Términos y condiciones', ruta: 'admin.conf.terminos', icon: 'icon-book-open-variant' , can : 'menu_faqs_configuracion_terminos_condiciones' },
                { nombre: 'Política de privacidad', ruta: 'admin.conf.politica', icon: 'icon-lock' , can : 'menu_faqs_configuracion_politica_privacidad' },
            ],
            aspectoSistema: [
                { nombre: 'Logos del sistema', ruta: 'admin.conf.logSistema', icon: 'icon-heart-empty' , can : 'menu_aspecto_configuracion_logos_sistema' },
                { nombre: 'Imagenes de Ingreso y registro', ruta: 'admin.conf.img-ingreso-registro', icon: 'icon-house-empty' , can : 'menu_aspecto_configuracion_logos_sistema' },
                { nombre: 'Imagenes de estados', ruta: 'admin.conf.logSistema.img-estados', icon: 'icon-image-outline' , can : 'menu_aspecto_configuracion_logos_sistema' },
                { nombre: 'Cargando versión web y movil', ruta: 'admin.conf.logSistema.img-web-movil', icon: 'icon-loading' , can : 'menu_aspecto_configuracion_logos_sistema' },
                { nombre: 'Screen splash de apps', ruta: 'admin.conf.screen-splash', icon: 'icon-mobile' , can : 'menu_aspecto_configuracion_logos_sistema' },
                { nombre: 'Imagenes para apps', ruta: 'admin.conf.img-apps', icon: 'icon-tooltip-image-outline' , can : 'menu_aspecto_configuracion_logos_sistema' },
            ],
            delivery: [
                { nombre: 'Motivos de novedades', ruta: 'admin.conf.motivos-novedades', icon: 'icon-attention-alt' , can : 'menu_delivery_configuracion_motivos_novedades' },
            ]
        }
    },
    mounted(){
        this.Leecheros[1].nombre = `Selección de ${this.$config.vendedor} por defecto`
        this.cedis[14].nombre = `Home app ${this.$config.vendedor}`
        this.clientes[0].nombre = `Tipos de ${this.$config.cliente}`
        this.clientes[1].nombre = `Avatares de ${this.$config.cliente}`
        this.gestionPedidos[1].nombre = `Motivos de rechazo por ${this.$config.vendedor}`
        this.gestionPedidos[2].nombre = `Motivos de cancelación por ${this.$config.vendedor}`
        this.gestionPedidos[3].nombre = `Motivos de cancelación por ${this.$config.cliente}`
		if(`${this.$config.menu.gaming}` == 0)this.cedis.splice(this.cedis.findIndex( el => el.ruta == 'admin.conf.gaming'), 1)
    },
}
</script>

<style lang="css" scoped>
.menu-prinicipal{
    height: calc(100vh - 98px);
    width: calc(100vw - 506px);
    background: #fff;
}
.width-circle{
    width: 20px;
    height: 20px;
}
</style>
