<template>
    <section>
        <div class="row mx-0 py-3 border-bottom pl-4">
            <div class="col-auto f-18 text-general f-500">
                Factura
            </div>
        </div>
        <div class="custom-scroll overflow-auto pl-4 pr-2 pt-4" style="height:calc(100vh - 164px)">
            <div class="row mx-0 mb-5">
                <p class="col-12 text-general f-15">
                    Personalice el pie de pagina de la factura para cada uno de los CEDIS
                </p>
            </div>
            <div v-for="data in cedis" :key="data.id" class="row mx-0 mb-5">
                <div class="col-4 text-general f-500">
                    {{ data.nombre }}
                    <i v-show="!data.estado" class="icon-eye-off" />
                </div>
                <div class="col">
                    <p class="f-12 text-general pl-3">Texto en el pie de la factura</p>
                    <el-input v-model="data.texto_factura" placeholder="Texto" class="w-100" type="textarea" :rows="5" @change="update_factura(data.id,data.texto_factura)" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import FacturaCedis from "../../../services/configurar/admin/factura";
export default {
    data(){
        return {
            cedis: []
        }
    },

    mounted(){
        this.get_cedis();
    },

    methods: {
        async get_cedis(){
            try {
                const {data} = await FacturaCedis.getCedis()
                this.cedis = data.cedis;
            } catch (e){
                this.error_catch(e)
            }
        },

        async update_factura(id,texto){
            try {
                if (texto != ''){
                    const {data} = await FacturaCedis.updateCedi(id,texto)
                    this.notificacion('', data.mensaje, 'success')
                } else {
                    this.notificacion('Advertencia', 'El campo texto es obligatorio', 'warning')
                }
            } catch (e){
                this.error_catch(e)
            }
        },
    },
}
</script>
