var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row mx-0 border-bottom pl-5 py-2 f-600 text-general"},[_vm._v(" Franjas horarias de entregas de pedidos por Cedis ")]),_c('div',{staticClass:"pl-5 px-3 overflow-auto custom-scroll",staticStyle:{"height":"calc(100vh - 144px)"}},_vm._l((_vm.cedis),function(data){return _c('div',{key:data.id,staticClass:"row mx-0 mt-3 px-4"},[_c('div',{staticClass:"col border-bottom  pb-4"},[_c('div',{staticClass:"row mx-0 mt-2"},[_c('div',{staticClass:"col-xl-3 col-md d-middle pt-4"},[_c('p',{staticClass:"text-gr-general f-600 mb-2"},[_vm._v(" "+_vm._s(data.nombre)+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(data.estado==false),expression:"data.estado==false"}],staticClass:"icon-eye-off"})])]),_c('div',{staticClass:"col-xl col-md-12 my-md-2"},[_c('ValidationProvider',{attrs:{"rules":"required|between:1,24","name":"franja cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('label',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Franja Horaria ")]),_c('el-input',{staticClass:"w-100 append-edit",on:{"change":function($event){return _vm.update_franja(data.tipo1,valid)}},model:{value:(data.tipo1.tiempo),callback:function ($$v) {_vm.$set(data.tipo1, "tiempo", $$v)},expression:"data.tipo1.tiempo"}},[_c('template',{slot:"append"},[_c('i',[_vm._v("Horas")])])],2),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-xl col-md-12 my-md-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"inicio cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('label',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Hora de Inicio ")]),_c('el-select',{staticClass:"w-100",on:{"change":function($event){return _vm.update_franja(data.tipo1,valid)}},model:{value:(data.tipo1.inicio),callback:function ($$v) {_vm.$set(data.tipo1, "inicio", $$v)},expression:"data.tipo1.inicio"}},_vm._l((_vm.horas),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-xl col-md-12 my-md-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"fin cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('label',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Hora Fin ")]),_c('el-select',{staticClass:"w-100",on:{"change":function($event){return _vm.update_franja(data.tipo1,valid)}},model:{value:(data.tipo1.fin),callback:function ($$v) {_vm.$set(data.tipo1, "fin", $$v)},expression:"data.tipo1.fin"}},_vm._l((_vm.horas),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 px-0 my-4"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-xl col-md-12"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general"},[_vm._v(" Tiempo mínimo de programación para pedir al cedis ")]),_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('ValidationProvider',{attrs:{"rules":"required|between:1,255","name":"promagración cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{staticClass:"w-100 append-edit",on:{"change":function($event){return _vm.update_franja(data.tipo1,valid)}},model:{value:(data.tipo1.programacion),callback:function ($$v) {_vm.$set(data.tipo1, "programacion", $$v)},expression:"data.tipo1.programacion"}},[_c('template',{slot:"append"},[_c('i',[_vm._v("Horas")])])],2),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])]),_c('div',{staticClass:"col-xl col-md-12"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general"},[_vm._v(" Tiempo máximo de programación de pedidos ")]),_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('ValidationProvider',{attrs:{"rules":"required|between:1,255","name":"tiempo maximo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{staticClass:"w-100 append-edit",on:{"change":function($event){return _vm.update_franja(data.tipo1,valid)}},model:{value:(data.tipo1.tiempo_maximo),callback:function ($$v) {_vm.$set(data.tipo1, "tiempo_maximo", $$v)},expression:"data.tipo1.tiempo_maximo"}},[_c('template',{slot:"append"},[_c('i',[_vm._v("Días")])])],2),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])])])])]),_c('div',{staticClass:"row mx-0 mt-3"},[_c('div',{staticClass:"col-xl-3 col-md d-flex align-items-end"},[_c('p',{staticClass:"mb-2 text-general"},[_vm._v(" "+_vm._s(_vm.$config.vendedor)+" del Cedis ("+_vm._s(data.tiendas_count)+") ")])]),_c('div',{staticClass:"col-xl col-md-12 my-md-2"},[_c('ValidationProvider',{attrs:{"rules":"required|between:1,24","name":"franja"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('label',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Franja Horaria ")]),_c('el-input',{staticClass:"w-100 append-edit",on:{"change":function($event){return _vm.update_franja(data.tipo2,valid)}},model:{value:(data.tipo2.tiempo),callback:function ($$v) {_vm.$set(data.tipo2, "tiempo", $$v)},expression:"data.tipo2.tiempo"}},[_c('template',{slot:"append"},[_c('i',[_vm._v("Horas")])])],2),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-xl col-md-12 my-md-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"hora inicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('label',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Hora de Inicio ")]),_c('el-select',{staticClass:"w-100",on:{"change":function($event){return _vm.update_franja(data.tipo2,valid)}},model:{value:(data.tipo2.inicio),callback:function ($$v) {_vm.$set(data.tipo2, "inicio", $$v)},expression:"data.tipo2.inicio"}},_vm._l((_vm.horas),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-xl col-md-12 my-md-2"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"hora fin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('label',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Hora Fin ")]),_c('el-select',{staticClass:"w-100",on:{"change":function($event){return _vm.update_franja(data.tipo2,valid)}},model:{value:(data.tipo2.fin),callback:function ($$v) {_vm.$set(data.tipo2, "fin", $$v)},expression:"data.tipo2.fin"}},_vm._l((_vm.horas),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 px-0 my-4"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-xl col-md-12"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general"},[_vm._v(" Tiempo mínimo de programación para pedir al cedis ")]),_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('ValidationProvider',{attrs:{"rules":"required|between:1,255","name":"promagración cedis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{staticClass:"w-100 append-edit",on:{"change":function($event){return _vm.update_franja(data.tipo2,valid)}},model:{value:(data.tipo2.programacion),callback:function ($$v) {_vm.$set(data.tipo2, "programacion", $$v)},expression:"data.tipo2.programacion"}},[_c('template',{slot:"append"},[_c('i',[_vm._v("Horas")])])],2),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])]),_c('div',{staticClass:"col-xl col-md-12"},[_c('div',{staticClass:"row mx-0"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general"},[_vm._v(" Tiempo máximo de programación de pedidos ")]),_c('div',{staticClass:"col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"},[_c('ValidationProvider',{attrs:{"rules":"required|between:1,255","name":"tiempo maximo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{staticClass:"w-100 append-edit",on:{"change":function($event){return _vm.update_franja(data.tipo2,valid)}},model:{value:(data.tipo2.tiempo_maximo),callback:function ($$v) {_vm.$set(data.tipo2, "tiempo_maximo", $$v)},expression:"data.tipo2.tiempo_maximo"}},[_c('template',{slot:"append"},[_c('i',[_vm._v("Días")])])],2),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)])])])])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }