<template>
    <section>
        <div class="row mx-0 p-3 border-bottom">
            <div class="col-auto">
                <p class="f-18 text-general f-600">
                    Imágenes de perfil
                </p>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 164px)">
            <div class="row mx-0 py-3 my-3">
                <div class="col-auto" />
                <div class="col f-15 text-general">
                    Seleccione una imagen de perfil para uno de los CEDIS
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-auto px-4" />
                <div class="col">
                    <div class="row mx-0">
                        <template v-for="(cedi,index) in datos">
                            <div :key="index" class="mr-5 mb-4 cr-pointer" style="width:163px;">
                                <img :src="cedi.foto" width="160" height="160" class="bg-fondo border cr-pointer br-8 obj-cover mb-2" @click="editarImagen(cedi.id,cedi.foto)" />
                                <p class="text-general f-15 f-500 text-center tres-puntos">
                                    {{ cedi.nombre }}
                                    <i v-show="!cedi.estado" class="icon-eye-off" />
                                </p>
                            </div>
                            <div :key="`space-${index}`" class="col-auto px-4" />
                        </template>    
                    </div>
                </div>
            </div>
        </div>
        <modalEditarImagenCedis ref="modalEditarImagenCedis" @update="listarCedis()" />
    </section>
</template>


<script>
import cedisImagen from '~/services/configurar/admin/cedisImagen'
export default {
    components: {
        modalEditarImagenCedis: () => import('./partials/modalEditarImagenCedis')
    },
    data(){
        return {
            ciudad: false,
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            datos: []
        }
    },
    mounted(){
        this.listarCedis();
    },
    methods:{
        async listarCedis(){
            try {
                const { data } = await cedisImagen.getCedis();
                this.datos = data
            } catch (e){
                this.error_catch(e)
            }
        },
        editarImagen(id,foto){
            this.$refs.modalEditarImagenCedis.toggle(id,foto);
        }
    }
}
</script>