<template>
    <section>
        <div class="row mx-0 p-3 border-bottom">
            <div class="col-auto">
                <p class="f-18 text-general f-600">
                    Almacenar inventario en CEDIS diferente
                </p>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 160px)">
            <div class="row mx-0 my-4 py-2">
                <div class="col-auto" />
                <div class="col px-0 text-general f-15">
                    Indique si el CEDIS guardará su inventario en otro diferente y cual será
                </div>
            </div>
            <div v-for="(cedi,index) in datos" :key="index" class=" mx-0 w-100 mb-4">
                <div class="row mx-0">
                    <div class="col-3" />
                    <div class="col-1 text-center">
                        <div class="f-12 text-general">
                            Inventario propio
                        </div>
                    </div>
                    <div class="col-4 py-2 text-left">
                        <p class="f-12 text-general">
                            Seleccionar cedis
                        </p>
                    </div>
                </div>
                <div class="row d-middle mx-0">
                    <div class="col-3  f-15 text-general f-500">
                        {{ cedi.nombre }}
                        <i v-show="!cedi.estado" class="icon-eye-off" />
                    </div>
                    <el-checkbox v-model="cedi.activo" class=" col-1 check-dark text-center " @change="onChange(cedi.id,idInventario,cedi.activo)" />
                    <div class="col-4">
                        <el-select v-model="cedi.id_cedis_stock" placeholder="Seleccionar" size="small" class="w-100" :disabled="cedi.activo" @change="onChange(cedi.id,cedi.id_cedis_stock)">
                            <el-option
                            v-for="(cedis,ind) in datos"
                            :key="ind"
                            :label="cedis.nombre"
                            :value="cedis.id"
                            >
                                <span> {{ cedis.nombre }} </span>
                                <i v-show="!cedis.estado" class="icon-eye-off" />
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import cedisInventarioDiferente from '~/services/configurar/admin/cedisInventarioDiferente'
export default {
    data(){
        return {
            datos: [],
            idInventario: null
        }
    },
    mounted(){
        this.listarCedis();
    },
    methods: {
        async listarCedis(){
            try {
                const { data } = await cedisInventarioDiferente.getCedis();
                this.datos = data
                this.datos = this.datos.map(el=> ({...el, activo: el.id_cedis_stock? false : true}))
            } catch (e){
                this.error_catch(e);
            }
        },
        async onChange(idCedis,idInventario,activo){
            try {
                let params = {
                    id: idInventario ? idInventario : null
                }
                const { data } = await cedisInventarioDiferente.putCedisInventario(idCedis,params)
                if(activo===true){
                    let cedis = this.datos.find(el=> el.id == idCedis)
                    cedis.id_cedis_stock = null
                    this.notificacion("","Inventario actualizado correctamente","success")
                }else if(idInventario){
                    this.notificacion("","Inventario actualizado correctamente","success")
                }
            } catch (e){
                this.error_catch(e)
            }
            
        }
    }
}
</script>