import axios from 'axios'

const API = 'forma-pago'

const ENDPOINTS = {
    getFormaPagos(){
        return axios.get(`${API}/listar`)
    },
    postFormaPago(model){
        return axios.post(`${API}/guardar`, model)
    },
    changeFormaPago(model){
        return axios.post(`${API}/change`, model)
    },
    putFormaPago(id, model){
        return axios.put(`${API}/${id}/actualizar`, model)
    },
    deleteFormaPago(id){
        return axios.delete(`${API}/${id}/eliminar`)
    },
    postCedisFormasPagoAll(model){
        return axios.post(`${API}/guardar/todo`, model)
    },
    deleteFormasPagoAll(id){
        return axios.delete(`${API}/${id}/eliminar/todo`)
    },
};
export default ENDPOINTS;