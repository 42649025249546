import axios from 'axios'

const API = 'configurar/personalizacion';

const ENDPOINTS = {
    getLogosSistema(){
        return axios.get(`${API}/logos-sistema/listar`)
    },
    postLogoSistema(model){
        return axios.post(`${API}/logos-sistema/guardar`, model)
    },
    getImagenesIngresoRegistro(params){
        return axios.get(`${API}/ingreso-registro/listar`, {params})
    },
    postImagen(model){
        return axios.post(`${API}/ingreso-registro/guardar`, model)
    },
    deleteImagen(id){
        return axios.delete(`${API}/${id}/ingreso-registro/eliminar-imagen`)
    },
    getImagenesEstados(){
        return axios.get(`${API}/estados/listar`)
    },
    postImagenEstado(model){
        return axios.post(`${API}/estados/guardar`, model)
    },
    deleteImagenEstado(id){
        return axios.delete(`${API}/${id}/estados/eliminar-imagen`)
    },
    getWebMovil(){
        return axios.get(`${API}/web-movil/listar`)
    },
    postMovil(model){
        return axios.post(`${API}/web-movil/guardar`, model)
    },
    deleteImagenWebMovil(id){
        return axios.delete(`${API}/${id}/web-movil/eliminar`)
    },
    getScreenApps(){
        return axios.get(`${API}/screen-apps/listar`)
    },
	getImagesApps(){
        return axios.get(`${API}/images-apps/listar`)
    },
	postImagesApps(payload){
        return axios.post(`${API}/images-apps/guardar`, payload)
    },
	deleteImagesApps(idImagen){
        return axios.delete(`${API}/images-apps/${idImagen}/delete`)
    },
    //postImagenScreenApps(model){
    //    return axios.post(`${API}/screen-apps/guardar`, model)
    //},
    postScreenSplashApps(model){
        return axios.post(`${API}/screen-apps/guardar`, model)
    },
    deleteScreenApps(id){
        return axios.delete(`${API}/${id}/screen-apps/eliminar-imagen`)
    },
    updateOrdenImgIngresoRegistro(model){
        return axios.post(`${API}/ingreso-registro/orden-imagen`, model)
    },
    postImagenIngreso(model){
        return axios.post(`${API}/login/image/save`, model)
    }

};
export default ENDPOINTS
