<template>
    <section>
        <div class="row mx-0 py-3 px-2 border-bottom">
            <div class="col-auto f-17 f-600 text-general">
                Impuestos e inventario
            </div>
        </div>
        <div class="custom-scroll px-3 overflow-auto" style="height:calc(100vh - 118px)">
            <div class="row mx-0 py-3 border-bottom">
                <div class="col-3" />
                <div class="col text-general f-17 f-600">
                    Impuestos por defecto <br /> para productos
                </div>
                <div class="col text-general f-17 f-600">
                    Inventario en cedis <br /> diferente
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div v-for="(data,d) in cedis" :key="d" class="row mx-0 mb-5">
                <div class="col-3 pt-3 d-flex align-items-center text-general f-15 f-600">
                    {{ data.nombre }}
                </div>
                <div class="col">
                    <p class="text-general f-12 pl-4">Impuesto</p>
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-2 pr-0">
                            <el-select v-model="data.impuestos" size="small" class="w-100" @change="value => editarImpuestosInventario(data,value, 1)">
                                <el-option label="Sin Impuestos" :value="0" />
                                <el-option label="Valores +IVA" :value="1" />
                                <el-option label="IVA incluido" :value="2" />
                                <el-option label="IVA incluido sin etiqueta" :value="3" />
                            </el-select>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 align-items-center">
                        <el-checkbox :key="key" v-model="data.cedis_inventario" class="check-dark mx-1 mt-4" @change="value => editarImpuestosInventario(data,value, 2)" />
                        <div v-if="data.cedis_inventario" class="col pl-2 pr-0 position-relative ">
                            <el-select v-model="data.id_cedis_stock" size="small" placeholder="Cedis" class="w-100" @change="value => editarImpuestosInventario(data,value, 3)">
                                <el-option
                                v-for="item in cedis"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                :disabled="data.id == item.id"
                                />
                            </el-select>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Cobertura from '~/services/configurar/admin/CoberturaDefecto'
export default {
    data(){
        return {
            key: false,
        }
    },
    computed: {
        cedis(){
            return this.cedis_calculo.reduce((acc, el) => {
                if(el.id != 0){
                    el.cedis_inventario = el.id_cedis_stock != null                    
                    acc.push(el)
                }
                return acc
            }, [])
        }
    },
    methods: {
        async editarImpuestosInventario(value, valid, tipo = 0){
            try {
                this.key = !this.key
                if (tipo == 1){
                    value.impuestos = valid
                }else if(tipo == 2){
                    value.id_cedis_stock = null                
                }else if(tipo == 3){
                    value.id_cedis_stock = valid
                }
                if ( valid && tipo == 2) return
                let datos = {
                    id_cedis: value.id,
                    id_cedis_stock: value.id_cedis_stock,
                    impuestos: value.impuestos,
                }
                const {data} = await Cobertura.editarImpuestosInventario(datos)
                this.notificacion('', data.mensaje, 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>