<template>
    <section>
        <div class="row mx-0 py-3 px-2 border-bottom">
            <div class="col-auto f-17 f-600 text-general">
                Cobertura de entregas
            </div>
        </div>
        <div class="custom-scroll px-3 overflow-auto" style="height:calc(100vh - 165px)">
            <div class="row mx-0 py-3 border-bottom">
                <div class="col-2" />
                <div class="col text-general f-17 f-600">
                    Cobertura entre <br /> CEDIS y {{$config.vendedor}}
                </div>
                <div class="col text-general f-17 f-600">
                    Cobertura entre <br /> CEDIS y {{$config.vendedor}}
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div v-for="(data,d) in cedis" :key="d" class="row mx-0 mb-4">
                <div class="col-2 pt-3 d-flex align-items-center text-general f-15 f-600">
                    {{ data.nombre }}
                    <i v-show="!data.estado" class="icon-eye-off ml-1" />
                </div>
                <div class="col">
                    <p class="text-general f-12 pl-4">Distancia (metros)</p>
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-2 pr-0">
                            <ValidationProvider v-slot="{errors, valid}" rules="numeric|max:7" name="cobertura">
                                <!-- <input-miles v-model="data.cobertura" :money-options="config_distancias" @blur.native="editarCoberturaCedis(data,valid)" /> -->
                                <el-input v-model="data.cobertura" :disabled="data.checkedCobertura" class="w-100" @change="editarCoberturaCedis(data,valid)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <el-checkbox :key="key" v-model="data.checkedCobertura" class="check-dark mx-1" @change="value => editarCoberturaCedis(data,value, 1)" />
                        <span class="text-general">Ilimitada</span>
                    </div>
                </div>
                <div class="col">
                    <p class="text-general f-12 pl-4">Distancia (metros)</p>
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-2 pr-0">
                            <ValidationProvider v-slot="{errors, valid}" rules="numeric|max:5" name="cobertura_leecheros">
                                <!-- <input-miles v-model="data.cobertura_leecheros" :money-options="config_distancias" @blur.native="editarCoberturaCedis(data,valid)" /> -->
                                <el-input v-model="data.cobertura_leecheros" :disabled="data.checkedCoberturaLeecheros" class="w-100" @change="editarCoberturaCedis(data,valid)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <el-checkbox :key="key2" v-model="data.checkedCoberturaLeecheros" class="check-dark mx-1" @change="value => editarCoberturaCedis(data,value, 2)" />
                        <span class="text-general">Ilimitada</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Cobertura from '~/services/configurar/admin/CoberturaDefecto'

export default {
    data(){
        return {
            key: false,
            key2: false,
            cedis: []
        }
    },
    mounted(){
        this.listarCedis()
    },
    methods: {
        async listarCedis(){
            try {
                const { data } = await Cobertura.listarCedis()
                
                this.cedis = data.data
                this.cedis.map((el) => {
                    el.checkedCobertura = el.cobertura ? false : true
                    el.checkedCoberturaLeecheros = el.cobertura_leecheros ? false : true
                    return el 
                })

            } catch (error){
                this.error_catch(error)
            }
        },
        async editarCoberturaCedis(value, valid, tipo = 0){
            try {
                this.key = !this.key
                this.key2 = !this.key2
                if (tipo == 1){
                    value.cobertura = null
                }else if(tipo == 2){
                    value.cobertura_leecheros = null
                }
                if (valid){
                    let datos = {
                        id_cedis: value.id,
                        cobertura: value.cobertura,
                        cobertura_leecheros: value.cobertura_leecheros,
                    }
                    // console.log({datos});
                    // return 
                    const {data} = await Cobertura.editarCoberturaCedis(datos)
                    this.notificacion('', data.mensaje, 'success')
                    // this.listarTenderoDomicilios()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
    }    
}
</script>