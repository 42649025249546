<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 text-general">
            Grupo de avatares
        </div>
        <div class="px-3 f-15">
            <div class="row mx-0 text-general my-4">
                Selecciona los grupos de avatares de los cuales los clientes podrán escoger su preferido
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-5">
                    <ValidationProvider v-slot="{ errors }" rules="required|max:30" name="nombre grupo" tag="div" class="col-5 px-2">
                        <p class="text-general pl-3 f-14">
                            Nombre del grupo de avatares
                        </p>
                        <el-input v-model="nombre_grupo" maxlength="30" show-word-limit />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <div class="col-auto pt-3">
                        <el-tooltip class="item" effect="light" content="Crear grupo" placement="bottom">
                            <div class="bg-general cr-pointer d-middle-center br-10 mt-1" style="width:40px;height:40px;">
                                <i class="icon-plus f-20 text-white" @click="crear_grupo_avatar(valid)" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <div class="custom-scroll overflow-auto" style="height:calc(100vh - 352px);">
                <div v-for="(grupo, idx) in grupos" :key="idx">
                    <div class="row mx-0 px-2">
                        <div class="col px-0 border br-10 my-2">
                            <div class="d-middle mx-0 ">
                                <div class="px-2 br-l-10 bg-general2" style="height:40px;" />
                                <p class="tres-puntos f-17 mx-2 text-general">{{ grupo.nombre }}</p>
                                <el-popover
                                v-model="grupo.popover"
                                popper-class="popover-edit"
                                placement="bottom-end"
                                class="p-0 ml-auto"
                                width="300"
                                trigger="click"
                                >
                                    <div class="row mx-0 justify-center align-items-center position-relative" style="background-color:#F8F9FF;height:50px;">
                                        <div class="col text-general text-center px-0 f-17 f-600">
                                            Editar grupo de avatares
                                        </div>
                                        <div class="ml-auto mr-2 bg-general3 text-white rounded-circle d-middle-center cr-pointer" style="width:20px;height:20px;" @click=" grupo.popover = false">
                                            <i class="icon-cancel" />
                                        </div>
                                    </div>
                                    <ValidationObserver v-slot="{ valid }">
                                        <div class="row mx-0 my-4">
                                            <div class="col px-5">
                                                <label class="px-3 text-general f-500">
                                                    Nombre del grupo
                                                </label>
                                                <ValidationProvider v-slot="{ errors }" rules="required|max:30" name="nombre grupo">
                                                    <el-input v-model="grupo.nombre" maxlength="30" show-word-limit />
                                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                                </ValidationProvider>
                                            </div>
                                        </div>
                                        <div class="row mx-0 justify-center pb-3">
                                            <div class="col-5 px-1">
                                                <div class="bg-light-f5 cr-pointer p-2 d-middle-center br-10 f-17" @click=" grupo.popover = false">
                                                    Cancelar
                                                </div>
                                            </div>
                                            <div class="col-5 px-1">
                                                <div class="bg-general text-white cr-pointer p-2 d-middle-center br-10 f-17" @click="editar_grupo_avatar(valid, grupo)">
                                                    Aceptar
                                                </div>
                                            </div>
                                        </div>
                                    </ValidationObserver>
                                    <el-tooltip slot="reference" class="item" effect="light" content="Editar" placement="bottom">
                                        <div class="cr-pointer br-10">
                                            <i class="icon-pencil-outline f-20 hover-icons" />
                                        </div>
                                    </el-tooltip>
                                </el-popover>
                                <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                    <div class="cr-pointer br-10" @click="eliminarGrupo(grupo.id)">
                                        <i class="icon-trash-empty hover-inverse f-20" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip class="item" effect="light" content="Agregar avatar" placement="bottom">
                                    <div class="bg-general br- d-middle br-5 text-white cr-pointer m-1" style="width:32px;height:32px;" @click="subirAvatar(grupo.id)">
                                        <i class="icon-plus f-22" />
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <el-popover
                    v-for="(data, keyidx) in grupo.avatar" :key="`item-${keyidx}`"
                    v-model="data.popover"
                    placement="bottom"
                    trigger="click"
                    >
                        <div class="row mx-0 p-1 px-2 br-20 hover-options justify-center" @click="editarAvatar(data)">
                            Editar
                        </div>
                        <div class="row mx-0 p-1 px-2 br-20 hover-options justify-center" @click="eliminarAvatar(data)">
                            Eliminar
                        </div>
                        <img
                        slot="reference"
                        class="rounded-circle m-2 ml-3 bg-white obj-cover cr-pointer"
                        :class="activo==idx?'border-general2':'border'"
                        width="65" height="65"
                        :src="data.imagen_firmada"
                        @click="activo=idx"
                        />
                    </el-popover>
                </div>
            </div>
        </div>
        <modalSubirAvatar ref="modalSubirAvatar" @actualizar="get_avatares" />
        <modalEliminar ref="modalEliminarAvatar" titulo="Eliminar avatar" mensaje="¿Desea eliminar este avatar?" @eliminar="eliminar_avatar" />
        <modalEliminar ref="modalEliminarGrupo" titulo="Eliminar grupo" mensaje="¿Desea eliminar este grupo?" @eliminar="eliminar_avatar" />
    </section>
</template>

<script>
import Avatar from "~/services/configurar/admin/grupoAvatares"

export default {
    components: {
        modalSubirAvatar: () => import('./modalSubirAvatar')
    },
    data(){
        return {
            closeEditar: false,
            id_eliminar: null,
            tipo_eliminar: null,
            nombre_grupo: '',
            activo: -1,
            grupos: [
                {
                    nombre: 'Grupo 1: General',
                    avatar: [
                        { popover: false, imagen_firmada: 'https://www.clipartmax.com/png/middle/20-206419_cookie-clipart-png-transparent-galletas-del-monstruo-de-las-galletas.png' },
                        { popover: false, imagen_firmada: 'https://www.clipartmax.com/png/middle/20-206419_cookie-clipart-png-transparent-galletas-del-monstruo-de-las-galletas.png' }
                    ]
                }
            ]
        }
    },
    mounted(){
        this.get_avatares()
    },
    methods: {
        async get_avatares(){
            try {
                const { data } =  await Avatar.get_grupos_avatares()
                data.avatares.map(a =>{
                    a.popover = false
                })
                this.grupos = data.avatares

            } catch (error){
                this.error_catch(error)
            }
        },
        async crear_grupo_avatar(valid){
            try {
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                let model = {
                    nombre: this.nombre_grupo
                }
                const { data } =  await Avatar.crear_grupo_avatar(model)
                this.nombre_grupo = ''
                this.$refs.validacion.reset()
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.get_avatares()
            } catch (error){
                this.error_catch(error)
            }
        },
        async editar_grupo_avatar(valid,dato){
            try {
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                let model = {
                    id: dato.id,
                    nombre: dato.nombre,
                }
                const { data } =  await Avatar.crear_grupo_avatar(model)
                dato.popover = false
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.get_avatares()
            } catch (error){
                this.error_catch(error)
            }
        },
        subirAvatar(id){
            let datos = {
                id: null,
                id_padre: id,
                imagen: null
            }
            this.activo = null
            this.$refs.modalSubirAvatar.toggle(datos)
        },
        editarAvatar(data){
            let datos = {
                id: data.id,
                id_padre: data.id_padre,
                imagen: data.imagen,
                imagen_firmada: data.imagen_firmada,
            }
            data.popover = false
            this.$refs.modalSubirAvatar.toggle(datos)
        },
        eliminarAvatar(data){
            this.id_eliminar = data.id
            this.tipo_eliminar = 2
            data.popover = false
            this.$refs.modalEliminarAvatar.toggle()
        },
        eliminarGrupo(id){
            this.id_eliminar = id
            this.tipo_eliminar = 1
            this.$refs.modalEliminarGrupo.toggle()
        },
        async eliminar_avatar(){
            try {
                const { data } =  await Avatar.eliminar_avatar(this.id_eliminar)
                this.get_avatares()
                this.notificacion('Mensaje', data.mensaje, 'success')
                if (this.tipo_eliminar == 1){
                    this.$refs.modalEliminarGrupo.toggle()
                } else {
                    this.$refs.modalEliminarAvatar.toggle()
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="css" scoped>
.bg-rosa{
    background: #f5f5f5;
}
</style>
