import axios from 'axios'
const valorminimo = {

    PostValorMinimo: (datos) => axios.post(`valor-minimo/guardar-valor-minimo`,datos),
    GetCedis: () => axios.get(`valor-minimo/listar-cedis`),
    GetHistorialValores: (id_cedis) => axios.get(`valor-minimo/${id_cedis}/historial-valores`),
    PutEditar: (datos) => axios.post(`valor-minimo/editar-tis-tipo`,datos),
    DeleteRegistro: (id,idcategoria) => axios.delete(`valor-minimo/${id}/${idcategoria}/eliminar-tis-tipo-registros`),
    Delete: (id) => axios.delete(`valor-minimo/${id}/eliminar-tis-tipo`)
}
export default valorminimo