<template>
	<section>
		<div v-if="show" class="row align-items-center mx-0 border-bottom py-3 px-3">
			<div class="col-auto text-general f-600">
				Condiciones de las comisiones
			</div>
			<div class="col-6" />
			<div class="col-auto px-2 bg-disabled border p-1 cr-pointer br-5">
				<i class="icon-pencil-outline f-20 text-black mt-1" slot="suffix" />
				<router-link :to="{name: 'admin.conf.condiciones'}">
					<span class="tab"> {{ text }} </span>
				</router-link>
			</div>
		</div>
		<div v-else class="row align-items-center mx-0 border-bottom py-3 px-3 text-black">
			<router-link :to="{name: 'admin.conf.comisiones-condiciones'}">
				<div>
					<i class="icon-angle-left f-20 text-black mt-1" slot="suffix" />
				</div>
			</router-link>
			<div class="col-auto text-general f-600">
				Tipo de comisiones
			</div>
		</div>

		<div class="custom-scroll overflow-auto" style="height:calc(100vh - 218px);">
			<router-view />
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			text: 'Editar clasificaciòn de descuentos',
		}
	},
	computed:{
        show(){
            const name = this.$route.name
            if(name == "admin.conf.comisiones-condiciones")return true
            return false
        }
    },
}
</script>


<style lang="css" scoped>
.orange-star {
	color: #FF9D32;
}


.nombre-condicion {
	height: 35px;
	background-color: #E9ECEF;
	color: var(--text-general) !important;
	font-size: 14px;
}

.tab{
    color: var(--text-general);
    text-decoration: none !important;
}
</style>