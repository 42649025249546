<template>
    <section>
        <div class="row mx-0 border-bottom pl-5 py-2 f-600 text-general">
            Franjas horarias de entregas de pedidos por Cedis
        </div>
        <div class="pl-5 px-3 overflow-auto custom-scroll" style="height:calc(100vh - 144px);">
            <div v-for="data in cedis" :key="data.id" class="row mx-0 mt-3 px-4">
                <div class="col border-bottom  pb-4">
                    <div class="row mx-0 mt-2">
                        <div class="col-xl-3 col-md d-middle pt-4">
                            <p class="text-gr-general f-600 mb-2">
                                {{ data.nombre }}
                                <i v-show="data.estado==false" class="icon-eye-off" />
                            </p>
                        </div>
                        <div class="col-xl col-md-12 my-md-2">
                            <ValidationProvider v-slot="{errors, valid}" rules="required|between:1,24" name="franja cedis">
                                <label class="pl-3 text-general f-15"> Franja Horaria </label>
                                <el-input v-model="data.tipo1.tiempo" class="w-100 append-edit" @change="update_franja(data.tipo1,valid)">
                                    <template slot="append">
                                        <i>Horas</i>
                                    </template>
                                </el-input>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-xl col-md-12 my-md-2">
                            <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="inicio cedis">
                                <label class="pl-3 text-general f-15"> Hora de Inicio </label>
                                <el-select v-model="data.tipo1.inicio" class="w-100" @change="update_franja(data.tipo1,valid)">
                                    <el-option
                                    v-for="item in horas"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-xl col-md-12 my-md-2">
                            <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="fin cedis">
                                <label class="pl-3 text-general f-15"> Hora Fin </label>
                                <el-select v-model="data.tipo1.fin" class="w-100" @change="update_franja(data.tipo1,valid)">
                                    <el-option
                                    v-for="item in horas"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 px-0 my-4">
                            <div class="row mx-0">
                                <div class="col-xl col-md-12">
                                    <div class="row mx-0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general">
                                            Tiempo mínimo de programación para pedir al cedis
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <ValidationProvider v-slot="{errors, valid}" rules="required|between:1,255" name="promagración cedis">
                                                <el-input v-model="data.tipo1.programacion" class="w-100 append-edit" @change="update_franja(data.tipo1,valid)">
                                                    <template slot="append">
                                                        <i>Horas</i>
                                                    </template>
                                                </el-input>
                                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl col-md-12">
                                    <div class="row mx-0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general">
                                            Tiempo máximo de programación de pedidos
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <ValidationProvider v-slot="{errors, valid}" rules="required|between:1,255" name="tiempo maximo">
                                                <el-input v-model="data.tipo1.tiempo_maximo" class="w-100 append-edit" @change="update_franja(data.tipo1,valid)">
                                                    <template slot="append">
                                                        <i>Días</i>
                                                    </template>
                                                </el-input>
                                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col-xl-3 col-md d-flex align-items-end">
                            <p class="mb-2 text-general">
                                {{$config.vendedor}} del Cedis ({{ data.tiendas_count }})
                            </p>
                        </div>
                        <div class="col-xl col-md-12 my-md-2">
                            <ValidationProvider v-slot="{errors, valid}" rules="required|between:1,24" name="franja">
                                <label class="pl-3 text-general f-15"> Franja Horaria </label>
                                <el-input v-model="data.tipo2.tiempo" class="w-100 append-edit" @change="update_franja(data.tipo2,valid)">
                                    <template slot="append">
                                        <i>Horas</i>
                                    </template>
                                </el-input>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-xl col-md-12 my-md-2">
                            <ValidationProvider v-slot="{errors, valid}" rules="required" name="hora inicio">
                                <label class="pl-3 text-general f-15"> Hora de Inicio </label>
                                <el-select v-model="data.tipo2.inicio" class="w-100" @change="update_franja(data.tipo2,valid)">
                                    <el-option
                                    v-for="item in horas"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-xl col-md-12 my-md-2">
                            <ValidationProvider v-slot="{errors, valid}" rules="required" name="hora fin">
                                <label class="pl-3 text-general f-15"> Hora Fin </label>
                                <el-select v-model="data.tipo2.fin" class="w-100" @change="update_franja(data.tipo2,valid)">
                                    <el-option
                                    v-for="item in horas"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                    />
                                </el-select>
                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 px-0 my-4">
                            <div class="row mx-0">
                                <div class="col-xl col-md-12">
                                    <div class="row mx-0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general">
                                            Tiempo mínimo de programación para pedir al cedis
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <ValidationProvider v-slot="{errors, valid}" rules="required|between:1,255" name="promagración cedis">
                                                <el-input v-model="data.tipo2.programacion" class="w-100 append-edit" @change="update_franja(data.tipo2,valid)">
                                                    <template slot="append">
                                                        <i>Horas</i>
                                                    </template>
                                                </el-input>
                                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl col-md-12">
                                    <div class="row mx-0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 px-4 f-14 text-general">
                                            Tiempo máximo de programación de pedidos
                                        </div>
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                            <ValidationProvider v-slot="{errors, valid}" rules="required|between:1,255" name="tiempo maximo">
                                                <el-input v-model="data.tipo2.tiempo_maximo" class="w-100 append-edit" @change="update_franja(data.tipo2,valid)">
                                                    <template slot="append">
                                                        <i>Días</i>
                                                    </template>
                                                </el-input>
                                                <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Franjas from "../../../services/configurar/admin/franjas";
export default {
    data(){
        return{
            cedis:[]
        }
    },
    computed: {
        horas(){
            let horas = []
            for (let index = 0; index < 24; index++){
                horas.push({
                    value:index,
                    label: index > 9 ? `${index}:00` :  `0${index}:00`,
                })
            }
            return horas
        }
    },
    mounted(){
        this.get_franjas()
    },
    methods:{
        async get_franjas(){
            try {
                const {data} = await Franjas.get_franjas()
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        async update_franja(datos,valid){
            try {
                if (valid){
                    const {data} = await Franjas.update_franja(datos)
                    this.notificacion('', data.mensaje, 'success')
                    datos = data.data
                }
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
