<template>
    <section>
        <div class="row mx-0 border-bottom py-3 px-3 text-general f-600">
            <div class="col-auto">
                Valor mínimo del pedido
            </div>
        </div>
        <div class="row mx-0 my-3 px-3">
            <div class="col-12 text-general f-14">
                Indique el valor mínimo del pedido para los {{$config.vendedor}} de cada uno de los cedis
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 213px)">
            <div class="row mx-0 px-3">
                <div class="col-6 d-flex py-2">
                    <div class="col-auto text-general f-18 f-600">
                        CEDIS
                    </div>
                    <div class="col-auto f-18 ml-auto text-general f-600">
                        Valor
                    </div>
                </div>
            </div>
            <div v-for="(cedis, id) in listado" :key="id" class="row mx-0 my-3 px-3">
                <div class="col-6 br-12 d-flex bg-light-f5 border br-5 py-2">
                    <div class="col-auto text-general f-15">
                        {{ cedis.nombre }}
                    </div>
                    <i v-show="!cedis.estado" class="icon-eye-off" />
                    <div class="col-auto f-15 ml-auto text-general2">
                        <span>{{ convertMoney(cedis.valor,cedis.idm_moneda) }}</span>                    
                    </div>
                </div>
                <div class="col-auto d-middle-center cr-pointer" @click="cambiarValorMinimo(cedis)">
                    <i class="icon-pencil-outline f-25 text-general" />
                </div>
                <div class="col-auto d-middle-center cr-pointer px-0" @click="historialCambioPrecio(cedis.id,cedis.nombre,cedis.idm_moneda)">
                    <i class="icon-historial f-20 text-general" />
                </div>
            </div>
        </div>
        <!-- Partials -->
        <ValidationObserver ref="validacion" v-slot="{ valid }">
            <modal ref="modalValorMinimo" icon="aprobar" center-icon titulo="Cambiar valor mínimo pedido" cerrar no-cancelar no-aceptar adicional="Guardar" @adicional="GuardarValorMinimo(valid)">
                <div class="row mx-0 justify-center">
                    <div class="col-10 my-2 text-general f-600 f-17">
                        CEDIS: {{ cedis.nombre }}
                    </div>
                    <ValidationProvider v-slot="{errors}" rules="required|numeric" name="valor" tag="div" class="col-10 mt-2">
                        <p class="pl-3 text-general w-100 f-14">Valor</p>
                        <money v-model="valor" class="input-money" v-bind="money" />
                        <span class="text-danger  w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <div class="col-10 my-1">
                        <div class="row mx-0">
                            <div class="col-auto mt-1">
                                <el-checkbox v-model="aplicaCliente" class="check-dark" />
                            </div>
                            <div class="col text-general f-15 pl-0 mt-1">
                                Aplicar también como pedido mínimo de todos los {{$config.vendedor}} del Cedis para sus Clientes
                            </div>
                        </div>
                    </div>
                </div>            
            </modal>
        </ValidationObserver>
        <modal-historial-precio ref="modalHistorialPrecio" />
    </section>
</template>

<script>
import {Money} from 'v-money'
import ValorMinimo from '~/services/configurar/admin/valorMinimo'
export default{
    components:{
        modalHistorialPrecio: () => import('./partials/modalHistorialPrecio'),
        Money
    },
    data(){
        return {
            money: {
                decimal: ',',
                thousands: '.',
                /* prefix: '$', */
                precision: 2,
                maxlength: 11,
                masked: false   
            },
            /* { decimal: ',', thousands: '.', suffix: '', precision: 2, maxlength: 6, masked: false } */
            valor: '',
            aplicaCliente: false,
            listado:[],
            cedis:{},
        }
    },
    mounted(){
        this.ListarCedis()
    },
    methods: {
        cambiarValorMinimo(cedis){
            this.cedis = cedis  
            this.valor = cedis.valor 
            if(cedis.herencia == 1){
                cedis.herencia = true
            }
            this.aplicaCliente = cedis.herencia 
            this.$refs.modalValorMinimo.toggle();
        },
        historialCambioPrecio(id_cedis,nombre,idm_moneda){
            this.$refs.modalHistorialPrecio.HistorialValores(id_cedis,nombre,idm_moneda)
            this.$refs.modalHistorialPrecio.toggle()
            
        },
        async ListarCedis(){
            try {
                const {data} = await ValorMinimo.GetCedis()
                this.listado = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async GuardarValorMinimo(valid){
            try {
                if(valid){
                    let datos = {
                        id_cedis : this.cedis.id,
                        herencia: this.aplicaCliente,
                        valor : this.valor
                    }
                    const {data} = await ValorMinimo.PostValorMinimo(datos)
                    this.LimpiarDatos()
                    this.ListarCedis()
                    this.$refs.modalValorMinimo.toggle()
                }
                
            } catch (e){
                this.error_catch(e)
            }
        },
        LimpiarDatos(){
            this.valor= 0
            this.aplicaCliente= false
            this.listado=[]
            this.cedis={}
        }
    }
}
</script>