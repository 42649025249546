<template>
    <section class="h-100">
        <div class="row mx-0 px-3 pl-5 f-600 border-bottom py-2 text-general">
            Criterios de calificación de pedidos
        </div>
        <div class="p-3 f-15 h-90">
            <div class="row mx-0 text-general">
                Crea los criterios con los cuales los clientes pueden calificar los pedidos
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-3">
                    <div class="col-3 px-2">
                        <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:20" name="criterio">
                            <p class="ml-2 text-general">Criterio</p>
                            <el-input v-model="model.nombre" maxlength="20" show-word-limit>
                                <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                            </el-input>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto px-2">
                        <ValidationProvider v-slot="{errors, failedRules}" rules="required|numeric|min_value:1|max_value:10" name="peso">
                            <p class="ml-2 text-general">Peso</p>
                            <el-input v-model="model.peso" style="width:100px;" />
                            <p v-if="failedRules.min_value" class="text-danger w-100 f-11">
                                El campo peso debe ser un valor entre 1 y 10
                            </p>
                            <p v-else-if="failedRules.max_value" class="text-danger w-100 f-11">
                                El campo peso debe ser un valor entre 1 y 10
                            </p>
                            <p v-else class="text-danger w-100 f-11" style="width:100px;"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto mt-3 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear criterio" placement="bottom">
                            <div class="bg-general cr-pointer d-middle-center br-10" style="width:40px;height:40px;">
                                <i class="icon-plus f-20 text-white" @click="CrearRegistro(valid)" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <div class="row mx-0 my-2">
                Lista de criterios
            </div>
            <div v-if="criterios.length" class="overflow-auto custom-scroll" style="height:calc(100% - 135px);">
                <ValidationObserver ref="validacion_pedidos" v-slot="{ valid }">
                    <draggable :list="criterios" handle=".handle" @change="actualizar_posicion">
                        <div v-for="(data, idx) in criterios" :key="idx" class="row mx-0 my-1 align-items-center">
                            <i class="icon-drag f-22 cr-pointer handle text-muted" />
                            <div class="col-3 px-2" :class="data.estado_set?'bg-disabled':'bg-inactive'">
                                <ValidationProvider v-slot="{errors}" :vid="'vp'+idx" rules="required" name="tipo">
                                    <el-input v-model="data.nombre" :disabled="editar!=idx" :suffix-icon="false?'icon-attention-alt text-danger':''" />
                                    <span v-show="false" class="text-danger w-100 f-11"> Ya existe una calificación con este nombre </span>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-2" :class="data.estado_set?'bg-disabled':'bg-inactive'">
                                <ValidationProvider v-slot="{errors, failedRules}" :vid="'vp2'+idx" rules="required|numeric|min_value:1|max_value:10" name="peso">
                                    <el-input v-model="data.peso" :disabled="editar!=idx" style="width:75px;" />
                                    <p v-if="failedRules.min_value" class="text-danger w-100 f-11">
                                        El campo peso debe ser un valor entre 1 y 10
                                    </p>
                                    <p v-else-if="failedRules.max_value" class="text-danger w-100 f-11">
                                        El campo peso debe ser un valor entre 1 y 10
                                    </p>
                                    <p v-else class="text-danger w-100 f-11" style="width:75px;"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-2">
                                <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                    <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;">
                                        <i class="icon-pencil-outline f-18" @click="editar=idx" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                    <div class="bg-light-f5 text-general2 shadow border cr-pointer d-middle-center br-10" style="width:40px;height:40px;" @click="editarRegistro(valid,data)">
                                        <i class="icon-ok-circled-outline f-20" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto px-0">
                                <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                    <div class="d-middle-center cr-pointer br-10" style="width:40px;height:40px;" @click="eliminar(data.id)">
                                        <i class="icon-trash-empty f-20 hover-inverse" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto px-2">
                                <el-switch
                                v-model="data.estado_set"
                                class="my-auto switch-red"
                                active-color="red"
                                inactive-color="#DBDBDB"
                                @change="CambiodeEstado(data)"
                                />
                            </div>
                            <div class="col-auto px-2">
                                <el-tooltip content="Tipo Delivery" placement="bottom" effect="light">
                                    <div class="custom-control custom-checkbox" @change="cambioDelivery(data)">
                                        <input :id="data.id" v-model="data.delivery" type="checkbox" class="custom-control-input" />
                                        <label class="custom-control-label" :for="data.id" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto">
                                <el-tooltip class="item" effect="light" content="Pedidos que ha usado este criterio para calificar" placement="bottom">
                                    <div class="br-20 bg-general3 d-middle text-white justify-content-between" style="min-width:57px;">
                                        <i class="icon-receipt mx-1  f-18" />
                                        <span class="pr-2">{{ data.calificaciones_pedido_count }}</span>
                                    </div>
                                </el-tooltip>
                            </div>
                            <template v-if="data.resultado">
                                <span class="mx-2">{{ data.resultado }}</span>
                                <i class="icon-star text-warning" />
                            </template>
                        </div>
                    </draggable>
                </ValidationObserver>
            </div>
            <sinDatos v-else icon="list" mensaje="No se ha creado ningún criterio" />
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar criterio" mensaje="¿Desea eliminar el criterio de clasificación?" @eliminar="EliminarRegistro" />
    </section>
</template>

<script>
let colorDegRed = getComputedStyle(document.documentElement).getPropertyValue('--gr-red')
import calificacionesTipos from '~/services/configurar/admin/calificacionesTipos'
export default {
    data(){
        return {
            editar: -1,
            model:{
                nombre:null,
                peso:1
            },
            id_eliminar:null,
            criterios:[],
        }
    },
    mounted(){
        this.listarmotivo()
    },
    methods: {
        eliminar(id){
            if(id){
                this.id_eliminar = id
            }
            this.$refs.modalEliminar.toggle()
        },
        async listarmotivo(){
            try {
                const {data} = await calificacionesTipos.GetTipos()
                data.forEach(element => {
                    element.estado_set = element.estado ?  true : false
                });
                this.criterios = data
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearRegistro(valid){
            try {
                if(valid){
                    const {data} = await calificacionesTipos.PostCrear(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.limpiar()
                    this.listarmotivo()
                    this.$refs.validacion.reset();
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarRegistro(valid,row){
            try {
                if(valid){
                    let datos={
                        id:row.id,
                        nombre:row.nombre,
                        peso:row.peso,
                    }
                    const {data} = await calificacionesTipos.PutEditar(datos)
                    this.editar=-1
                    this.notificacion('', data.mensaje, 'success')
                    this.listarmotivo()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async EliminarRegistro(){
            try {
                const {data} = await calificacionesTipos.Delete(this.id_eliminar)
                this.notificacion('', data.mensaje, 'success')
                this.listarmotivo()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async CambiodeEstado(row){
            try {
                let datos={
                    id:row.id,
                    estado:row.estado
                }
                const {data} = await calificacionesTipos.PutEstado(datos)
                this.notificacion('', data.mensaje, 'success')
                this.listarmotivo()
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioDelivery(row){
            try {
                let datos={
                    id:row.id,
                    delivery:row.delivery
                }
                const {data} = await calificacionesTipos.PutCalificacionDelivery(datos)
                this.notificacion('', data.mensaje, 'success')
                //this.listarmotivo()
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizar_posicion(event){
            const datos = {
                antiguaPosicion: event.moved.oldIndex + 1,
                nuevaPosicion: event.moved.newIndex + 1,
                id: event.moved.element.id,
            }
            const { data } = await calificacionesTipos.PostActualizarPosicion(datos)
            this.notificacion('', data.mensaje, 'success')
            this.listarmotivo()
        },
        limpiar(){
            this.model.nombre = null
            this.model.peso = 0
        }
    }

}
</script>

<style lang="css" scoped>
</style>
