<template>
    <section>
        <listado
        ref="listadoImg"
        :tipo="1"
        @agregar="anadirImagen"
        />
        <!-- Partials -->
        <!-- <modal-prev ref="modalPrev" /> -->
        <modal-anadir-imagen ref="modalAnadirImagen" @update="updateView" />
        <modal-duplicar-imagen ref="modalDuplicarImagen" />
    </section>
</template>

<script>
import Publicidad from "~/services/publicidad/publicidad_admin"
export default {
    components: {
        // modalPrev: () => import('./partials/modalPrevisualizacion'),
        modalAnadirImagen: () => import('./partials/modalAnadirImagen'),
        modalDuplicarImagen: () => import('./partials/modalDuplicarImagen'),
        listado: () => import('./componentes/listado')
    },
    data(){
        return {

        }
    },
    mounted(){
        //this.listarInfo()
    },
    methods: {

        previsualizar(){
            this.$refs.modalPrev.toggle();
        },
        anadirImagen(id_cedis){
            this.$refs.modalAnadirImagen.addImgCliente(id_cedis);
        },
        duplicarImagen(){
            this.$refs.modalDuplicarImagen.toggle();
        },
        updateView(){
            this.$refs.listadoImg.updateView()
        }
    }
}
</script>
