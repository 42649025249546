<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0 border-bottom pl-5 py-2 f-600 text-general">
            Forma de pago
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 139px)">
            <p class="f-15 text-general px-3 py-3">
                Crea las diferentes formas de pago que se efecturán a traves de trasnferencia o pagos en linea.
            </p>
            <div class="row mx-0">
                <div class="column-cedis border-right">
                    <div class="d-flex border-bottom justify-content-center align-items-end" style="height: 239px;">
                        <div class="btn-general f-14 px-3 mb-2" @click="irCrearFormaPago">
                            Crear forma de pago
                        </div>
                    </div>
                    <div class="row mx-0 bg-fondo align-items-center justify-center" style="height:45px;">
                        <div class="col-auto px-0 f-16 f-500 text-general">
                            Centros de distribución
                        </div>
                    </div>
                    <div v-for="(data,index) in cedis" :key="index" class="row mx-0 border-bottom align-items-center justify-center" :class="data.estado == 0 ? 'background-disabled':''" style="height:45px;">
                        <el-checkbox v-model="data.checked" class="check-green" :disabled="data.estado == 0" @change="seleccionarTodos($event, data.id)" />
                        <div class="col-8 tres-puntos text-general f-500">
                            {{ data.nombre }}
                        </div>
                    </div>
                </div>
                <div class="col px-0 custom-scroll overflow-auto px-0 border-bottom" style="height:calc(250vh - 180px)">
                    <div style="display:inline-flex;">
                        <div v-for="(data, index) in formaPagos" :key="index" class="border-right border-bottom text-center border-left" style="width:50px;">
                            <div class="w-100 border-bottom" style="height: 238px;">
                                <div class="row mx-0 py-3 justify-center">
                                    <el-tooltip content="Usos en los últimos 6 meses" placement="bottom" effect="light">
                                        <div v-if="data.id" class="mn-pill text-white bg-general3">
                                            {{ data.pedidos }}
                                        </div>
                                        <div v-else class="mn-pill" />
                                    </el-tooltip>
                                </div>
                                <div class="box-text">
                                    <p class="tres-puntos text-left">
                                        {{ data.nombre }}
                                    </p>
                                </div>
                            </div>
                            <div class="w-100 bg-fondo d-middle-center" style="height:45px;">
                                <el-dropdown v-if="data.id != null">
                                    <span class="el-dropdown-link">
                                        <i class="icon-dots-vertical f-20 text-general cr-pointer" />
                                    </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item>
                                            <div @click="irEditarFormaPago(data.id, data.nombre)">
                                                Editar nombre
                                            </div>
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="data.pedidos < 1">
                                            <div @click="confirmarEliminar(data.id, data.pedidos)">
                                                Eliminar
                                            </div>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </div>
                            <div v-for="(data1,index1) in totalCedis" :key="index1" class="w-100 d-middle-center" :class="cedisIsInactivo(index1) ? 'background-disabled' : ''" style="height:45px;">
                                <el-checkbox class="check-green" :checked="seleccionarFormaPagoCedis(index1, data.id, data.column)" :disabled="cedisIsInactivo(index1)" @change="changeFormaPago($event, data.id, index1, data.column)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalCrearFormaPago" :titulo="modoEditar ? 'Editar forma de pago' : 'Crear forma de pago'" no-aceptar adicional="Guardar" @adicional="control">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 py-3 justify-center">
                    <div class="col-10">
                        <p class="text-general f-13 ml-2">Nombre de la forma de pago</p>
                        <ValidationProvider v-slot="{errors}" rules="required|max:40|reservada" name="Nombre">
                            <el-input v-model="nombre" placeholder="Nombre" size="small" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <modal ref="modalEliminarFormaPago" titulo="Eliminar forma de pago" no-aceptar adicional="Eliminar" @adicional="eliminarFormaPago">
            <div class="row mx-0 py-3 justify-center">
                <div class="col-10 text-center">
                    <p class="text-general ml-2">¿Desea eliminar esta forma de pago?</p>
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import Service from '~/services/configurar/admin/formaPago'
import { extend } from 'vee-validate'

extend('reservada', {
    validate(value){
        let result = false;
        if(_.toLower(value) == 'efectivo' || _.toLower(value) == 'crédito' || _.toLower(value) == 'credito'){
            result = true;
        }
        return !result;
    },
    message: 'No es posible crear una forma de pago con el nombre especificado.'
});

export default {
    data(){
        return {
            cargando: false,
            modoEditar: false,
            checked: false,
            formaPagos: [],
            cedis: [],
            totalCedis: 0,
            idFormaPago: null,
            nombre: null,
        }
    },
    mounted(){
        this.getFormaPagos();
    },
    methods: {
        async getFormaPagos(){
            try {
                const {data} = await Service.getFormaPagos();
                this.formaPagos = data.formaPagos;
                this.cedis = data.cedis;
                this.totalCedis = data.cedis.length;
            } catch(e){
                return this.error_catch(e);
            }
        },
        irCrearFormaPago(){
            this.$refs.validacion.reset();
            this.$refs.modalCrearFormaPago.toggle();
        },
        control(){
            if(this.modoEditar)return this.putFormaPago();
            return this.postFormaPago();
        },
        async postFormaPago(){
            try {
                this.cargando = true;
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const model = {
                    nombre: this.nombre
                };
                const {data} = await Service.postFormaPago(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.nombre = null;
                this.formaPagos = [];
                this.getFormaPagos();
                this.$refs.modalCrearFormaPago.toggle();
            } catch(e){
                return this.error_catch(e)
            } finally{
                setTimeout(() => {
                    this.cargando = false;
                },500);
            }
        },
        async putFormaPago(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const model = {
                    nombre: this.nombre
                };
                const {data} = await Service.putFormaPago(this.idFormaPago, model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.updateRegister();
                this.$refs.modalCrearFormaPago.toggle();
            } catch(e){
                return this.error_catch(e)
            }
        },
        async updateRegister(){
            let index = this.formaPagos.findIndex((item) => item.id === this.idFormaPago);
            this.formaPagos[index].nombre = this.nombre;
            this.idFormaPago = null;
            this.nombre = null;
            this.modoEditar = false;
        },
        seleccionarFormaPagoCedis(index, idFormaPago, column){
            let result = false;
            let data = this.cedis[index].forma_pagos.filter((item) => item.id_transferencia_tipo === idFormaPago);
            if(data.length > 0){
                return !result;
            }
            if(column){
                switch(column){
                case "pago_efectivo":
                    result = parseInt(this.cedis[index].pago_efectivo) == 1 ? !result : result;
                    break;
                case "pago_credito":
                    result = parseInt(this.cedis[index].pago_credito) == 1 ? !result : result;
                    break;
                }
            }
            return result;
        },
        async changeFormaPago(event, idFormaPago, index, column){
            let accion = 0;
            if(event){
                accion = 1; // Añade esta forma de pago al cedis
            } else {
                accion = 2; // quita esta forma de pago al cedis
            }
            if(accion == 0)return;
            try {
                const model = {
                    accion: accion,
                    idFormaPago: idFormaPago,
                    idCedis: this.cedis[index].id,
                    column: column,
                };
                const {data} = await Service.changeFormaPago(model)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
            } catch(error){
                return this.error_catch(error)
            }
        },
        irEditarFormaPago(id, nombre){
            this.idFormaPago = id;
            this.nombre = nombre;
            this.modoEditar = true;
            this.$refs.modalCrearFormaPago.toggle();
        },
        confirmarEliminar(id, pedidos){
            if(pedidos > 0)return;
            this.idFormaPago = id;
            this.$refs.modalEliminarFormaPago.toggle();
        },
        async eliminarFormaPago(){
            try {
                this.cargando = true;
                const {data} = await Service.deleteFormaPago(this.idFormaPago);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.idFormaPago = null;
                this.formaPagos = [];
                this.getFormaPagos();
                this.$refs.modalEliminarFormaPago.toggle();
            } catch(e){
                return this.error_catch(e)
            } finally{
                setTimeout(() => {
                    this.cargando = false;
                },500);
            }
        },
        cedisIsInactivo(index){
            if(this.cedis[index].estado == 0){
                return true;
            }
            return false;
        },
        async seleccionarTodos(event, idCedis){
            try {
                if(event){
                    this.postCedisFormasPagoAll(idCedis);
                }else{
                    this.deleteFormasPagoAll(idCedis);
                }
            } catch(e){
                this.error_catch(e)
            }
        },

        async deleteFormasPagoAll(idCedis){
            try {
                this.cargando = true;
                const {data} = await Service.deleteFormasPagoAll(idCedis);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.formaPagos = [];
                this.getFormaPagos();
            } catch(e){
                this.error_catch(e)
            } finally{
                setTimeout(() => {
                    this.cargando = false;
                },500);
            }
        },

        async postCedisFormasPagoAll(idCedis){
            try {
                this.cargando = true;
                const model = {
                    idCedis: idCedis,
                };
                const {data} = await Service.postCedisFormasPagoAll(model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.formaPagos = [];
                this.getFormaPagos();
            } catch(e){
                this.error_catch(e)
            } finally{
                setTimeout(() => {
                    this.cargando = false;
                },500);
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.box-text{
    width: 40px;
    height: 180px;
    p{
        writing-mode: vertical-lr;
        height: 150px;
        transform: rotate(180deg) translate(7px, -24px);

    }
    .space-icon{
        background: #D4D9DD;
        height: 100%;
        height: 45px;
    }
}

.column-cedis{
    width: 350px;
}

.background-disabled {
    background: #F6F9FB;
}
</style>