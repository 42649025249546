<template>
    <section>
        <div class="row mx-0 p-3 border-bottom">
            <div class="col-auto">
                <p class="f-18 text-general f-600">
                    Aplicar impuestos
                </p>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 164px)">
            <div class="row mx-0 my-4 py-2">
                <div class="col-auto" />
                <div class="col px-0 text-general f-15">
                    Indique qué CEDIS discriminarán los impuestos en el recibo del pedido y si este es aplicado qué etiqueta del impuesto tendrá por defecto.
                </div>
            </div>
            <div v-for="(data,d) in cedis" :key="d" class="row mx-0 mb-5 ml-3">
                <div class="col-3 pt-3 d-flex align-items-center text-general f-17 f-600">
                    {{ data.nombre }}
                    <i v-show="!data.estado" class="icon-eye-off ml-1" />
                </div>
                <div class="col-3">
                    <p class="text-general f-12 pl-4">Impuesto</p>
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-2 pr-0">
                            <el-select v-model="data.impuestos" size="small" class="w-100" @change="value => editarImpuestosInventario(data,value)">
                                <el-option label="Sin Impuestos" :value="0" />
                                <el-option label="Valores +IVA" :value="1" />
                                <el-option label="IVA incluido" :value="2" />
                                <el-option label="IVA incluido sin etiqueta" :value="3" />
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Cobertura from '~/services/configurar/admin/CoberturaDefecto'
export default {
    data(){
        return {
            cedis: []
        }
    },
    mounted(){
        this.listarCedis()
    },
    methods: {
        async listarCedis(){
            try {
                const { data } = await Cobertura.listarCedis()
                this.cedis = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async editarImpuestosInventario(value, valid){
            try {
                value.impuestos = valid
                let datos = {
                    id_cedis: value.id,
                    impuestos: value.impuestos,
                }
                const {data} = await Cobertura.editarImpuestosInventario(datos)
                this.notificacion('','Actualizado correctamente', 'success')
            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>