var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 text-general"},[_vm._v(" Tiempos de estados de los pedidos ")]),_c('div',{staticClass:"p-3 f-15 overflow-auto custom-scroll",staticStyle:{"height":"calc(100vh - 148px)"}},[_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo máximo que tiene un "+_vm._s(_vm.$config.vendedor)+" para aceptar o confirmar un Pedido, antes de que se cancele automáticamente")])]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"solicitado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,1,valid)}},model:{value:(_vm.tiempos.solicitado_cancelado),callback:function ($$v) {_vm.$set(_vm.tiempos, "solicitado_cancelado", $$v)},expression:"tiempos.solicitado_cancelado"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Minutos")])]),_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo máximo que tiene un "+_vm._s(_vm.$config.vendedor)+" para enviar un Pedido que ha sido previamente Confirmado, antes de que se cancele automáticamente")])]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"confirmado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,2,valid)}},model:{value:(_vm.tiempos.confirmado_cancelado),callback:function ($$v) {_vm.$set(_vm.tiempos, "confirmado_cancelado", $$v)},expression:"tiempos.confirmado_cancelado"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Minutos")])]),_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo máximo que tiene un "+_vm._s(_vm.$config.vendedor)+" para enviar un Pedido con cambios que ya ha sido confirmado por el "+_vm._s(_vm.$config.cliente)+", antes de que se cancele automáticamente")])]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"cambios tendero"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,3,valid)}},model:{value:(_vm.tiempos.cambios_cancelado_tendero),callback:function ($$v) {_vm.$set(_vm.tiempos, "cambios_cancelado_tendero", $$v)},expression:"tiempos.cambios_cancelado_tendero"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Minutos")])]),_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo máximo que tiene un "+_vm._s(_vm.$config.cliente)+" para confirmar los cambios hechos por el "+_vm._s(_vm.$config.vendedor)+" en un Pedido, antes de que se cancele automáticamente")])]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"cambios cliente"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,4,valid)}},model:{value:(_vm.tiempos.cambios_cancelado_cliente),callback:function ($$v) {_vm.$set(_vm.tiempos, "cambios_cancelado_cliente", $$v)},expression:"tiempos.cambios_cancelado_cliente"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Minutos")])]),_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_vm._m(0),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:5","name":"enviado finalizado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,5,valid)}},model:{value:(_vm.tiempos.enviado_finalizado),callback:function ($$v) {_vm.$set(_vm.tiempos, "enviado_finalizado", $$v)},expression:"tiempos.enviado_finalizado"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Horas")])]),_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo durante el cual un "+_vm._s(_vm.$config.cliente)+" tiene disponible hacer un Reclamo de un Pedido. Este tiempo inicia posterior a la confirmación de Envío")])]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:240","name":"enviado reclamo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,6,valid)}},model:{value:(_vm.tiempos.enviado_reclamo),callback:function ($$v) {_vm.$set(_vm.tiempos, "enviado_reclamo", $$v)},expression:"tiempos.enviado_reclamo"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Minutos")])]),_c('div',{staticClass:"row mx-0 my-2 py-1 align-items-center border-bottom"},[_c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo durante el cual un "+_vm._s(_vm.$config.cliente)+" tiene disponible solicitar Soporte a "+_vm._s(_vm.$config.proyecto)+" acerca de un Pedido. Este tiempo inicia posterior al cierre de un pedido")])]),_c('div',{staticClass:"col-2 px-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max_value:5","name":"reclamo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('el-input',{on:{"change":function($event){return _vm.CrearEditar($event,7,valid)}},model:{value:(_vm.tiempos.reclamo_soporte),callback:function ($$v) {_vm.$set(_vm.tiempos, "reclamo_soporte", $$v)},expression:"tiempos.reclamo_soporte"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"text-general"},[_vm._v("Horas")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-7 text-general"},[_c('p',[_vm._v("Tiempo durante el cual permanece activo el Chat del Pedido. Una vez transcurrido este tiempo, el chat se cerrará y el Pedido pasará a estado Finalizado")])])}]

export { render, staticRenderFns }