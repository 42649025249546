<template>
    <section>
        <div class="row mx-0 border-bottom py-2 px-3">
            <div class="col-auto text-general f-18 f-600">
                Selección de {{$config.vendedor}} por defecto
            </div>
        </div>
        <div class="row mx-0 my-4 px-3">
            <div class="col-auto text-general f-500 f-15">
                Seleccione los {{$config.vendedor}} disponibles para la selección automática del {{$config.cliente}}.
            </div>
        </div>
        <div class="row mx-0 px-3 align-items-center">
            <div class="col-5 mt-3">
                <div class="border bg-light-f5 text-general f-15 br-12 d-middle px-3 py-2">
                    Por defecto del todo el sistema
                </div>
            </div>
            <div class="col-auto px-1 mt-4">
                <el-tooltip v-if="!_.isEmpty(defecto)" placement="top" effect="light">
                    <div slot="content">
                        <div style="max-width:280px;">
                            <div class="row mx-0 position-relative">
                                <img :src="defecto.tienda.imagen" class="rounded-circle position-absolute obj-cover border bg-light-f5" width="80px" height="80px" style="top:-35px;left:98px" />
                                <div class="col-12 text-center f-17 text-general f-600 mt-5">
                                    {{ defecto.tienda.propietario_nombre }}
                                </div>
                                <div class="col-12 mt-2 f-500 text-general mt-3">
                                    <i class="icon-location text-general2 f-18" />
                                    {{ defecto.tienda.direccion }}
                                </div>
                                <div class="col-12 mt-2 f-500 text-general mt-3">
                                    <i class="icon-warehouse text-general2 f-18" />
                                    {{ defecto.tienda.cedis_nombre }}
                                </div>
                                <div class="col-12 mt-2 mb-2 f-500 text-general mt-3">
                                    <i class="icon-fecha-fin text-general2 f-18" />
                                    Activo desde: {{ formatearFecha(defecto.tienda.activo) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img :src="defecto.tienda.imagen" width="40" height="40" class="obj-cover rounded-circle" />
                </el-tooltip>
                <div v-else class="hover-leechero">
                    <i class="icon-leechero-cap f-30" />
                </div>
            </div>
            <div class="col-4 pt-3">
                <el-select v-model="defecto.id_tienda" class="w-100" filterable @change="changeDefault($event)">
                    <el-option
                    v-for="(item, i) in tiendas"
                    :key="i"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 px-3 mt-4 align-items-center">
            <div class="col-5">
                <p class="text-general f-15 f-500 pl-3">
                    Añadir país
                </p>
                <el-select v-model="pais" class="w-100" filterable="">
                    <el-option
                    v-for="(item, i) in paises"
                    :key="i"
                    :label="item.pais"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-1 mt-4">
                <el-tooltip v-if="!_.isEmpty(paisStore)" placement="top" effect="light">
                    <div slot="content">
                        <div style="max-width:280px;">
                            <div class="row mx-0 position-relative">
                                <img :src="paisStore.imagen" class="rounded-circle position-absolute obj-cover border bg-light-f5" width="80px" height="80px" style="top:-35px;left:98px" />
                                <div class="col-12 text-center f-17 text-general f-600 mt-5">
                                    {{ paisStore.propietario_nombre }}
                                </div>
                                <div class="col-12 mt-2 f-500 text-general mt-3">
                                    <i class="icon-location text-general2 f-18" />
                                    {{ paisStore.direccion }}
                                </div>
                                <div class="col-12 mt-2 f-500 text-general mt-3">
                                    <i class="icon-warehouse text-general2 f-18" />
                                    {{ paisStore.cedis_nombre }}
                                </div>
                                <div class="col-12 mt-2 mb-2 f-500 text-general mt-3">
                                    <i class="icon-fecha-fin text-general2 f-18" />
                                    Activo desde: {{ formatearFecha(paisStore.activo) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <img :src="paisStore.imagen" width="40" height="40" class="obj-cover rounded-circle" />
                </el-tooltip>
                <div v-else class="hover-leechero">
                    <i class="icon-leechero-cap f-30" />
                </div>
            </div>
            <div class="col-4 pt-3">
                <el-select v-model="paisTienda" class="w-100" filterable>
                    <el-option
                    v-for="(item, i) in tiendas"
                    :key="i"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-auto px-0 pt-3">
                <el-tooltip placement="bottom" content="Crear Motivo" effect="light">
                    <div class="bg-general p-2 br-12 cr-pointer" @click="addPais()">
                        <i class="icon-plus text-white" />
                    </div>
                </el-tooltip>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 372px);">
            <div v-for="(data, d) in datos" :key="d" class="row mx-0 px-3 my-3 align-items-center mb-5">
                <div class="col-lg-6 col-xl-5">
                    <div class="border bg-light-f5 text-general f-15 br-12 d-middle px-3 py-2">
                        {{ data.pais }} <i class="icon-plus text-general ml-auto f-18 cr-pointer" @click="anadirCiudad(data)" />
                    </div>
                </div>
                <div class="col-auto px-1">
                    <el-tooltip v-if="!_.isEmpty(data.tienda)" placement="top" effect="light">
                        <div slot="content">
                            <div style="max-width:280px;">
                                <div class="row mx-0 position-relative">
                                    <img :src="data.tienda.imagen" class="rounded-circle position-absolute obj-cover border bg-light-f5" width="80px" height="80px" style="top:-35px;left:98px" />
                                    <div class="col-12 text-center f-17 text-general f-600 mt-5">
                                        {{ data.tienda.propietario_nombre }}
                                    </div>
                                    <div class="col-12 mt-2 f-500 text-general mt-3">
                                        <i class="icon-location text-general2 f-18" />
                                        {{ data.tienda.direccion }}
                                    </div>
                                    <div class="col-12 mt-2 f-500 text-general mt-3">
                                        <i class="icon-warehouse text-general2 f-18" />
                                        {{ data.tienda.cedis_nombre }}
                                    </div>
                                    <div class="col-12 mt-2 mb-2 f-500 text-general mt-3">
                                        <i class="icon-fecha-fin text-general2 f-18" />
                                        Activo desde: {{ formatearFecha(data.tienda.activo) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img :src="data.tienda.imagen" width="40" height="40" class="obj-cover rounded-circle" />
                    </el-tooltip>
                    <div v-else class="hover-leechero">
                        <i class="icon-leechero-cap f-30" />
                    </div>
                </div>
                <div class="col-4">
                    <el-select v-model="data.tienda.id" placeholder="Select" class="w-100" filterable @change="onChangeTienda($event, data)">
                        <el-option
                        v-for="(item, i) in tiendas"
                        :key="i"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div v-if="!data.ciudades.length" class="col-auto px-0">
                    <el-tooltip placement="bottom" content="Eliminar" effect="light">
                        <i class="icon-trash-empty hover-inverse cr-pointer f-25" @click="eliminarPais(data.predefinida_id, 1)" />
                    </el-tooltip>
                </div>

                <div v-for="(ciudad, c) in data.ciudades" :key="c" class="col-12 my-2">
                    <div class="d-flex mx-0">
                        <div class="col-xl-5 col-lg-6">
                            <div class="border bg-light-f5 text-general f-15 br-12 d-middle px-3 py-2">
                                {{ ciudad.ciudad }}
                            </div>
                        </div>
                        <div class="col-auto px-1">
                            <el-tooltip v-if="!_.isEmpty(ciudad.tienda)" placement="top" effect="light">
                                <div slot="content">
                                    <div style="max-width:280px;">
                                        <div class="row mx-0 position-relative">
                                            <img :src="ciudad.tienda.imagen" class="rounded-circle position-absolute obj-cover border bg-light-f5" width="80px" height="80px" style="top:-35px;left:98px" />
                                            <div class="col-12 text-center f-17 text-general f-600 mt-5">
                                                {{ ciudad.tienda.propietario_nombre }}
                                            </div>
                                            <div class="col-12 mt-2 f-500 text-general mt-3">
                                                <i class="icon-location text-general2 f-18" />
                                                {{ ciudad.tienda.direccion }}
                                            </div>
                                            <div class="col-12 mt-2 f-500 text-general mt-3">
                                                <i class="icon-warehouse text-general2 f-18" />
                                                {{ ciudad.tienda.cedis_nombre }}
                                            </div>
                                            <div class="col-12 mt-2 mb-2 f-500 text-general mt-3">
                                                <i class="icon-fecha-fin text-general2 f-18" />
                                                Activo desde: {{ formatearFecha(ciudad.tienda.activo) }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img :src="ciudad.tienda.imagen" width="40" height="40" class="obj-cover rounded-circle" />
                            </el-tooltip>
                            <div v-else class="hover-leechero">
                                <i class="icon-leechero-cap f-30" />
                            </div>
                        </div>
                        <div class="col-4 pr-1">
                            <el-select v-model="ciudad.tienda.id" placeholder="Select" class="w-100" filterable @change="onChangeTienda($event, ciudad)">
                                <el-option
                                v-for="(item, i) in tiendas"
                                :key="i"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                        </div>
                        <div class="col-auto px-2">
                            <i class="icon-trash-empty hover-inverse cr-pointer f-25" @click="eliminarPais(ciudad.predefinida_id, 2)" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-eliminar
        ref="modalEliminarPais"
        eliminar
        :no-aceptar="false"
        :titulo="setTitle"
        mensaje="¿Desea eliminar el país o ciudad con vendedor por defecto?"
        @eliminar="eliminar()"
        />
        <modal-anadir-ciudad ref="modalAnadirCiudad" @saved="fetchDatos()" />
    </section>
</template>

<script>
import leecherosDefecto from '~/services/configurar/admin/leecherosDefecto'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    components: {
        modalAnadirCiudad: () => import('./partials/modalAnadirCiudad')
    },
    data(){
        return {
            paisTienda: '',
            paises: [],
            value: '',
            pais: '',
            delete: 1,
            idDelete: null,
            esPais: false
        }
    },
    computed: {
        ...mapGetters({
            tiendas: 'admin/leecherosDefecto/getTiendas',
            datos: 'admin/leecherosDefecto/getDatos',
            defecto: 'admin/leecherosDefecto/getDefecto',
        }),
        paisStore(){
            return this.tiendas.find(el => el.id == this.paisTienda) ?? {}
        },
        setTitle(){
            if(this.delete == 1){return 'Eliminar Pais'}
            else{ return 'Eliminar Ciudad' }
        },
    },
    async mounted(){
        await this.fetchDatos()
        await this.fetchTiendas()
        await this.buscarPaises()
    },
    methods: {
        ...mapActions({
            fetchDatos: 'admin/leecherosDefecto/fetchDatos',
            fetchTiendas: 'admin/leecherosDefecto/fetchTiendas',
        }),
        ...mapMutations({
            setIdPais: 'admin/leecherosDefecto/setIdPais',
        }),
        anadirCiudad({ id }){
            this.setIdPais(id)
            this.$refs.modalAnadirCiudad.toggle(id)
        },
        eliminarPais(idDelete, title){
            this.idDelete = idDelete
            this.delete = title;
            this.$refs.modalEliminarPais.toggle()
        },

        async buscarPaises(){
            try {
                const { data } = await leecherosDefecto.buscarPaises();
                this.paises = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChangeTienda(tienda, {predefinida_id: id}){
            try {
                const params = {
                    id,
                    tienda
                }
                const { data } = await leecherosDefecto.updateTienda(params)
                this.notificacion('', 'Tienda actualizada correctamente', 'success')
                await this.fetchDatos()
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminar(){
            try {
                const { data } = await leecherosDefecto.eliminar(this.idDelete);
                if (data.message == 'deleted'){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.idDelete = null
                    await this.fetchDatos()
                    if (this.esPais){
                        await this.buscarPaises()
                        this.esPais = !this.esPais
                    }
                    this.$refs.modalEliminarPais.toggle()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async addPais(){
            try {
                if (!this.paisTienda || !this.pais){
                    return this.notificacion('Error', 'El país y el vendedor son requeridos', 'warning')
                }
                const datos = {
                    id_tienda: this.paisTienda,
                    idm_pais: this.pais
                }
                const { data } = await leecherosDefecto.savePais({ datos })
                if (data.message == 'saved'){
                    await this.fetchDatos()
                    this.paisTienda = ''
                    this.paises = this.paises.filter(el => el.id != this.pais)
                    this.pais = ''
                    this.notificacion('', 'Guardado correctamente', 'success')
                }

            } catch (error){
                this.error_catch(error)
            }
        },
        async changeDefault(event){
            try {
                const datos = {
                    id_tienda: event
                }
                const { data } = await leecherosDefecto.changeDefault( datos );
                if (data.message == 'saved'){
                    await this.fetchDatos()
                    this.notificacion('', 'Guardado correctamente', 'success')
                }
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.hover-leechero{
    background-color: var(--color-general2);
    color: #ffffff;
    border-radius: 50%;
    transition: 0.8s;
    &:hover{
        background-color: var(--color-general);
        border-radius: 5px;
    }
}
</style>
