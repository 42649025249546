<template>
    <section class="h-100">
        <div class="row mx-0 px-3 pl-5 f-600 border-bottom text-general py-2">
            Distancia de los domicilios
        </div>
        <div class="p-3 f-15 h-90">
            <div class="row mx-0 text-general">
                Crea los diferentes rangos de distancia posibles, para que cada tendero defina los precios de sus domicilios de acuerdo a esa distancia.
            </div>
            <ValidationObserver ref="validacion_crear" v-slot="{ valid }">
                <div class="row mx-0 my-2">
                    <div class="col-auto px-2" style="width:150px;">
                        <ValidationProvider v-slot="{errors}" rules="required" name="distancia">
                            <p class="ml-2 text-general">Distancia (m)</p>
                            <input-miles v-model="model.distancia" :money-options="config_distancias" />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                    <div class="col-auto mt-3 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear rango" placement="bottom">
                            <div class="bg-general cr-pointer d-middle-center br-8" style="width:38px;height:38px;">
                                <i class="icon-plus f-20 text-white" @click="crearRegistro(valid)" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <div class="row mx-0 my-4 text-general">
                {{$config.vendedor}} con domicilios gratis: {{ total }}
            </div>
            <div class="row mx-0 my-2 text-general">
                Rangos de distancias
            </div>
            <div v-if="distancia.length" class="overflow-auto custom-scroll" style="height:calc(100% - 165px);">
                <ValidationObserver ref="validacion" v-slot="{ valid }">
                    <div v-for="(data, idx) in distancia" :key="idx" class="row mx-0 mb-3">
                        <div class="col-2 px-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="desde">
                                <input-miles v-model="data.distancia_inicial" style="height:30px;" :suffix-icon="false?'icon-attention-alt text-danger':''" :disabled="true" :money-options="config_distancias" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <span class="mt-2 text-general">A</span>
                        <div class="col-2 px-2">
                            <ValidationProvider v-slot="{errors}" rules="required" name="hasta">
                                <input-miles v-model="data.distancia" :disabled="editar!=idx" :money-options="config_distancias" style="height:30px;" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="col-auto px-1">
                            <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                <div class="btn-action border">
                                    <i class="icon-pencil-outline f-22 cr-pointer" @click="editar=idx" />
                                </div>
                            </el-tooltip>
                            <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                <div class="btn-action border" @click="EditarDistancia(valid,data)">
                                    <i class="icon-ok-circled-outline text-green f-20" />
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-auto px-0">
                            <el-tooltip class="item" effect="light" content="Eliminar" placement="bottom">
                                <div class="btn-action border" @click="eliminar(data.id)">
                                    <i class="icon-trash-empty f-18 cr-pointer hover-inverse" />
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="col-2 px-2">
                        <input-miles v-model="distancia_final" :disabled="true" :money-options="config_distancia_final" style="height:30px;" />
                    </div>
                </ValidationObserver>
            </div>
            <sinDatos v-else icon="regla" ext="png" mensaje="No se ha creado ningún rango de distancia" />
            <modalEliminar ref="modalEliminar" titulo="Eliminar distancia de domicilio" mensaje="¿Desea eliminar la distancia de domicilios?" @eliminar="EliminarRegistro" />
        </div>
    </section>
</template>

<script>
import DistanciaDomicilios from '~/services/configurar/admin/distancasDomicilios'
export default {
    data(){
        return {
            editar: -1,
            model:{
                distancia:null
            },
            config_distancias: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' m',
                precision: 0,
                masked: false
            },
            config_distancia_final: {
                decimal: ',',
                thousands: '.',
                prefix: '+ ',
                suffix: ' m',
                precision: 0,
                masked: false
            },
            distancia:[],
            total: 0,
            id_eliminar:null,
            distancia_final:0
        }
    },
    mounted(){
        this.listarDomicilios()
    },
    methods: {
        eliminar(id){
            if(id){
                this.id_eliminar = id
            }
            this.$refs.modalEliminar.toggle()
        },
        async listarDomicilios(){
            try {
                const {data} = await DistanciaDomicilios.GetDistancia()
                let indice = data.distancia.length - 1
                if (data.distancia.length){
                    this.distancia_final = data.distancia[indice].distancia
                }
                this.distancia = data.distancia
                this.total = data.total
            } catch (e){
                this.error_catch(e)
            }
        },
        async crearRegistro(valid){
            try {
                if(valid){
                    const {data} = await DistanciaDomicilios.PostCrear(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.limpiar()
                    this.listarDomicilios()
                    this.$refs.validacion_crear.reset();
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async EditarDistancia(valid,row){
            try {
                if(valid){
                    this.editar=-1
                    let datos={
                        id:row.id,
                        distancia:row.distancia
                    }
                    const {data} = await DistanciaDomicilios.PutEditar(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listarDomicilios()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async EliminarRegistro(){
            try {
                const {data} = await DistanciaDomicilios.Delete(this.id_eliminar)
                this.notificacion('', data.mensaje, 'success')
                this.listarDomicilios()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model.distancia = null
        }
    }
}
</script>
<style lang="css" scoped>
</style>
