import axios from "axios"

const API = "avatares";

const avatar = {
    get_grupos_avatares(){
        return axios(`${API}/get-grupos-avatares`);
    },
    get_avatares(id_padre){
        return axios(`${API}/${id_padre}/get-avatares`);
    },
    crear_grupo_avatar(params){
        return axios.post(`${API}/crear-grupo-avatar`, params);
    },
    crear_avatar(params){
        return axios.post(`${API}/crear-avatar`, params);
    },
    eliminar_avatar(id_eliminar){
        return axios.delete(`${API}/${id_eliminar}/eliminar-avatar`);
    },
    grupoAvatares: () => axios.get(`grupo-avatares/listar-avatares`),

};

export default avatar;
