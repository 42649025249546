import axios from 'axios'

const clasificacionstipos = {

    PostCrear: (datos) => axios.post(`calificaciones-tipos/crear-tipo`,datos),
    GetTipos: () => axios.get(`calificaciones-tipos/listar-tipos`),
    PutEditar: (datos) => axios.post(`calificaciones-tipos/editar-tipo`,datos),
    Delete: (id) => axios.delete(`calificaciones-tipos/${id}/eliminar-tipo`),
    PostActualizarPosicion: (datos) => axios.post('calificaciones-tipos/posicion-tipo', datos),
    PutEstado: (datos) => axios.post(`calificaciones-tipos/estado-tipo`,datos),
    PutCalificacionDelivery: (datos) => axios.put(`calificaciones-tipos/tipo-delivery`,datos),
}
export default clasificacionstipos
