<template>
    <section>
        <ValidationObserver ref="validacion">
            <div class="row mx-0 py-3 px-2 border-bottom">
                <div class="col-auto f-18 f-500 text-general">
                    Parámetros temporales
                </div>
            </div>
            <div class="row mx-0 py-3 px-2">
                <div class="col-auto f-15 text-general">
                    Indica el tiempo limites que tiene los clientes para la confirmación de cambios del pedido y solicitud de reclamo
                </div>
            </div>
            <div class="row mx-0 py-3 border-bottom">
                <div class="col-2" />
                <div class="col text-general f-15 f-500">
                    Minutos para auto confirmación
                    <el-tooltip content="Minutos límite que tiene el cliente para aceptar los cambios realizados en el listado de los productos del pedido. Antes que se confirmen automáticamente." placement="bottom" effect="light">
                        <i class="icon-info-circled-alt f-20" />
                    </el-tooltip>
                </div>
                <div class="col text-general f-15 f-500">
                    Días reclamo
                    <el-tooltip :content="`Días límite que tiene el cliente para solicitar un reclamo al ${$config.vendedor} una vez recibido el pedido.`" placement="bottom" effect="light">
                        <i class="icon-info-circled-alt f-20" />
                    </el-tooltip>
                </div>
            </div>
            <div class="row mx-0 py-2" />
            <div v-for="(data,d) in cedis" :key="d" class="row mx-0 mb-4">
                <div class="col-2 pt-3 d-flex align-items-center text-general f-15 f-600">
                    {{ data.nombre }}
                </div>
                <div class="col">
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-2 pr-0">
                            <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Minutos auto confirmación">
                                <el-input v-model="data.horas_autoconfirmacion" class="w-100" placeholder="Cantidad de minutos" @change="cambioValores(data.id)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="row mx-0 align-items-center">
                        <div class="col pl-2 pr-0">
                            <ValidationProvider v-slot="{errors}" rules="required|numeric" name="Días reclamo">
                                <el-input v-model="data.dias_reclamo" class="w-100" placeholder="Número de días" @change="cambioValores(data.id)" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import Service from '~/services/configurar/admin/cedisParametrosTemporales'

export default {
    data(){
        return {
            cedis: []
        }
    },
    mounted(){
        this.getCedis();
    },
    methods:{
        async getCedis(){
            try {
                const {data} = await Service.getCedis()
                this.cedis = data.cedis;
            } catch(error){
                this.error_catch(error)
            }
        },

        async cambioValores(idCedis){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                let model = this.cedis.find((element) => element.id === idCedis);
                if(!model.horas_autoconfirmacion || !model.dias_reclamo)return;
                const {data} = await Service.putHorasDiasCedis(model)
                this.notificacion("Mensaje", data.mensaje, data.tipo);
            } catch(error){
                this.error_catch(error)
            }
        }
    },
}
</script>
