<template>
    <section>
        <div class="row mx-0 border-bottom px-3 py-3">
            <div class="col-auto f-600 text-general">
                Seleccion de Cedis por Defecto
            </div>
        </div>
        <div class="custom-scroll overflow-auto px-3" style="height:calc(100vh - 154px)">
            <div class="row mx-0">
                <div class="col-12 mt-3 text-general f-15">
                    Seleccione el cedis por defecto para cada país, con el fin de asignarle automáticamente al {{$config.vendedor}} en el momento del registro.
                </div>
            </div>
            <div class="row mx-0 mt-4 mb-3">
                <div class="col-4 pt-3">
                    <div class="border bg-light-f5 text-general f-15 br-10 py-1 mt-1 text-center">
                        Por defecto de todo el sistema
                    </div>
                </div>
                <div class="col-5">
                    <p class="text-general f-14 pl-3">
                        Cedis
                    </p>
                    <el-select v-model="defecto.id_cedis" size="small" class="w-100" filterable @change="changeDefault($event)">
                        <el-option
                        v-for="(item, i) in cedis"
                        :key="i"
                        :value="item.id"
                        :label="item.nombre"
                        >
                            <span> {{ item.nombre }} </span>
                            <i v-show="!item.estado" class="icon-eye-off" />
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-4">
                    <p class="text-general f-14 pl-3">
                        Seleccionar país
                    </p>
                    <el-select v-model="pais" size="small" class="w-100" filterable="">
                        <el-option
                        v-for="(item, i) in paises"
                        :key="i"
                        :label="item.pais"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-5">
                    <p class="text-general f-14 pl-3">
                        Seleccionar Cedis
                    </p>
                    <el-select v-model="paisCedis" size="small" class="w-100" filterable>
                        <el-option
                        v-for="(item, i) in cedis"
                        :key="i"
                        :value="item.id"
                        :label="item.nombre"
                        >
                            <span> {{ item.nombre }} </span>
                            <i v-show="!item.estado" class="icon-eye-off" />
                        </el-option>
                    </el-select>
                </div>
                <div class="col-auto pt-3">
                    <el-tooltip placement="bottom" content="Añadir Pais" effect="light">
                        <div class="btn-red mt-1">
                            <i class="icon-plus f-14 cr-pointer" @click="addPais()" />
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div v-for="(data, i) in datos" :key="i" class="row mx-0 mt-3 mb-4">
                <div class="col-4">
                    <div class="bg-light-f5 border text-general px-2 d-middle br-10 py-1" style="height:32px;">
                        <span class="tres-puntos">
                            {{ data.pais }}
                        </span>
                        <i class="icon-plus f-14 ml-auto cr-pointer" @click="anadirCiudad(data)" />
                    </div>
                </div>
                <div class="col-5">
                    <el-select v-model="data.cedi.id" size="small" class="w-100" filterable @change="onChangeCedi($event, data)">
                        <el-option
                        v-for="(item, k) in cedis"
                        :key="k"
                        :value="item.id"
                        :label="item.nombre"
                        >
                            <span> {{ item.nombre }} </span>
                            <i v-show="!item.estado" class="icon-eye-off" />
                        </el-option>
                    </el-select>
                </div>
                <div v-if="!data.ciudades.length" class="col-auto d-middle-center">
                    <div class="btn-action border" @click="eliminarPais(data.predefinida_id, 1)">
                        <i class="icon-trash-empty f-20 hover-inverse cr-pointer" />
                    </div>
                </div>
                <div v-for="(ciudad, c) in data.ciudades" :key="c" class="col-12 mt-2">
                    <div class="row mx-0">
                        <div class="col-4 pr-4">
                            <div class="bg-light-f5 border f-14 text-general px-2 d-middle br-10 py-1" style="height:32px;">
                                <span class="tres-puntos">
                                    {{ ciudad.ciudad }}
                                </span>
                            </div>
                        </div>
                        <div class="col-5 px-2">
                            <el-select v-model="ciudad.cedi.id" size="small" class="w-100" filterable @change="onChangeCedi($event, ciudad)">
                                <el-option
                                v-for="(item, k) in cedis"
                                :key="k"
                                :value="item.id"
                                :label="item.nombre"
                                >
                                    <span> {{ item.nombre }} </span>
                                    <i v-show="!item.estado" class="icon-eye-off" />
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-auto d-middle-center px-4">
                            <div class="btn-action border" @click="eliminarPais(ciudad.predefinida_id, 2)">
                                <i class="icon-trash-empty f-20 hover-inverse cr-pointer mx-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar
        ref="modalEliminarPais"
        eliminar
        :no-aceptar="false"
        :titulo="setTitle"
        mensaje="¿Desea eliminar el país o ciudad con cedis por defecto?"
        @eliminar="eliminar()"
        />
        <modal-eliminar ref="modalElminarNovedad" titulo="Eliminar Motivo de novedad" mensaje="¿Desea Eliminar el motivo de novedad?" />
        <modal-anadir-cedis-ciudad ref="modalAnadirCedisCiudad" @saved="fetchDatos()" />
    </section>
</template>

<script>
import cedisDefecto from '~/services/configurar/admin/cedisDefecto'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ModalAnadirCedisCiudad from './partials/modalAnadirCedisCiudad.vue'

export default {
    components: {
        ModalAnadirCedisCiudad
    },
    data(){
        return {
            motivo: '',
            listaMotivos: '',
            value1: false,
            lista_motivos: [
                {
                    value1: false,
                    text: 'Hola como estamos 1'
                },{
                    value1: false,
                    text: 'Hola como estamos 2'
                },{
                    value1: false,
                    text: 'Hola como estamos 3'
                },
            ],
            options: [{
                value: 'Option1',
                label: 'Option1'
            }, {
                value: 'Option2',
                label: 'Option2'
            }, {
                value: 'Option3',
                label: 'Option3'
            }, {
                value: 'Option4',
                label: 'Option4'
            }, {
                value: 'Option5',
                label: 'Option5'
            }],
            value: '',
            paises: [],
            idDelete: null,
            delete: 1,
            pais: '',
            paisCedis: '',
            esPais: false,

        }
    },
    computed: {
        ...mapGetters({
            cedis: 'admin/cedisDefecto/getCedis',
            datos: 'admin/cedisDefecto/getDatos',
            defecto: 'admin/cedisDefecto/getDefecto',
        }),
        setTitle(){
            if(this.delete == 1){return 'Eliminar Pais'}
            else{ return 'Eliminar Ciudad' }
        },
    },
    async mounted(){
        await this.fetchDatos()
        await this.fetchCedis()
        await this.buscarPaises()
    },
    methods: {
        ...mapActions({
            fetchDatos: 'admin/cedisDefecto/fetchDatos',
            fetchCedis: 'admin/cedisDefecto/fetchCedis',
        }),
        ...mapMutations({
            setIdPais: 'admin/cedisDefecto/setIdPais',
        }),
        anadirCiudad({ id }){
            this.setIdPais(id)
            this.$refs.modalAnadirCedisCiudad.toggle(id)
        },
        eliminarPais(idDelete, title){
            this.idDelete = idDelete
            this.delete = title;
            this.$refs.modalEliminarPais.toggle()
        },

        async buscarPaises(){
            try {
                const { data } = await cedisDefecto.buscarPaises();
                this.paises = data
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChangeCedi(cedi, {predefinida_id: id}){
            try {
                const params = {
                    id,
                    cedi
                }
                const { data } = await cedisDefecto.updateCedi(params)
                this.notificacion('', 'Cedi actualizado correctamente', 'success')
                await this.fetchDatos()
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminar(){
            try {
                const { data } = await cedisDefecto.eliminar(this.idDelete);
                if (data.message == 'deleted'){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.idDelete = null
                    await this.fetchDatos()
                    if (this.esPais){
                        await this.buscarPaises()
                        this.esPais = !this.esPais
                    }
                    this.$refs.modalEliminarPais.toggle()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async addPais(){
            try {
                if (!this.paisCedis || !this.pais){
                    return this.notificacion('Error', 'El país y el cedis son requeridos', 'warning')
                }
                const datos = {
                    id_cedis: this.paisCedis,
                    idm_pais: this.pais
                }
                const { data } = await cedisDefecto.savePais({ datos })
                if (data.message == 'saved'){
                    await this.fetchDatos()
                    this.paisCedis = ''
                    this.paises = this.paises.filter(el => el.id != this.pais)
                    this.pais = ''
                    this.notificacion('', 'Guardado correctamente', 'success')
                }

            } catch (error){
                this.error_catch(error)
            }
        },
        async changeDefault(event){
            try {
                const datos = {
                    id_cedis: event
                }
                const { data } = await cedisDefecto.changeDefault( datos );
                if (data.message == 'saved'){
                    await this.fetchDatos()
                    this.notificacion('', 'Guardado correctamente', 'success')
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        eliminarMotivo(){
            this.$refs.modalElminarNovedad.toggle();
        }
    }
}
</script>

<style lang="scss" scoped>
.h:hover{
    background-color: var(--color-general2);
    color: #ffffff !important;
}
</style>