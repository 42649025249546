import { render, staticRenderFns } from "./motEliminacion.vue?vue&type=template&id=341e611a&scoped=true&"
import script from "./motEliminacion.vue?vue&type=script&lang=js&"
export * from "./motEliminacion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "341e611a",
  null
  
)

export default component.exports