import axios from 'axios'

const distanciadomicilio = {

    PostCrear: (datos) => axios.post(`distancia-domicilio/crear-distancia-domicilio`,datos),
    GetDistancia: () => axios.get(`distancia-domicilio/listar-distancia-domicilios`),
    PutEditar: (datos) => axios.post(`distancia-domicilio/editar-distancia-domicilio`,datos),
    Delete: (id) => axios.delete(`distancia-domicilio/${id}/eliminar-distancia-domicilio`)
}
export default distanciadomicilio
