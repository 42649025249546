<template>
    <section>
        <div class="row mx-0 align-items-center px-3 py-3 border-bottom">
            <div class="col-auto text-general tres-puntos f-600 f-18">
                <p class="tres-puntos">Parámetros del {{$config.vendedor}} por defecto</p>
            </div>
            <div class="col-auto ml-auto">
                <i class="f-16 icon-cedis text-general mx-2" />
                <el-select v-model="cediSelected" filterable :clearable="false" @change="onChangeCedi()">
                    <el-option
                    v-for="(cedi, i) in cedis"
                    :key="i"
                    :value="cedi.id"
                    :label="cedi.nombre"
                    >
                        <span>{{ cedi.nombre }}</span>
                        <i v-show="!cedi.estado" class="icon-eye-off ml-1" />
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 182px)">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col">
                    <div class="row justify-center mx-0 px-3 pt-3 pb-1 border-bottom">
                        <div class="col-auto text-general f-600 f-16">
                            Cedis: {{ nombreCedis }}
                            <i v-show="!estadoCedis" class="icon-eye-off ml-1" />
                        </div>
                        <div class="col" />
                        <div class="col-auto">
                            <el-tooltip class="item" effect="light" content="Duplicar parámetros a otros cedis" placement="bottom">
                                <i class="f-20 icon-content-duplicate text-general mx-2 cr-pointer" @click="duplicar()" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-4">
                            <label class="text-general f-600 f-16 mt-4">Comisión</label>
                            <el-select v-model="condicionSelected" class="w-100" :clearable="false" @change="onChange($event, 'id_condicion')">
                                <el-option
                                v-for="(cedi, i) in condiciones"
                                :key="i"
                                :value="cedi.id"
                                :label="cedi.condicion"
                                />
                            </el-select>
                        </div>
                    </div>
                    <div class="row mx-0 py-3" />
                    <div class="row mx-0 py-2">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkCambiarCondicion"
                            class="check-red"
                            :label="`${$config.vendedor} puede cambiar la comisión en el pedido de abastecimiento`"
                            @change="onChange($event, 'cambio_condiciones')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px" />
                        <div class="col">
                            <p class="ml-auto">
                                El {{$config.vendedor}} podrá cambiar en cada pedido la comisión aplicada, de lo contrario siempre será el default que definió el administrador.
                            </p>
                        </div>
                    </div>
                    <div class="row mx-0 px-3 py-3">
                        <div class="col-auto text-general f-600 f-16">
                            Suministro y entrega
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkClientePedidoDirecto"
                            class="check-red"
                            :label="`${$config.cliente} pedido directo`"
                            @change="onChange($event, 'cliente_pedido_directo')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Los pedidos que hagan los {{$config.cliente}} de este {{$config.vendedor}} serán atendidos directamente por el centro de distribución y este se encargará de realizar el cambio de estos y gestión de productos hasta el momento del envío.
                            </p>
                        </div>
                    </div>
                    <div class="ml-5" :class="{'disabled-div': !checkClientePedidoDirecto}">
                        <div class="row mx-0 mt-3">
                            <div class="col-auto">
                                <el-checkbox
                                v-model="checkClienteEntregaDirecta"
                                class="check-red"
                                label="Cliente entrega directa"
                                @change="onChange($event, 'cliente_entrega_directa')"
                                />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div style="width:29px;" />
                            <div class="col">
                                <p>
                                    Activa esta opción cuando el {{$config.vendedor}} no hace las entregas de los pedidos a sus {{$config.cliente}}, sino que el centro de distribución se encarga de llevarlo directamente hasta la casa del cliente.
                                </p>
                            </div>
                        </div>

                        <div class="row mx-0 mt-3">
                            <div class="col-auto">
                                <el-checkbox
                                v-model="checkCambioSumistro"
                                class="check-red"
                                label="Permiso cambio tipo de suministro y entrega"
                                @change="onChange($event, 'cambio_tipo_pedido')"
                                />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div style="width:29px;" />
                            <div class="col">
                                <p>
                                    El {{$config.vendedor}} podrá cambiar la configuración de pedidos directos y entrega directa en su pedido, de lo contrario siempre será el default que definió el administrador.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkManejaInventario"
                            class="check-red"
                            label="Maneja inventario"
                            @change="onChange($event, 'manejo_inventario')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Activa esta opción cuando el {{$config.vendedor}} maneje inventario en su casa, así se podrá controlar exactamente que tiene en stock, además servirá para que no despache a sus {{$config.cliente}} productos si no tiene la cantidad adecuada en su inventario.
                            </p>
                        </div>
                    </div>
                    <!-- Nuevo -->
                    <div class="ml-5" :class="{'disabled-div': !checkManejaInventario}">
                        <div class="row mx-0 mt-3">
                            <div class="col-auto">
                                <el-checkbox
                                v-model="checkCambioValor"
                                class="check-red"
                                label="Cambio de valor de producto de venta"
                                @change="onChange($event, 'editar_precios_inventario')"
                                />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div style="width:29px;" />
                            <div class="col">
                                <p>
                                    El {{$config.vendedor}} podrá cambiar el valor del venta de un producto especifico o definir un porcentaje de aumento o disminución que será aplicado a todos los productos.
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-3">
                            <div class="col-auto">
                                <el-checkbox
                                v-model="checkVentaSinStockCedis"
                                class="check-red"
                                label="Ventas sin stock del cedis"
                                @change="onChange($event, 'usar_venta_stock_cedis')"
                                />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div style="width:29px;" />
                            <div class="col">
                                <p>
                                    El {{$config.vendedor}} podrá ofrecer todos los productos del cedis que se pueden vender sin stock.
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-3">
                            <div class="col-auto">
                                <el-checkbox
                                v-model="checkVenderNoExistente"
                                class="check-red"
                                label="Vender productos del inventario que no vende el cedis"
                                @change="onChange($event, 'vender_no_existentes')"
                                />
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div style="width:29px;" />
                            <div class="col">
                                <p>
                                    Puede vender sus productos en stock aún cuando en ese momento el cedis no lo tenga disponible.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkPromocionesCedis"
                            class="check-red"
                            label="Usar las promociones del Cedis"
                            @change="onChange($event, 'promociones_cedis')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Usar las promociones del Cedis.
                            </p>
                        </div>
                    </div>

                    <!-- Cobertura -->
                    <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkCambiarCobertura"
                            class="check-red"
                            label="Cambiar cobertura de entrega"
                            @change="onChange($event, 'cambiar_cobertura')"
                            />
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Activa esta opción cuando el {{$config.vendedor}} pueda cambiar la cobertura de la entrega de los pedidos para sus {{$config.cliente}}.
                            </p>
                        </div>
                    </div>
                    <!-- <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkCambiarCobertura"
                            class="check-red"
                            label="Maneja inventario"
                            @change="onChange($event, 'ventas_solo_app')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Activa esta opción cuando el Vendedor solamente puede crear pedidos asociados a un cliente final que usa el app.
                            </p>
                        </div>
                    </div>  -->

                    <!-- compras -->
                    <div class="row mx-0 px-3 py-3">
                        <div class="col-auto text-general f-600 f-16">
                            Compras
                        </div>
                    </div>
                    <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkClienteApp"
                            class="check-red"
                            :label="`Comprar para vender a ${$config.cliente} registrados`"
                            @change="onChange($event, 'cliente_app')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Puede vender para los {{$config.cliente}} que se han registrado a través de la aplicación.
                            </p>
                        </div>
                    </div>

                    <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkClienteDigitado"
                            class="check-red"
                            :label="`Comprar para vender a ${$config.cliente} no registrados`"
                            @change="onChange($event, 'cliente_digitado')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Puede vender para los {{$config.cliente}} que no se han registrado a través de la aplicación, el {{$config.vendedor}} debe ingresar los datos del {{$config.cliente}} de manera manual.
                            </p>
                        </div>
                    </div>

                    <div class="row mx-0 mt-3">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkClienteYo"
                            class="check-red"
                            :label="`Comprar para el ${$config.vendedor} mismo`"
                            @change="onChange($event, 'cliente_yo')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                El {{$config.vendedor}} puede comprar para si mismo, ésta opción le permite aplicar cupones si están disponibles para él.
                            </p>
                        </div>
                    </div>


                    <!-- cambio precio -->
                    <div class="row mx-0 px-3 py-3">
                        <div class="col-auto text-general f-600 f-16">
                            Cambio de precio
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-auto">
                            <el-checkbox
                            v-model="checkPrecioSugerido"
                            class="check-red"
                            label="Precio sugerido"
                            @change="onChange($event, 'permiso_descuento')"
                            />
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div style="width:29px;" />
                        <div class="col">
                            <p>
                                Este {{$config.vendedor}} podrá sugerir un nuevo valor de los productos de un pedido de sus {{$config.cliente}}, generando una solicitud de aprobación al centro de distribución.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-auto" />
            </div>
        </div>
        <modal ref="duplicar" titulo="Duplicar los parámetros a otro cedis" @guardar="onSaveModal()">
            <div class="row">
                <div class="col text-center">
                    <p>Selecciona el cedis al cual se duplicará estos parámetros</p>
                </div>
            </div>
            <div class="row">
                <div class="col-auto" />
                <div class="col">
                    <el-checkbox-group v-model="cedisChecked">
                        <div v-for="(cedi, i) in cedis.filter(el => el.id != cediSelected)" :key="i" class="row p-3">
                            <el-checkbox
                            :key="`check-${i}`"
                            :label="cedi.id"
                            >
                                {{ cedi.nombre }}
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
                <div class="col-auto" />
            </div>
        </modal>
    </section>
</template>

<script>
import condiciones from '~/services/configurar/admin/condiciones'
import parametrosDefecto from '~/services/configurar/admin/parametrosDefecto'
export default {
    name: 'ParametrosDefecto',
    data(){
        return {
            cediSelected: '',
            condiciones: [],
            condicionSelected: '',
            checkCambiarCondicion: false,
            checkClientePedidoDirecto: false,
            checkClienteEntregaDirecta: false,
            checkCambioSumistro: false,
            checkManejaInventario: false,
            checkPrecioSugerido: false,
            checkVentasApp: false,
            checkCambiarCobertura: false,
            checkClienteApp: false,
            checkClienteDigitado: false,
            checkClienteYo: false,
            checkCambioValor: false,
            checkVentaSinStockCedis: false,
            checkVenderNoExistente: false,
            checkPromocionesCedis: false,
            cedisChecked: [],
            cedisArr: []
        }
    },
    computed: {
        cedis(){
            return this.cedisArr.filter(el => el.id).map(el => ({...el, checked: false})) ?? []
        },
        nombreCedis(){
            const { nombre = '' } = this.cedis.find(el => el.id == this.cediSelected) ?? {}
            return nombre
        },
        estadoCedis(){
            if(this.cediSelected){
                return this.cedis.find(el=>el.id==this.cediSelected).estado == 1
            }else{
                return true
            }
        }
    },
    mounted(){
        this.listarCedis()
    },
    methods: {
        async listarCedis(){
            try {
                const { data } = await parametrosDefecto.listarCedis()
                this.cedisArr = data.data
            } catch (error){
                this.error_catch(error)
            }
        },
        duplicar(){
            if(!this.cediSelected) return this.notificacion('', 'Debe seleccionar un cedis', 'error')
            this.cedisChecked = []
            this.$refs.duplicar.toggle()
        },
        async onChangeCedi(){
            await this.obtenerDatos()
            await this.listarCondiciones()
        },
        async obtenerDatos(){
            const { data } = await parametrosDefecto.listar(this.cediSelected)
            this.condicionSelected = data.condicion
            this.checkCambiarCondicion = data.cambio_condiciones
            this.checkClientePedidoDirecto = data.cliente_pedido_directo
            this.checkClienteEntregaDirecta = data.cliente_entrega_directa
            this.checkCambioSumistro = data.cambio_tipo_pedido
            this.checkManejaInventario = data.manejo_inventario
            this.checkPrecioSugerido = data.permiso_descuento
            this.checkVentasApp = data.ventas_solo_app
            this.checkCambiarCobertura = data.cambiar_cobertura
            this.checkClienteApp = data.cliente_app
            this.checkClienteDigitado = data.cliente_digitado
            this.checkClienteYo = data.cliente_yo
            this.checkCambioValor = data.editar_precios_inventario
            this.checkVentaSinStockCedis = data.usar_venta_stock_cedis
            this.checkVenderNoExistente = data.vender_no_existentes
            this.checkPromocionesCedis = data.promociones_cedis
        },
        async listarCondiciones(){
            try {
                this.condiciones = []
                const params = {
                    id_cedis: this.cediSelected
                }
                const { data } = await condiciones.listarCondiciones(params)
                this.condiciones = data.condiciones
            } catch (error){
                this.error_catch(error)
            }
        },
        async onChange(valor, campo){
            try {

                const obj = {
                    valor,
                    campo,
                    cedi: this.cediSelected
                }

                const { data } = await parametrosDefecto.guardar(obj)
                if (data.exito){
                    this.notificacion('', 'Actualizado con exito', 'success')
                }

                if(campo=='cliente_pedido_directo' && !valor){
                    this.checkClienteEntregaDirecta = false
                    this.checkCambioSumistro = false
                }

                if(campo=='manejo_inventario' && !valor){
                    this.checkCambioValor = false
                    this.checkVentaSinStockCedis = false
                    this.checkVenderNoExistente = false
                }

            } catch (error){
                this.checkCambiarCondicion = false
                this.checkClientePedidoDirecto = false
                this.checkClienteEntregaDirecta = false
                this.checkCambioSumistro = false
                this.checkManejaInventario = false
                this.checkPrecioSugerido = false
                this.checkVentasApp = false
                this.checkCambiarCobertura = false
                this.checkClienteApp = false
                this.checkClienteDigitado = false
                this.checkClienteYo = false
                this.checkCambioValor = false
                this.checkVentaSinStockCedis = false
                this.checkVenderNoExistente = false
                this.checkPromocionesCedis = false
                this.error_catch(error)
            }
        },
        async onSaveModal(){
            try {

                if(!this.cedisChecked.length) return this.notificacion('', 'Debe seleccionar al menos un cedis', 'error')

                const obj = {
                    principal: this.cediSelected,
                    cedis: this.cedisChecked.join(',')
                }

                const { data } = await parametrosDefecto.duplicar(obj)
                if (data.exito){
                    this.duplicar()
                    this.notificacion('', 'Duplicado con exito', 'success')
                }

            } catch (error){
                this.error_catch(error)

            }
        }
    }
}
</script>

<style>

</style>
