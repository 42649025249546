<template>
    <modal ref="modalAnadirCedisCiudad" titulo="Añadir Ciudad" no-aceptar adicional="Añadir" tamano="modal-lg" @adicional="onSubmit">
        <div class="row mx-0">
            <div class="col-auto px-4" />
            <div class="col">
                <div class="row mx-0 align-items-center mt-4 mb-3 justify-center">
                    <div class="col-10">
                        <el-select
                        v-model="ciudadSelected"
                        filterable
                        remote
                        :remote-method="buscarCiudad"
                        :loading="loading"
                        class="w-100"
                        >
                            <el-option
                            v-for="(item, i) in ciudades"
                            :key="i"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-auto px-0">
                        <div class="bg-general p-2 br-12 cr-pointer" @click="onSelect()">
                            <i class="icon-plus text-white" />
                        </div>
                    </div>
                </div>
                <div v-for="(data, d) in ciudadesSelected" :key="d" class="row mx-0 mt-2 justify-center">
                    <div class="col-5">
                        <div class="border bg-light-f5 text-general f-15 br-12 d-middle px-3 py-2 tres-puntos">
                            {{ data.nombre }}
                        </div>
                    </div>
                    
                    <div class="col-4">
                        <el-select
                        v-model="data.idCedi"
                        class="w-100"
                        filterable
                        @change="onChangeCedi(data)"
                        >
                            <el-option
                            v-for="(item, i) in cedis"
                            :key="i"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-auto px-0">
                        <i class="icon-trash-empty hover-inverse cr-pointer f-25" @click="eliminar(data)" />
                    </div>
                </div>
            </div>
            <div class="col-auto px-4" />
        </div>
    </modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import cedisDefecto from '~/services/configurar/admin/cedisDefecto'
export default {
    data(){
        return {
            ciudades: [],
            ciudadesSelected: [],
            ciudadSelected: '',
            idPais: null,
            loading: false
        }
    },
    computed: {
        ...mapGetters({
            cedis: 'admin/cedisDefecto/getCedis',
            datos: 'admin/cedisDefecto/getDatos',
        }),
        ciudadesExcluidas(){
            const pais = this.datos.find(el => el.id == this.idPais)
            return pais.ciudades.map(el => el.id)
        }
    },
    methods: {
        toggle(idPais){
            this.clean()
            this.idPais = idPais
            this.$refs.modalAnadirCedisCiudad.toggle()
        },
        async buscarCiudad(query){
            try {
                this.loading = true
                const params = {
                    pais: this.idPais,
                    ids: this.ciudadesExcluidas.toString(),
                    text: query
                }
                const { data } = await cedisDefecto.buscarCiudad({ params })
                this.ciudades = data
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }

        },
        onSelect(){
            if (this.ciudadSelected){
                const exists = this.ciudadesSelected.some(el => el.id == this.ciudadSelected)
                if (exists){
                    return this.notificacion('Error', 'Ya la ciudad esta agregada', 'error')
                }
                const find = this.ciudades.find(el => el.id == this.ciudadSelected)
                this.ciudadesSelected = [...this.ciudadesSelected, ...[{...find, cedi: {}}]]
                this.ciudadSelected = ''
            }
        },
        eliminar({id}){
            this.ciudadesSelected = this.ciudadesSelected.filter(el => el.id != id)
        },
        onChangeCedi(ciudad){
            const find = this.cedis.find(el => el.id == ciudad.idCedi)
            ciudad.cedi = { ...find }
        },
        async onSubmit(){
            try {
                if (!this.ciudadesSelected.length){
                    return
                }
                const some = this.ciudadesSelected.some(el => _.isEmpty(el.cedi))
                if (some){
                    return this.notificacion('Error', 'Todas las ciudades deben tener un cedis', 'error');
                }
                const cedis = this.ciudadesSelected.map(el => ({id_cedis: el.cedi.id, idm_ciudad: el.id}))
                const { data } = await cedisDefecto.saveCedis({ cedis })
                this.$emit('saved')
                this.$refs.modalAnadirCedisCiudad.toggle()
            } catch (error){
                this.error_catch(error)
            }
        },
        clean(){
            this.ciudades = []
            this.ciudadesSelected = []
            this.ciudadSelected = ''
            this.idPais = null
            this.loading = false
        }

    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.hover-leechero{
    background-color: var(--color-general2);
    color: #ffffff;
    border-radius: 50%;
    transition: 0.8s;
    &:hover{
        background-color: var(--color-general);
        border-radius: 5px;
    }
}
</style>