<template>
    <section>
        <div class="row mx-0 px-3 pl-5 my-1 f-600 border-bottom py-2 text-general">
            Tiempos de estados de los pedidos
        </div>
        <div class="p-3 f-15 overflow-auto custom-scroll" style="height:calc(100vh - 148px);">
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo máximo que tiene un {{$config.vendedor}} para aceptar o confirmar un Pedido, antes de que se cancele automáticamente</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="solicitado">
                        <el-input v-model="tiempos.solicitado_cancelado" @change="CrearEditar($event,1,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Minutos</p>
            </div>
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo máximo que tiene un {{$config.vendedor}} para enviar un Pedido que ha sido previamente Confirmado, antes de que se cancele automáticamente</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="confirmado">
                        <el-input v-model="tiempos.confirmado_cancelado" @change="CrearEditar($event,2,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Minutos</p>
            </div>
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo máximo que tiene un {{$config.vendedor}} para enviar un Pedido con cambios que ya ha sido confirmado por el {{$config.cliente}}, antes de que se cancele automáticamente</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="cambios tendero">
                        <el-input v-model="tiempos.cambios_cancelado_tendero" @change="CrearEditar($event,3,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Minutos</p>
            </div>
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo máximo que tiene un {{$config.cliente}} para confirmar los cambios hechos por el {{$config.vendedor}} en un Pedido, antes de que se cancele automáticamente</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="cambios cliente">
                        <el-input v-model="tiempos.cambios_cancelado_cliente" @change="CrearEditar($event,4,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Minutos</p>
            </div>
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo durante el cual permanece activo el Chat del Pedido. Una vez transcurrido este tiempo, el chat se cerrará y el Pedido pasará a estado Finalizado</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:5" name="enviado finalizado">
                        <el-input v-model="tiempos.enviado_finalizado" @change="CrearEditar($event,5,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Horas</p>
            </div>
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo durante el cual un {{$config.cliente}} tiene disponible hacer un Reclamo de un Pedido. Este tiempo inicia posterior a la confirmación de Envío</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:240" name="enviado reclamo">
                        <el-input v-model="tiempos.enviado_reclamo" @change="CrearEditar($event,6,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Minutos</p>
            </div>
            <div class="row mx-0 my-2 py-1 align-items-center border-bottom">
                <div class="col-7 text-general">
                    <p>Tiempo durante el cual un {{$config.cliente}} tiene disponible solicitar Soporte a {{$config.proyecto}} acerca de un Pedido. Este tiempo inicia posterior al cierre de un pedido</p>
                </div>
                <div class="col-2 px-2">
                    <ValidationProvider v-slot="{errors, valid}" rules="required|max_value:5" name="reclamo">
                        <el-input v-model="tiempos.reclamo_soporte" @change="CrearEditar($event,7,valid)" />
                        <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                    </ValidationProvider>
                </div>
                <p class="text-general">Horas</p>
            </div>
        </div>
    </section>
</template>

<script>
import TiemposPedidos from '~/services/configurar/admin/tiemposPedidos'
export default {
    data(){
        return {
            tiempos:{}
        }
    },
    mounted(){
        this.listarTiempos()
    },
    methods:{
        async listarTiempos(){
            try {
                let tienda = this.$usuario.rol_nombre == 'tendero-vip' ? this.$usuario.tienda.id : null
                const {data} = await TiemposPedidos.GetTiemposPedidos(tienda)
                this.tiempos =  data
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearEditar(event,column,valid){
            try {
                if (valid){
                    let datos ={
                        valor:event,
                        columna:column,
                        id_tienda:this.$usuario.rol_nombre == 'tendero-vip' ? this.$usuario.tienda.id : null
                    }
                    const {data} = await TiemposPedidos.PostCrearEditar(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listarTiempos()
                }
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
