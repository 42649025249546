<template>
    <section>
        <div class="row mx-0 border-bottom py-2 pl-5 px-2">
            <div class="col-auto px-0 f-600 text-general">
                Ingreso y registro
            </div>
        </div>
        <div class="custom-scroll overflow-auto pt-3 px-4" style="height:calc(100vh - 139px)">
            <p class="f-15 text-general mb-3">
                Sube las imagenes que aparecerán en los inicios de sesión de las paginas web de administrador, vendedor y cliente.
            </p>
            <p class="f-15 text-general mb-4">
                Relación de aspcecto 16:9
            </p>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    Administrador inicio
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 31, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 mt-3 px-0 d-flex">
                    <div v-if="imgsLogin.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data,index) in imgsLogin" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.cliente }} - Inicio
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 21, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 scroll-none overflow-auto">
                    <div class="col-12 px-0 d-flex">
                        <div v-if="imgsClienteInicio.length > 0" class="mt-3" style="display:inline-flex;">
                            <img v-for="(data, index) in imgsClienteInicio" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                        </div>
                        <div v-else class="mt-3">
                            <p class="f-14 ml-3">
                                No hay imagenes para mostrar
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.cliente }} - Ingreso
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 22, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsClienteIngreso.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsClienteIngreso" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.cliente }} - Registro
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 23, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsClienteRegistro.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsClienteRegistro" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.cliente }} - Código de verificación
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 25, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsClienteCodigo.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsClienteCodigo" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.cliente }} - Recuperación de contraseña
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 24, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsClienteContrasena.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsClienteContrasena" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.vendedor }} - Inicio
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 11, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 scroll-none overflow-auto">
                    <div v-if="imgsVendedorInicio.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsVendedorInicio" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.vendedor }} - Ingreso
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 12, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsVendedorIngreso.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsVendedorIngreso" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.vendedor }} - Registro
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 13, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsVendedorRegistro.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsVendedorRegistro" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.vendedor }} - Código de verificacion
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 15, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsVendedorCodigo.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsVendedorCodigo" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mx-0 align-items-center mb-4">
                <div class="col-auto f-15 text-general pl-0">
                    {{ $config.vendedor }} - Recuperación de contraseña
                </div>
                <div class="btn-general px-3 ml-auto" @click="tipo = 14, galeriaVista()">
                    Editar
                </div>
                <div class="col-12 px-0 d-flex">
                    <div v-if="imgsVendedorContrasena.length > 0" class="mt-3" style="display:inline-flex;">
                        <img v-for="(data, index) in imgsVendedorContrasena" :key="index" :src="data.imagen" class="obj-cover border mr-2" width="288" height="162" />
                    </div>
                    <div v-else class="mt-3">
                        <p class="f-14 ml-3">
                            No hay imagenes para mostrar
                        </p>
                    </div>
                </div>
            </div>
            <botones-configurables :data="imgsIngreso" @update="getData" />
        </div>
        <!-- Partials -->
        <modal-galeria-vista ref="modalGaleriaVista" :tipo="tipo" :galeria-seleccionada="galeriaSeleccionada" @subirImagen="subirImagen" />
        <modal-subir-imagen ref="modalSubirImagen" :tipo="tipo" @actualizar="getData" />
    </section>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    components: {
        modalGaleriaVista: () => import('./partials/modalGaleriaVista'),
        modalSubirImagen: () => import('./partials/modalSubirImagenRegistro'),
        botonesConfigurables: () => import('./components/botonesConfigurables.vue')
    },
    data(){
        return {
            imgsLogin: [],
            imgsClienteInicio: [],
            imgsClienteIngreso: [],
            imgsClienteRegistro: [],
            imgsClienteCodigo: [],
            imgsClienteContrasena: [],
            imgsVendedorInicio: [],
            imgsVendedorIngreso: [],
            imgsVendedorRegistro: [],
            imgsVendedorContrasena: [],
            imgsVendedorCodigo: [],
            galeriaSeleccionada: [],
            imgsIngreso: {},
            tipo: null,
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        async getData(){
            try {
                const params = {
                    tipo: this.tipo
                };
                const {data} = await Service.getImagenesIngresoRegistro(params);
                this.imgsLogin = data.admin;
                this.imgsClienteInicio = data.cliente_inicio;
                this.imgsClienteIngreso = data.cliente_login;
                this.imgsClienteRegistro = data.cliente_registro;
                this.imgsClienteCodigo = data.cliente_codigo;
                this.imgsClienteContrasena = data.cliente_contrasena;
                this.imgsVendedorInicio = data.vendedor_inicio;
                this.imgsVendedorIngreso = data.vendedor_ingreso;
                this.imgsVendedorRegistro = data.vendedor_registro;
                this.imgsVendedorContrasena = data.vendedor_contrasena;
                this.imgsVendedorCodigo = data.vendedor_codigo;
                this.imgsIngreso = data.ingreso;
            } catch(error){
                this.error_catch(error)
            }
        },
        galeriaVista(){
            switch(this.tipo){
            case 11:
                this.galeriaSeleccionada = this.imgsVendedorInicio;
                break;
            case 12:
                this.galeriaSeleccionada = this.imgsVendedorIngreso;
                break;
            case 13:
                this.galeriaSeleccionada = this.imgsVendedorRegistro;
                break;
            case 14:
                this.galeriaSeleccionada = this.imgsVendedorContrasena;
                break;
            case 15:
                this.galeriaSeleccionada = this.imgsVendedorCodigo;
                break;
            case 21:
                this.galeriaSeleccionada = this.imgsClienteInicio;
                break;
            case 22:
                this.galeriaSeleccionada = this.imgsClienteIngreso;
                break;
            case 23:
                this.galeriaSeleccionada = this.imgsClienteRegistro;
                break;
            case 24:
                this.galeriaSeleccionada = this.imgsClienteContrasena;
                break;
            case 25:
                this.galeriaSeleccionada = this.imgsClienteCodigo;
                break;
            case 31:
                this.galeriaSeleccionada = this.imgsLogin;
                break;
            }
            this.$refs.modalGaleriaVista.toggle();
        },
        subirImagen(){
            this.$refs.modalSubirImagen.toggle();
        },
    }
}
</script>
