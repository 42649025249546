<template>
    <section>
        <cargando v-if="cargando" />
        <div class="row mx-0 align-items-center p-3 text-general border-bottom">
            <p class="col-auto f-18 f-500">
                Home app {{ $config.vendedor }}
            </p>
            <i class="icon-cedis f-18 ml-auto" />
            <div class="col-3">
                <el-select v-model="cediSelected" filterable :clearable="false" @change="onChangeCedi()">
                    <el-option
                    v-for="(cedi, i) in cedis"
                    :key="i"
                    :value="cedi.id"
                    :label="cedi.nombre"
                    >
                        <span> {{ cedi.nombre }} </span>
                        <i v-show="!cedi.estado" class="icon-eye-off" />
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="row mx-5 align-items-center mt-3 py-2 border-bottom">
            <p class="f-15 text-general f-500">
                Cedis: {{ nombreCedis }}
                <i v-show="!estadoCedis" class="icon-eye-off" />
            </p>
            <el-tooltip placement="bottom" content="Duplicar parámetros a otros cedis" effect="light">
                <div class="btn-action border ml-auto cr-pointer" @click="duplicarMetodos">
                    <i class="icon-content-duplicate f-18" />
                </div>
            </el-tooltip>
        </div>
        <div class="row mx-0 px-5 custom-scroll overflow-auto" style="height:calc(100vh - 243px);">
            <p class="col-12 px-5 my-3 text-general2 f-15">
                Seleccione las secciones que los {{ $config.vendedor }} de este cedis podrá ver en el <br /> inicio de la aplicación
            </p>
            <div v-for="(data,i) in opciones_home" :key="i" class="col-12 d-flex mb-3">
                <el-checkbox v-model="data.check" class="check-dark mt-1" @change="controlActionHome($event,data.campo_table)" />
                <div class="col mt-1">
                    <p class="f-15 text-general">
                        {{ data.titulo }}
                    </p>
                    <p class="f-15 text-general2">
                        {{ data.descripcion }}
                    </p>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalDuplicarParametros" titulo="Duplicar los parámetros a otro cedis" @guardar="putDuplicateCedis()">
            <div class="row">
                <div class="col text-center">
                    <p>Selecciona el cedis al cual se duplicará estos parámetros</p>
                </div>
            </div>
            <div class="row">
                <div class="col-auto" />
                <div class="col">
                    <el-checkbox-group v-model="cedisDuplicarInfo">
                        <div v-for="(cedi, i) in cedis.filter(el => el.id != cediSelected)" :key="i" class="row p-3">
                            <el-checkbox
                            :key="`check-${i}`"
                            :label="cedi.id"
                            >
                                {{ cedi.nombre }}
                            </el-checkbox>
                        </div>
                    </el-checkbox-group>
                </div>
                <div class="col-auto" />
            </div>
        </modal>
    </section>
</template>

<script>
import Home from '~/services/configurar/admin/homeLeecheros'

export default {
    data(){
        return {
            cedisArr: [],
            cargando: false,
            opciones_home: [{
                titulo: 'Inicio de postulación',
                descripcion: 'Invitación a completar los datos de registro que permitían evaluarlo en el proceso de postulación.',
                campo_table: 'postulacion_inicio',
                check: false
            }, {
                titulo: 'Proceso de postulación',
                descripcion: 'Chat activo con el administrador sobre el proceso de postulación posterior a la registro completo de datos.',
                campo_table: 'postulacion_proceso',
                check: false
            }, {
                titulo: 'Consulta activa',
                descripcion: 'Chat activo del vendedor con leeche sobre alguna consulta que no encontró en las preguntas preguntas.',
                campo_table: 'consulta_activa',
                check: false
            }, {
                titulo: 'Gráfica y estados de ventas',
                descripcion: 'Gráfica de ventas de día de ayer, la semana anterior y el semestre más reciente. junto con el valor de ventas del último mes.',
                campo_table: 'ventas',
                check: false
            }, {
                titulo: 'Gráfica y estados de compras',
                descripcion: 'Gráfica de compras de día de ayer, la semana anterior y el semestre más reciente. junto con el valor de compras del último mes.',
                campo_table: 'compras',
                check: false
            },
            {
                titulo: 'Gráfica y estados de ganancias',
                descripcion: 'Gráfica de ventas de día de ayer, la semana anterior y el semestre más reciente, junto con el valor de ventas del último mes.',
                campo_table: 'ganancias',
                check: false
            },
            {
                titulo: 'Pedidos por recibir',
                descripcion: 'Son los pedidos que el vendedor a realizado a leeche y que aún están en estado vigente.',
                campo_table: 'pedidos_admin',
                check: false
            },
            {
                titulo: 'Pedidos por entregar',
                descripcion: 'Son los pedidos que los clientes le han hecho al vendedor y que aún están en estado vigente.',
                campo_table: 'pedidos_cliente',
                check: false
            },
            {
                titulo: 'Promociones',
                descripcion: 'Promociones que el cedis ofrece en el momento.',
                campo_table: 'promociones',
                check: false
            },
            {
                titulo: 'Compras frecuentes',
                descripcion: 'Son los productos que más veces a comprado el vendedor en los últimos "30" días.',
                campo_table: 'compras_frecuentes',
                check: false
            },
            {
                titulo: 'Créditos por cobrar',
                descripcion: 'Son las cuentas que tiene el vendedor con sus clientes por ser cobradas.',
                campo_table: 'creditos_cobrar',
                check: false
            },
            {
                titulo: 'Créditos por pagar',
                descripcion: 'Son las cuentas que tiene el vendedor con la app por ser pagadas.',
                campo_table: 'creditos_pagar',
                check: false
            }
            ],
            cedisDuplicarInfo: [],
            cediSelected: '',
            checked: '',
        }
    },
    computed: {
        cedis(){
            return this.cedisArr.filter(el => el.id).map(el => ({...el, checked: false})) ?? []
        },
        nombreCedis(){
            const { nombre = '' } = this.cedis.find(el => el.id == this.cediSelected) ?? {}
            return nombre
        },
        estadoCedis(){
            if(this.cediSelected){
                return this.cedisArr.find(el=>el.id == this.cediSelected).estado == 1
            }else{
                return true
            }
        }
    },
    mounted(){
        this.getCedis()
        this.opciones_home[2].descripcion = `Chat activo del ${this.$config.vendedor} con ${this.$config.proyecto} sobre alguna consulta que no encontró en las preguntas preguntas.`
        this.opciones_home[6].descripcion = `Son los pedidos que el ${this.$config.vendedor} a realizado a ${this.$config.proyecto} y que aún están en estado vigente.`
        this.opciones_home[7].descripcion = `Son los pedidos que los ${this.$config.cliente} le han hecho al ${this.$config.vendedor} y que aún están en estado vigente.`
        this.opciones_home[9].descripcion = `Son los productos que más veces a comprado el ${this.$config.vendedor} en los últimos "30" días.`
        this.opciones_home[10].descripcion = `Son las cuentas que tiene el ${this.$config.vendedor} con sus ${this.$config.cliente} por ser cobradas.`
        this.opciones_home[11].descripcion = `Son las cuentas que tiene el ${this.$config.vendedor} con ${this.$config.proyecto} por ser pagadas.`
		if(`${this.$config.menu.creditos}` == 0)this.opciones_home.splice(this.opciones_home.findIndex( el => el.campo_table == 'creditos_cobrar'), 2)
    },
    methods: {

        async getCedis(){
            try {
                const { data } = await Home.getCedis()
                this.cedisArr = data.data
            } catch (error){
                this.error_catch(error)
            }
        },

        async onChangeCedi(){
            this.cargando = true;
            await this.getOptionsHomeLeechero()
        },

        async getOptionsHomeLeechero(){
            try {
                const { data } = await Home.getOptionsHomeLeechero(this.cediSelected)
                if (data.optionsHomeCedi.length < 1){
                    this.opciones_home.map(function(dato){
                        if (dato.check === true){
                            dato.check = false;
                        }
                    });
                    return this.cargando = false;
                }
                this.actualizarOpciones(data.optionsHomeCedi);
            } catch (error){
                this.error_catch(error)
            }
        },

        async controlActionHome(event,campo_table){
            if (event){
                this.postOptionHomeLeechero(campo_table);
            } else {
                this.deleteOptionHomeLeechero(campo_table);
            }
        },

        async postOptionHomeLeechero(campo_table){
            if (this.cediSelected === '' || this.cediSelected === null){
                this.opciones_home.map(function(dato){
                    if (dato.check === true){
                        dato.check = false;
                    }
                });
                return this.notificacion('Mensaje', 'Por favor seleccione un cedis', 'warning')
            }
            try {
                const params = {
                    id_cedi: this.cediSelected,
                    campo_table: campo_table
                }
                const { data } = await Home.postHomeLeechero(params)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
            } catch (error){
                this.error_catch(error)
            }
        },

        async putDuplicateCedis(){
            if (this.cediSelected === '' || this.cediSelected === null){
                return this.notificacion('Mensaje', 'Por favor seleccione un cedis', 'warning')
            }
            if (this.cedisDuplicarInfo.length < 1){
                return this.notificacion('Mensaje', 'Por favor seleccione al menos un cedis', 'warning')
            }
            try {
                const params = {
                    cedis: this.cedisDuplicarInfo,
                }
                const { data } = await Home.putDuplicateCedis(params,this.cediSelected)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
                this.cedisDuplicarInfo = [];
                this.$refs.modalDuplicarParametros.toggle()
            } catch (error){
                this.error_catch(error)
            }
        },

        async deleteOptionHomeLeechero(campo_table){
            try {
                const params = {
                    campo_table: campo_table
                }
                const { data } = await Home.deleteOptionHomeLeechero(params,this.cediSelected)
                this.notificacion('Mensaje', data.mensaje, data.tipo)
            } catch (error){
                this.error_catch(error)
            }
        },

        async actualizarOpciones(datos){
            let pasajero = this.opciones_home;
            for (let i = 0; i < datos.length; i++){
                if (datos[i].campo_table === pasajero[i].campo_table){
                    if (datos[i].check === 1){
                        pasajero[i].check = true;
                        continue;
                    }
                    pasajero[i].check = false;
                    continue;
                }
            }
            this.opciones_home = [];
            this.opciones_home = pasajero;
            this.cargando = false;
        },

        duplicarMetodos(){
            if (this.cediSelected === '' || this.cediSelected === null){
                return this.notificacion('Mensaje', 'Por favor seleccione un cedis', 'warning')
            }
            this.$refs.modalDuplicarParametros.toggle()
        },
    }
}
</script>
