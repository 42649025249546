<template>
    <section>
        <div class="p-3 f-15 h-90">
            <div class="row mx-0 text-general">
                Crea los diferentes motivos por los cuales se puede rechazar la solicitud
            </div>
            <ValidationObserver ref="validacion" v-slot="{ valid }">
                <div class="row mx-0 my-3">
                    <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:40" name="motivo" tag="div" class="col-xl-5 col-lg-6">
                        <p class="ml-2 text-general">Motivo</p>
                        <el-input v-model="model.nombre" maxlength="40" show-word-limit class="input-pr">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <!-- <div class="col-3 px-2">
                    </div> -->
                    <div class="col-auto mt-3 pt-1">
                        <el-tooltip class="item" effect="light" content="Crear motivo" placement="bottom">
                            <div class="bg-general cr-pointer d-middle-center br-10" style="width:40px;height:40px;">
                                <i class="icon-plus f-20 text-white" @click="CrearRegistro(valid)" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </ValidationObserver>
            <div class="row mx-0 my-2 text-general">
                Lista de motivos
            </div>
            <div v-if="tendero.length" class="custom-scroll overflow-auto" style="height: calc(100% - 140px);">
                <ValidationObserver ref="validacion2" v-slot="{ valid }">
                    <draggable :list="tendero" handle=".handle" @change="actualizar_posicion">
                        <div v-for="(data, idx) in tendero" :key="idx" class="row mx-0 my-1 align-items-center">
                            <i class="icon-drag f-22 cr-pointer handle text-muted" />
                            <div class="col-5 px-2" :class="data.estado?'bg-disabled':'bg-inactive'">
                                <ValidationProvider v-slot="{errors}" :vid="'nombre'+idx" rules="required|max:40" name="motivo">
                                    <el-input v-model="data.nombre" :disabled="editar!=idx" maxlength="40" show-word-limit class="input-pr">
                                        <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                                    </el-input>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-auto px-2">
                                <el-tooltip v-if="editar!=idx" class="item" effect="light" content="Editar" placement="bottom">
                                    <div class="d-middle-center btn-edit cr-pointer br-10" style="width:44px;height:44px;">
                                        <i class="icon-pencil-outline f-20" @click="editar=idx" />
                                    </div>
                                </el-tooltip>
                                <el-tooltip v-else class="item" effect="light" content="Guardar" placement="bottom">
                                    <div class="bg-light-f5 shadow border text-general2 cr-pointer d-middle-center br-10" style="width:40px;height:40px;" @click="editarTendero(valid,data)">
                                        <i class="icon-ok-circled-outline f-20" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto px-0">
                                <el-tooltip
                                class="item "
                                effect="light"
                                placement="bottom"
                                >
                                    <div slot="content" class="text-center" style="max-width:230px;">
                                        {{ data.count > 0 ? 'No puede eliminar este motivo por que hay deliveries que estan inactivos por este' : 'Eliminar' }}
                                    </div>
                                    <div
                                    :class="`${data.count > 0 ? 'text-gris':'hover-inverse'} d-middle-center cr-pointer br-10`"
                                    style="width:44px;height:44px;"
                                    @click="data.count > 0 ? '':eliminar(data.id)"
                                    >
                                        <i class="icon-trash-empty  f-20" />
                                    </div>
                                </el-tooltip>
                            </div>
                            <div class="col-auto px-2">
                                <el-switch
                                v-model="data.estado"
                                class="my-auto switch-red"
                                active-color="red"
                                inactive-color="#DBDBDB"
                                @change="CambiodeEstado(data)"
                                />
                            </div>
                            <div class="col-auto">
                                <el-tooltip class="item" effect="light" placement="bottom">
                                    <div slot="content" class="text-center" style="max-width:140px;">
                                        Solicitudes de cambio con este motivo
                                    </div>
                                    <div class="col-auto d-middle">
                                        <div class="bg-general3 br-12 px-3 text-white">
                                            <i class="icon-text-box-check-outline f-20" />
                                            <span class="ml-2">{{ data.count }}</span>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </div>
                        </div>
                    </draggable>
                </ValidationObserver>
            </div>
            <sinDatos v-else icon="list" mensaje="No se ha creado ningún motivo" />
        </div>
        <modalEliminar ref="modalEliminar" titulo="Eliminar motivo" mensaje="¿Desea eliminar el motivo de rechazo por cambio de precio?" @eliminar="EliminarRegistro" />
    </section>
</template>

<script>
import CancelacionAdmin from '~/services/configurar/admin/cancelacionAdmin'
import motivosGenerales from "~/services/configurar/admin/motivosGenerales"
export default {
    data(){
        return {
            editar: -1,
            model:{
                nombre:null,
                tipo: 22
            },
            id_eliminar:null,
            tendero:[]
        }
    },
    mounted(){
        this.listar()
    },
    methods: {
        eliminar(id){
            if(id){
                this.id_eliminar = id
            }
            this.$refs.modalEliminar.toggle()
        },
        async listar(){
            try {
                const {data} = await motivosGenerales.listarRechazo()
                console.log(data);
                this.tendero = data.map(el => ({...el, estado: !!el.estado}))
            } catch (e){
                this.error_catch(e)
            }
        },
        async CrearRegistro(valid){
            try {
                if(valid){
                    const {data} = await CancelacionAdmin.PostCrear(this.model)
                    this.notificacion('', data.mensaje, 'success')
                    this.limpiar()
                    this.listar()
                    this.$refs.validacion.reset();
                }
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarTendero(valid,row){
            try {
                if(valid){
                    this.editar=-1
                    let datos={
                        id:row.id,
                        nombre:row.nombre,
                        tipo:row.tipo,
                    }
                    const {data} = await CancelacionAdmin.PutEditar(datos)
                    this.notificacion('', data.mensaje, 'success')
                    this.listar()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async EliminarRegistro(){
            try {
                const {data} = await CancelacionAdmin.Delete(this.id_eliminar,this.model.tipo)
                this.notificacion('', data.mensaje, 'success')
                this.listar()
                this.$refs.modalEliminar.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        async CambiodeEstado(row){
            try {
                let datos={
                    id:row.id,
                    estado:!row.estado
                }
                const {data} = await CancelacionAdmin.PutEstado(datos)
                this.notificacion('', data.mensaje, 'success')
                this.listar()
            } catch (e){
                this.error_catch(e)
            }
        },
        async actualizar_posicion(event){
            const datos = {
                antiguaPosicion: event.moved.oldIndex + 1,
                nuevaPosicion: event.moved.newIndex + 1,
                tipo: event.moved.element.tipo,
                id: event.moved.element.id,
            }
            const { data } = await CancelacionAdmin.PostActualizarPosicion(datos)
            this.notificacion('', data.mensaje, 'success')
            this.listar()
        },
        limpiar(){
            this.model.nombre = null
        }
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px !important;
}
.hover-button{
    background-color: var(--color-light);
    color: var(--color-general2);
    transition: .1s linear;
    &:hover{
        background-color: var(--color-general2);
        color: #ffffff;
    }
}
</style>
