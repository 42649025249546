<template>
	<section>
		<!-- <router-view /> -->
		<template v-if="id_cedis != null">
			<div class="row mx-0">
				<div class="col-12 ">
					<div class="row mx-4 border-bottom pt-3">
						<div class="col-auto f-500 text-general">
							Cedis:
							<i v-show="!estadoCedis" class="icon-eye-off" />
						</div>
						<div class="col-4 pb-2">
							<el-select v-model="id_cedis" filterable class="w-100" @change="listarCondicionesPagos">
								<el-option v-for="item in cedis" :key="item.id" :label="item.nombre" :value="item.id">
									<span>{{ item.nombre }} </span>
									<i v-show="!item.estado" class="icon-eye-off" />
								</el-option>
							</el-select>
						</div>
						<div class="col-auto ml-auto px-0 cr-pointer" @click="eliminarCedis">
							<el-tooltip placement="bottom" content="Desmarcar todas las condiciones" effect="light">
								<i class="icon-fusionar text-general f-20" />
							</el-tooltip>
						</div>
						<div class="col-auto">
							<el-tooltip placement="bottom" content="Duplicar configuracion a otro cedis" effect="light">
								<i class="icon-content-duplicate text-general f-20 cr-pointer"
									@click="duplicarProducto()" />
							</el-tooltip>
						</div>
					</div>
				</div>
			</div>
			<div class="custom-scroll overflow-auto px-4 pl-5" >
				<div v-for="(condicion,c) in condiciones" :key="c" class="row mx-0 mt-3">
                    <div class="col-4 px-0">
                        <div class="row align-items-end mx-0 mt-2">
                            <div class="col-auto pb-2 px-0">
                                <i class="f-18 text-general2" :class="`icon-${condicion.estrella ? 'star orange-star' : 'star-empty'}`" @click="favoritoCedis(condicion.id)" />
                            </div>
                            <div class="col-auto pb-2">
                                <el-checkbox v-model="condicion.checked" :disabled="!condicion.checked" class="check-dark" @change="eliminarCondicion(condicion)" />
                            </div>
                            <div class="col pt-3 d-flex align-items-center">
                                <div class="col nombre-condicion nombre br-10 py-2 ">
                                    {{ condicion.condicion }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col px-0">
                        <ValidationObserver ref="validacion2" v-slot="{ valid }">
                            <div class="row mx-0 mt-1">
                                <div class="col">
                                    <p class="text-general f-13 pl-3">
                                        Desde
                                    </p>
                                    <ValidationProvider v-slot="{errors}" :vid="`desde-${c}`" :rules="`decimal:${configComisionCedis.precision}|max:11`" name="desde">
                                        <input-miles v-model="condicion.model.desde" :money-options="configComisionCedis" placeholder="Valor" class="w-100" style="font-size:14px;" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col">
                                    <p class="text-general f-13 pl-3">
                                        Hasta
                                    </p>
                                    <ValidationProvider v-slot="{errors}" :vid="`hasta-${c}`" :rules="`decimal:${configComisionCedis.precision}|max:11`" name="hasta">
                                        <input-miles v-model="condicion.model.hasta" :money-options="configComisionCedis" placeholder="Valor" class="w-100" style="font-size:14px;" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col">
                                    <p class="text-general f-13 text-center">
                                        Comisión(%)
                                    </p>
                                    <ValidationProvider v-slot="{errors}" :vid="`pago-${c}`" rules="decimal:2|max:100" name="pago">
                                        <input-miles v-model="condicion.model.pago" :money-options="configComision" placeholder="%" class="w-100" style="font-size:14px;" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                                <div class="col-auto pt-3">
                                    <el-tooltip placement="bottom" content="Crear Comisión" effect="light">
                                        <div class="bg-general text-white br-10 px-1 py-1 mt-1" @click="crearCondicionPago(valid, condicion, c)">
                                            <i class="icon-plus f-18 cr-pointer" />
                                        </div>
                                    </el-tooltip>
                                </div>
                                <div class="col-1" />
                            </div>
                        </ValidationObserver>
                        <div v-for="(comision, c2) in condicion.comisiones" :key="c2" class="row mx-0 mt-2">
                            <div class="col">
                                <input-miles v-model="comision.desde" :money-options="configComisionCedis" :disabled="comision.editar" placeholder="Valor" class="w-100" style="font-size:14px;" />
                            </div>
                            <div class="col">
                                <input-miles v-model="comision.hasta" :money-options="configComisionCedis" :disabled="comision.editar" placeholder="Valor" class="w-100" style="font-size:14px;" />
                            </div>
                            <div class="col">
                                <input-miles v-model="comision.pago" :money-options="configComision" :disabled="comision.editar" placeholder="%" class="w-100" style="font-size:14px;" />
                            </div>
                            <div class="col-auto pt-2">
                                <i v-if="comision.editar" class="icon-pencil-outline f-20 cr-pointer" @click="comision.editar = !comision.editar" />
                                <i v-else class="icon-ok-circled-outline text-success f-20 cr-pointer" @click="editarCondicionPago(comision)" />
                            </div>
                            <div class="col-1 pt-2">
                                <i v-if="comision.editar" class="icon-trash-empty f-20 text-general2 cr-pointer" @click="eliminar(comision.id)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

		</template>
		<!-- Partials -->
		<modal-duplicar-comision ref="modalDuplicarComision" />
		<modalEliminar ref="modalEliminar" titulo="Eliminar comisión" mensaje="¿Desea eliminar la comisión?"
			@eliminar="deleteCondicionPago" />
		<modalEliminar ref="modalEliminarCondicion" titulo="Eliminar comisiones de la condición"
			mensaje="¿Desea eliminar las comisiones?" @eliminar="deleteCondicionPagoCondicion" />
		<modalEliminar ref="modalEliminarCedis" titulo="Eliminar comisiones del Cedis"
			mensaje="¿Desea eliminar todas las comisiones del Cedis?" @eliminar="deleteCondicionPagoCedis" />
		<modal ref="modalFavorito" titulo="Cambiar condición Cedis" @guardar="editarCondicionCedis">
			<slot>
				<div class="row justify-center">
					<el-checkbox v-model="radio" class="check-red">
						Cambiar condición por defecto a los {{$config.vendedor}}
					</el-checkbox>
				</div>
			</slot>
		</modal>
	</section>
</template>

<script>
import { mapGetters } from 'vuex'
import Condiciones from "~/services/configurar/admin/condiciones";
export default {
	components: {
		modalDuplicarComision: () => import('./partials/modalDuplicarComision')
	},
	data() {
		return {
			model: {
				desde: 0,
				hasta: 0,
				pago: 0,
			},
			text: 'Editar clasificaciòn de descuentos',
			cedis: [],
			radio: false,
			id_cedis: null,
			idEliminar: null,
			idCondicion: null,
			condiciones: [],
			configComision: {
				decimal: ',',
				thousands: '.',
				prefix: '',
				suffix: ' %',
				precision: 1,
				masked: false
			},
		}
	},
	computed: {
		...mapGetters({
			monedas: 'cedis/monedas',
		}),
		nombreCedis() {
			return this.cedis.find(el => el.id == this.id_cedis).nombre || 'Sin cedis'
		},
		estadoCedis() {
			return this.cedis.find(el => el.id == this.id_cedis).estado == 1
		},
		configComisionCedis() {
			let precision = this.cedis.find(el => el.id == this.id_cedis).decimales

			return {
				decimal: ',',
				thousands: '.',
				prefix: '$ ',
				suffix: '',
				precision,
				masked: false
			}
		}
	},
	mounted() {
		this.listarCedis()
	},
	methods: {
		async listarCedis() {
			try {
				const { data } = await Condiciones.getCedis()
				this.cedis = data.data
				this.id_cedis = this.cedis[0].id
				this.listarCondicionesPagos()
			} catch (e) {
				this.error_catch(e)
			}
		},
		async listarCondicionesPagos() {
			try {
				const params = {
					id_cedis: this.id_cedis
				}
				const { data } = await Condiciones.listarCondicionesPagos(params)
				data.condiciones.forEach(el => {
					el.checked = !!el.comisiones.length
					el.model = {
						desde: 0,
						hasta: 0,
						pago: 0,
						id_cedis: this.id_cedis,
						id_condicion: el.id,
					}
					el.comisiones.forEach(ele => ele.editar = true)
				})
				this.condiciones = data.condiciones
			} catch (error) {
				console.error(error);
			}
		},
		async crearCondicionPago(valid, condicion, i) {
			try {
				if (valid) {
					const { data } = await Condiciones.crearCondicionPago(condicion.model)
					if (data.error) {
						return this.notificacion('', data.error, 'warning')
					}
					this.notificacion('', data.mensaje, 'success')
					this.listarCondicionesPagos()
					this.$refs.validacion2[i].reset()
				}
			} catch (e) {
				this.error_catch(e)
			}
		},
		async editarCondicionPago(row) {
			try {

				const { data } = await Condiciones.editarCondicionPago(row)
				if (data.error) {
					return this.notificacion('', data.error, 'warning')
				}
				row.editar = !row.editar
				this.notificacion('', data.mensaje, 'success')
				this.listarCondicionesPagos()
			} catch (e) {
				this.error_catch(e)
			}
		},
		eliminar(id) {
			if (id) {
				this.idEliminar = id
			}
			this.$refs.modalEliminar.toggle()
		},
		async deleteCondicionPago() {
			try {
				const { data } = await Condiciones.deleteCondicionPago(this.idEliminar)
				this.notificacion('', data.mensaje, 'success')
				this.listarCondicionesPagos()
				this.$refs.modalEliminar.toggle()
			} catch (e) {
				this.error_catch(e)
			}
		},
		eliminarCondicion(condicion) {
			if (condicion.id) {
				this.idCondicion = condicion.id
			}
			this.$refs.modalEliminarCondicion.toggle()
		},
		async deleteCondicionPagoCondicion() {
			try {
				const { data } = await Condiciones.deleteCondicionPagoCondicion(this.id_cedis, this.idCondicion)
				this.notificacion('', data.mensaje, 'success')
				this.listarCondicionesPagos()
				this.$refs.modalEliminarCondicion.toggle()
			} catch (e) {
				this.error_catch(e)
			}
		},
		eliminarCedis() {
			this.$refs.modalEliminarCedis.toggle()
		},
		async deleteCondicionPagoCedis() {
			try {
				const { data } = await Condiciones.deleteCondicionPagoCedis(this.id_cedis)
				this.notificacion('', data.mensaje, 'success')
				this.listarCondicionesPagos()
				this.$refs.modalEliminarCedis.toggle()
			} catch (e) {
				this.error_catch(e)
			}
		},
		favoritoCedis(id) {
			if (id) {
				this.idCondicion = id
				this.radio = false
				this.$refs.modalFavorito.toggle()
			}
		},
		async editarCondicionCedis() {
			try {
				let params = {
					id_condicion: this.idCondicion,
					id_cedis: this.id_cedis,
					leechero: this.radio
				}
				const { data } = await Condiciones.editarCondicionCedis(params)
				this.notificacion('', data.mensaje, 'success')
				this.listarCondicionesPagos()
				this.$refs.modalFavorito.toggle()
			} catch (e) {
				this.error_catch(e)
			}
		},
		crear(valid, i) {
			this.$refs.validacion2[i].reset()
		},
		duplicarProducto() {
			let cedisValidos = this.cedis.filter(el => el.id != this.id_cedis && el.id != 0)
			cedisValidos.forEach(el => { el.checked = false })
			this.$refs.modalDuplicarComision.toggle(cedisValidos, this.id_cedis)
		},
		goCondiciones() {
			console.log(123);
			this.$router.push({ name: 'admin.conf.condiciones' })
		}
	}
}
</script>

<style lang="css" scoped>
.orange-star {
	color: #FF9D32;
}

.nombre-condicion {
	height: 45px;
	background-color: #E9ECEF;
	color: var(--text-general) !important;
	font-size: 14px;
}
</style>
