<template>
    <section>
        <div class="row mx-0 border-bottom py-2 pl-5 px-2">
            <div class="col-auto px-0 f-600 text-general">
                Imagenes de estados
            </div>
        </div>
        <cargando v-if="cargando" />
        <div v-if="showData" class="custom-scroll overflow-auto pt-3 px-4" style="height:calc(100vh - 139px)">
            <p class="f-15 text-general mb-2">
                Sube las imagenes que aparecerán en diferentes lugares de las aplicaciones del sistema.
            </p>
            <p class="f-15 text-general mb-5">
                Formato: SVG<span class="ml-5">Relación de aspecto 1:1</span>
            </p>
            <div v-for="(data, index) in inicio" :key="index" class="row mx-0 mb-4">
                <space-image-estados :texto="data.titulo" :tipo="data.tipo" :data="data.data" @actualizar="getData()" />
                <div class="col px-4 pt-4">
                    <p class="f-15 text-general mt-2">{{ data.tipoLabel }}</p>
                    <p class="f-15 text-general mt-2">{{ data.descripcion }}</p>
                    <div class="row mx-0 mt-3">
                        <img :src="data.miniatura" width="180" height="180" />
                    </div>
                </div>
            </div>
            <pantallas-vacias :datos="pantallasVacias" />
            <advertencia :datos="advertencia" />
        </div>
    </section>
</template>

<script>
import Service from "~/services/configurar/admin/personalizacion"
export default {
    components: {
        spaceImageEstados: () => import('./components/spaceImageEstados'),
        pantallasVacias: () => import('./components/pantallasVacias'),
        advertencia: () => import('./components/advertencia.vue'),

    },
    data(){
        return {
            showData: false,
            cargando: false,
            inicio: [
                {
                    tipo: 21,
                    titulo: 'Selecciona proyecto',
                    tipoLabel: '21 - Hola selección proyecto',
                    descripcion: 'Aparece cuando un usuario ejecuta la aplicacion y debe seleccionar un proyecto',
                    miniatura: '/img/ilustraciones/ilu_app.svg',
                    data: {},
                },
                {
                    tipo: 22,
                    titulo: 'Bienvenida',
                    tipoLabel: '22 - Bienvenida',
                    descripcion: 'Aparece cuando un usuario ingresa por primera vez a la aplicacion',
                    miniatura: '/img/ilustraciones/ilu_bienvenido.svg',
                    data: {},
                },
                {
                    tipo: 23,
                    titulo: 'Registro completo',
                    tipoLabel: '23 - Completó datos',
                    descripcion: 'Aparece cuando un usuario completa el registro de datos',
                    miniatura: '/img/ilustraciones/ilu_registrado.svg',
                    data: {},
                },
                {
                    tipo: 24,
                    titulo: 'Vendedor',
                    tipoLabel: '24 - Selecciona Vendedor',
                    descripcion: 'Representar al vendedor o invitar a seleccionar un vendedor',
                    miniatura: '/img/ilustraciones/ilu_seleccionar_leechero.svg',
                    data: {},
                },
                {
                    tipo: 25,
                    titulo: 'Pedido creado',
                    tipoLabel: '25 - Creó un pedido',
                    descripcion: 'Se usa para indicar cuando un cliente o un vendedor crear un pedido',
                    miniatura: '/img/ilustraciones/ilu_pedido_creado.svg',
                    data: {},
                },
                {
                    tipo: 26,
                    titulo: 'Canje creado',
                    tipoLabel: '26 - Canjeo recompensas',
                    descripcion: 'Se usa para indicar cuando un cliente o un vendedor canjearon sus recompensas',
                    miniatura: '/img/ilustraciones/ilu_producto_canjeado.svg',
                    data: {},
                },
                {
                    tipo: 27,
                    titulo: 'Al dia',
                    tipoLabel: '27 - Estás al día',
                    descripcion: 'Se usa para indicar cuando un cliente o un vendedor están al día con las gestiones',
                    miniatura: '/img/ilustraciones/ilu_ok.svg',
                    data: {},
                },
                {
                    tipo: 28,
                    titulo: 'Solicitud enviada',
                    tipoLabel: '28 - Esperando aceptación',
                    descripcion: 'Se usa para indicar cuando un cliente o un está a la espera que lo acepten en una solicitud',
                    miniatura: '/img/ilustraciones/ilu_solicitud_enviada.svg',
                    data: {},
                },
                {
                    tipo: 29,
                    titulo: 'Pop-up',
                    tipoLabel: '29 - Imagen pop-up',
                    descripcion: 'Se usa mientras la imagen de pop-up no ha cargado',
                    miniatura: '/img/ilustraciones/no_imagen_popup.png',
                    data: {},
                },
            ],
            pantallasVacias: [
                {
                    tipo: 41,
                    titulo: 'Carrito vacio',
                    tipoLabel: '41 - Carrito',
                    descripcion: 'Se usa para indicar cuando la pantalla del carrito está vacía',
                    miniatura: '/img/ilustraciones/ilu_carrito_vacio.svg',
                    data: {},
                },
                {
                    tipo: 42,
                    titulo: 'Sin notificaciones',
                    tipoLabel: '42 - Notificaciones',
                    descripcion: 'Se usa para indicar cuando la pantalla de notificaciones está vacía',
                    miniatura: '/img/ilustraciones/ilu_notificacion.svg',
                    data: {},
                },
                {
                    tipo: 43,
                    titulo: 'Sin productos favoritos',
                    tipoLabel: '43 - Favoritos',
                    descripcion: 'Se usa para indicar cuando la pantalla de productos favoritos está vacía',
                    miniatura: '/img/ilustraciones/ilu_favoritos.svg',
                    data: {},
                },
                {
                    tipo: 44,
                    titulo: 'Sin direccíon',
                    tipoLabel: '44 - Dirección',
                    descripcion: 'Se usa para indicar cuando la pantalla de direcciones está vacía',
                    miniatura: '/img/ilustraciones/ilu_direccion.svg',
                    data: {},
                },
                {
                    tipo: 45,
                    titulo: 'Sin resultados',
                    tipoLabel: '45 - Resultados',
                    descripcion: 'Se usa para indicar cuando se está realizando una busqueda y no se encuentran resultados',
                    miniatura: '/img/ilustraciones/ilu_buscar.svg',
                    data: {},
                },
                {
                    tipo: 46,
                    titulo: 'Sin cupones',
                    tipoLabel: '46 - Cupones',
                    descripcion: 'Se usa para indicar cuando la pantalla de cupones está vacía',
                    miniatura: '/img/ilustraciones/ilu_cupones.svg',
                    data: {},
                },
                {
                    tipo: 47,
                    titulo: 'Chat vacio',
                    tipoLabel: '47 - Chats',
                    descripcion: 'Se usa para indicar cuando la pantalla de chat con un vendedor o en un reclamo no tiene mensajes enviados',
                    miniatura: '/img/ilustraciones/ilu_chat.svg',
                    data: {},
                },
                {
                    tipo: 48,
                    titulo: 'Sin convenio',
                    tipoLabel: '48 - Convenio',
                    descripcion: 'Se usa para indicar cuando la pantalla de convenio no se tiene uno asociado',
                    miniatura: '/img/ilustraciones/ilu_pedido.svg',
                    data: {},
                },
                {
                    tipo: 49,
                    titulo: 'Sin fecha seleccionada',
                    tipoLabel: '49 - Fecha',
                    descripcion: 'Se usa para indicar cuando en la pantalla no se ha seleccionado una fecha para analizar',
                    miniatura: '/img/ilustraciones/ilu_calendario.svg',
                    data: {},
                },
                {
                    tipo: 50,
                    titulo: 'Sin pedidos',
                    tipoLabel: '50 - Pedido',
                    descripcion: 'Se usa para indicar cuando no hay pedidos',
                    miniatura: '/img/ilustraciones/ilu_lista_pedidos.svg',
                    data: {},
                },
                {
                    tipo: 51,
                    titulo: 'Sin productos',
                    tipoLabel: '51 - Productos',
                    descripcion: 'Se usa para indicar cuando en la pantalla no se ha añadido productos',
                    miniatura: '/img/ilustraciones/ilu_lista_productos.svg',
                    data: {},
                },
                {
                    tipo: 52,
                    titulo: 'Sin resultado exacto',
                    tipoLabel: '52 - Sin resultados',
                    descripcion: 'Se usa para indiciar cuando no se encuentra una busqueda específica',
                    miniatura: '/img/ilustraciones/preguntas.svg',
                    data: {},
                },
                {
                    tipo: 53,
                    titulo: 'Sin clientes',
                    tipoLabel: '53 - Sin clientes',
                    descripcion: 'Se usa para indiciar cuando el vendedor no tiene clientes',
                    miniatura: '/img/ilustraciones/ilustracion-Clientes.svg',
                    data: {},
                },
            ],
            advertencia: [
                {
                    tipo: 61,
                    titulo: 'Salir de la aplicacion',
                    tipoLabel: '61 - Salir de la app',
                    descripcion: 'Se usa cuando el usuario intenta salir de la apliación',
                    miniatura: '/img/ilustraciones/ilu_triste.svg',
                    data: {},
                },
                {
                    tipo: 62,
                    titulo: 'Permisos de ubicacion',
                    tipoLabel: '62 - Permiso ubicación',
                    descripcion: 'Se usa cuando el usuario no ha dado permisos de ubicación a la aplicación',
                    miniatura: '/img/ilustraciones/ilu_direccion.svg',
                    data: {},
                },
                // {
                //     tipo: 63,
                //     titulo: 'Cancelar pedido empacado',
                //     tipoLabel: '63 - Cancelar empacado',
                //     descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado empacado',
                //     miniatura: '/img/ilustraciones/ilu_empacado.svg',
                //     data: {},
                // },
                // {
                //     tipo: 64,
                //     titulo: 'Cancelar pedido enviado',
                //     tipoLabel: '64 - Cancelar enviado',
                //     descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado enviado',
                //     miniatura: '/img/ilustraciones/ilu_enviado.svg',
                //     data: {},
                // },
                {
                    tipo: 65,
                    titulo: 'Aplicación equivocada',
                    tipoLabel: '65 - App equivocada',
                    descripcion: 'Se usa cuando un usuario cliente o vendedor intenta ingresar en una aplicación del otro tipo de usuario',
                    miniatura: '/img/ilustraciones/ilu_ingresar_equivocada.svg',
                    data: {},
                },
                {
                    tipo: 66,
                    titulo: 'Actualización de aplicación',
                    tipoLabel: '66 - App actualización',
                    descripcion: 'Se usa cuando la aplicación solicita o sugiera una actualización',
                    miniatura: '/img/ilustraciones/ilu_actualizacion.svg',
                    data: {},
                },
                {
                    tipo: 67,
                    titulo: 'Error',
                    tipoLabel: '67 - Error',
                    descripcion: 'Cuando ocurre algo inesperado',
                    miniatura: '/img/ilustraciones/ilu_oops.svg',
                    data: {},
                },
				{
                    tipo: 81,
                    titulo: 'Cancelar pedido sin confirmar',
                    tipoLabel: '81 - Cancelar sin confirmar',
                    descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado sin confirmar',
                    miniatura: '/img/ilustraciones/sin_confirmar.svg',
                    data: {},
                },
                {
                    tipo: 82,
                    titulo: 'Cancelar pedido confirmado',
                    tipoLabel: '82 - Cancelar confirmado',
                    descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado confirmado',
                    miniatura: '/img/ilustraciones/ilu_registrado.svg',
                    data: {},
                },
                {
                    tipo: 83,
                    titulo: 'Cancelar pedido en alistamiento',
                    tipoLabel: '83 - Cancelar alistando',
                    descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado de alistamiento',
                    miniatura: '/img/ilustraciones/alistando.svg',
                    data: {},
                },
                {
                    tipo: 84,
                    titulo: 'Cancelar pedido empacado',
                    tipoLabel: '84 - cancelar empacado',
                    descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado empacado',
                    miniatura: '/img/ilustraciones/ilu_empacado.svg',
                    data: {},
                },
                {
                    tipo: 85,
                    titulo: 'Cancelar pedido enviado',
                    tipoLabel: '85 - Cancelar enviado',
                    descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado enviado ',
                    miniatura: '/img/ilustraciones/ilu_enviado.svg',
                    data: {},
                },
                {
                    tipo: 86,
                    titulo: 'Cancelar pedido editado',
                    tipoLabel: '86 - Cancelar editado',
                    descripcion: 'Se usa cuando el usuario intenta cancelar un pedido y este se encuentra en estado editado',
                    miniatura: '/img/ilustraciones/editado.svg',
                    data: {},
                },
            ],
        }
    },
    mounted(){
        this.getData();
    },
    methods: {
        async getData(){
            try {
                this.cargando = true;
                const {data} = await Service.getImagenesEstados()
                data.imagenes.forEach(element => {
                    let index = this.inicio.findIndex((item) => parseInt(item.tipo) === parseInt(element.tipo));
                    if(index != -1){
                        this.inicio[index].data = element;
                    }
                    let index2 = this.pantallasVacias.findIndex((item) => parseInt(item.tipo) === parseInt(element.tipo));
                    if(index2 != -1){
                        this.pantallasVacias[index2].data = element;
                    }
                    let index3 = this.advertencia.findIndex((item) => parseInt(item.tipo) === parseInt(element.tipo));
                    if(index3 != -1){
                        this.advertencia[index3].data = element;
                    }
                });
            } catch(error){
                this.error_catch(error)
            } finally{
                this.showData = true;
                this.cargando = false;
            }
        },
    },

}
</script>
<style lang="scss" scoped>
.space-image{
    width: 240px;
    height: 240px;
    position: relative;
    border-radius: 4px;
    cursor: pointer;
    .circled-cancel{
        width: 28px;
        height: 28px;
        right: 11px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        z-index: 5;
        color: #FFFFFF;
        background-color: #7F7F7F;
    }
    img{
        border-radius: 4px;
        object-fit: cover;
    }
}
</style>
